import { ClientProjectEntity, ClientProjectLinkDraft, ClientProjectLinkEntity } from '@edgebox/api-rest-client';
import { IRuntimeProjectLinkProperties, ISerializedProjectLinkProperties, UserType } from '@edgebox/data-definitions';
import { FormField } from '@edgebox/react-components';
import { EntityMatchType, ProjectLinkStatus, SiteApplicationType } from '@edgebox/sync-core-data-definitions';
import React, { PropsWithChildren, useMemo } from 'react';
import { EditMode, EntityContext } from '../../../contexts/EntityContext';
import { CustomerLink } from '../../Billing/Links/CustomerLink';
import { EntityForm, entityFormWithValidation, IEntityFormProps, IEntityFormState } from '../../Form/EntityForm';
import { EntityFormRow } from '../../Form/EntityFormRow';
import { ProjectLink } from '../Links/ProjectLink';
import { ProjectSelector } from '../Selectors/ProjectSelector';
import { EnvironmentSelector } from '../../../../components/Customer/ContentCloud/Components/EnvironmentSelector';
import { SystemEnvironment } from '../../../../components/Customer/ContentCloud/PublisherClient';
import { ContentCloudComponentProps, initContentCloud } from '../../../../components/Customer/ContentCloud/WithContentCloud';
import { Button, Col, Row } from 'react-bootstrap';
import { DynamicReference, ExternalServiceId, StaticReference } from '@edgebox/data-definition-kit';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NULL_ID } from '../../../constants';
import { Permission } from '../../../../components/Customer/ContentCloud/shared-permissions';

type OnSaveCallback = (entity: ClientProjectLinkDraft) => Promise<void>;

interface IProps extends IEntityFormProps<ClientProjectLinkDraft, ISerializedProjectLinkProperties>, PropsWithChildren {
  mode: 'view-full' | 'edit-full' | 'create' | 'inline-edit';
  projectConnection: 'source' | 'target';
  onSave: OnSaveCallback;
}

const EDIT_MODES = {
  // Create project.
  create: EditMode.Full,
  // Viewing their own profile
  'inline-edit': EditMode.Inline,
  'view-full': EditMode.None,
  'edit-full': EditMode.Full,
};

interface IState extends IEntityFormState<ClientProjectLinkDraft> {
  savedEntity?: ClientProjectLinkEntity;
  selectedSourceProject?: ClientProjectEntity;
  selectedTargetProject?: ClientProjectEntity;
  selectedEnvironment?: SystemEnvironment;
}

const FormWithValidation = entityFormWithValidation<ISerializedProjectLinkProperties, ClientProjectLinkDraft>(
  ClientProjectLinkDraft as any,
  ClientProjectLinkEntity as any
);

export class ProjectLinkForm extends EntityForm<ClientProjectLinkDraft, ISerializedProjectLinkProperties, IProps, IState> {
  constructor(props: IProps) {
    super(props, {
      savedEntity: (props.entity as any).id ? (props.entity as ClientProjectLinkEntity) : undefined,
      edit: EDIT_MODES[props.mode],
      extendProperties: [],
    });
  }

  async load(): Promise<Partial<IState>> {
    const selectedSourceProject = await this.props.entity.sourceProject.get();
    const selectedTargetProject = await this.props.entity.targetProject.get();

    let selectedEnvironment: SystemEnvironment | undefined = undefined;
    if (this.props.projectConnection === 'target') {
      if (selectedTargetProject) {
        const contentCloud = await selectedTargetProject.syncCore?.get();
        if (contentCloud) {
          const contentCloudData = await initContentCloud(this.api, contentCloud, [Permission.SPACE_READ], selectedTargetProject);
          if (contentCloudData) {
            selectedEnvironment = contentCloudData.space.environments.find((c) => c.customId === this.props.entity.targetPoolMachineName);
          }
        }
      }
    }

    return {
      selectedSourceProject,
      selectedTargetProject,
      selectedEnvironment,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    // Person was saved => show "Upload profile picture" component.
    if (!(prevProps.entity as any).id && (this.props.entity as any).id) {
      this.createdNew();
    }
    if (this.props.mode !== prevProps.mode) {
      this.setState({
        edit: EDIT_MODES[this.props.mode],
      });
    }
  }

  async createdNew() {
    this.setState({
      savedEntity: this.props.entity as ClientProjectLinkEntity,
    });
  }

  render(): React.ReactElement {
    let { projectConnection } = this.props;
    const { children } = this.props;

    const { selectedSourceProject, selectedTargetProject, selectedEnvironment } = this.state;

    const renderBackendProperties = this.api.currentUser!.type === UserType.Internal;

    const createNew = this.props.mode === 'create';

    return (
      <EntityContext.Provider value={this.entityContext}>
        <FormWithValidation
          {...this.formProperties()}
          apiComponent={this}
          initialValues={this.props.entity}
          labels={'above'}
          onSubmit={async (entity) => {
            this.setState({
              extendProperties: [],
            });

            await this.props.onSave(entity);
          }}
        >
          {({ values, setValue, errors }) => (
            <>
              {this.props.name}

              {renderBackendProperties && (
                <EntityFormRow<ClientProjectLinkDraft>
                  name={'customer'}
                  label={'Customer'}
                  view={() => <CustomerLink entityId={values.customer.id} />}
                />
              )}

              {projectConnection !== 'source' && (
                <EntityFormRow<ClientProjectLinkDraft>
                  name={'sourceProject'}
                  label={'Source project'}
                  view={() => <ProjectLink entityId={values.sourceProject.id} />}
                  edit={
                    createNew
                      ? () => (
                          <ProjectSelector
                            forAppType={SiteApplicationType.Drupal}
                            forCustomer={selectedTargetProject?.customer.getId()}
                            onSelected={(project) => {
                              this.setState({
                                selectedSourceProject: project,
                              });
                              setValue('sourceProject.id' as 'sourceProject', project.id);
                              setValue('sourceContract.id' as 'sourceContract', project.contract.getId());
                              setValue('sourceAppType', project.appType!);

                              if (!values.name) {
                                setValue('name', `${project.name} to ${selectedTargetProject?.name ?? ''}`);
                              }
                            }}
                            value={selectedSourceProject}
                          />
                        )
                      : undefined
                  }
                />
              )}

              {selectedSourceProject && (
                <EntityFormRow<ClientProjectLinkDraft>
                  name={'sourcePoolMachineNames'}
                  label={'Source content pools'}
                  edit={() => (
                    <>
                      {values.sourcePoolMachineNames.map((poolMachineName, index) => (
                        <Row key={index}>
                          <Col>
                            <FormField<ClientProjectLinkDraft>
                              type={'text'}
                              name={`sourcePoolMachineNames.${index}` as 'sourcePoolMachineNames'}
                              labelPlacement={'none'}
                              placeholder="Enter machine name..."
                              noRow
                            />
                          </Col>
                          <Col xs={1}>
                            <Button
                              disabled={values.sourcePoolMachineNames.length <= 1}
                              variant={values.sourcePoolMachineNames.length > 1 ? 'danger' : 'light'}
                              onClick={() => {
                                setValue(
                                  'sourcePoolMachineNames',
                                  values.sourcePoolMachineNames.filter((c, i) => i !== index)
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      <div>
                        <Button
                          variant="link"
                          onClick={() => setValue('sourcePoolMachineNames', [...values.sourcePoolMachineNames, '' as ExternalServiceId])}
                        >
                          Add
                        </Button>
                      </div>
                    </>
                  )}
                  view={() => values.sourcePoolMachineNames.join(', ')}
                />
              )}

              {projectConnection !== 'target' && (
                <EntityFormRow<ClientProjectLinkDraft>
                  name={'targetProject'}
                  label={'Target project'}
                  view={() => <ProjectLink entityId={values.targetProject.id} />}
                  edit={
                    createNew
                      ? () => (
                          <ProjectSelector
                            forAppType={SiteApplicationType.ContentCloud}
                            forCustomer={selectedSourceProject?.customer.getId()}
                            onSelected={(project) => {
                              this.setState({
                                selectedTargetProject: project,
                              });
                              setValue('targetProject.id' as 'targetProject', project.id);
                              setValue('targetContract.id' as 'targetContract', project.contract.getId());
                              setValue('targetAppType', project.appType!);

                              if (!values.name) {
                                setValue('name', `${selectedSourceProject?.name ?? ''} to ${project.name}`);
                              }
                            }}
                            value={selectedTargetProject}
                          />
                        )
                      : undefined
                  }
                />
              )}

              <EntityFormRow<ClientProjectLinkDraft>
                name={'targetPoolMachineName'}
                label={'Target environment'}
                edit={
                  createNew
                    ? () =>
                        selectedTargetProject && (
                          <EnvironmentSelector
                            value={selectedEnvironment}
                            contentCloudProject={selectedTargetProject}
                            onSelected={(environment) => {
                              this.setState({
                                selectedEnvironment: environment,
                              });

                              setValue('targetPoolMachineName', environment.customId);
                            }}
                          />
                        )
                    : undefined
                }
                view={selectedEnvironment ? () => selectedEnvironment.name : undefined}
              />

              <EntityFormRow<ClientProjectLinkDraft>
                name={'name'}
                label={'Link name'}
                edit={() => <FormField<ClientProjectLinkDraft> type={'text'} name={'name'} labelPlacement={'none'} noRow />}
                view={values.name ? () => values.name : undefined}
              />

              {children}
            </>
          )}
        </FormWithValidation>
      </EntityContext.Provider>
    );
  }
}

export function AddProjectLinkForm(
  props: Omit<IProps, keyof ContentCloudComponentProps | 'mode' | 'entity'> & { project: ClientProjectEntity }
) {
  const draft = useMemo(() => {
    const project = props.project;
    const projectConnection = props.projectConnection;

    const draftProperties: Omit<IRuntimeProjectLinkProperties, 'uuid'> = {
      customer: project.customer,
      entityBehavior: EntityMatchType.Syndicated,
      entityTypeBehavior: EntityMatchType.Syndicated,
      name: '',
      sourcePoolMachineNames: ['' as ExternalServiceId],
      targetPoolMachineName: (project.contentCloudSettings?.defaultEnvironmentCustomId ?? 'prod') as ExternalServiceId,
      status: ProjectLinkStatus.Active,
      ...(projectConnection === 'source'
        ? {
            sourceContract: project.contract,
            sourceProject: new StaticReference(project),
            sourceAppType: project.appType!,
            targetContract: new DynamicReference(NULL_ID, (() => null) as any),
            targetProject: new DynamicReference(NULL_ID, (() => null) as any),
            targetAppType: SiteApplicationType.ContentCloud,
          }
        : {
            sourceContract: new DynamicReference(NULL_ID, (() => null) as any),
            sourceProject: new DynamicReference(NULL_ID, (() => null) as any),
            sourceAppType: SiteApplicationType.Drupal,
            targetContract: project.contract,
            targetProject: new StaticReference(project),
            targetAppType: project.appType!,
          }),
    };

    return new ClientProjectLinkDraft(draftProperties as IRuntimeProjectLinkProperties);
  }, [props.project, props.projectConnection]);

  return <ProjectLinkForm key={props.projectConnection + props.project.id} {...props} entity={draft} mode="create" />;
}
