import React, { useContext, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { ContentBox } from '../../../Shared/ContentBox';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { getContentCloudSatelliteUrl, isContentUserDataType } from '../content-cloud-helper';
import { ContentCloudComponentProps, withContentCloud } from '../WithContentCloud';
import { Permission } from '../shared-permissions';
import { CopyToClipboardButton, IconButton, LoadingBar } from '@edgebox/react-components';
import { faCircleA } from '@fortawesome/pro-light-svg-icons/faCircleA';
import { ApolloSandbox } from '@apollo/sandbox/react';
import { AppContext } from '../../../../common/contexts/AppContext';
import { ContentTypesProps, withContentTypes } from '../Types/WithContentTypes';

interface Props extends ContentCloudComponentProps, ContentTypesProps {}

function GraphQlExplorer({ contentCloudData, contentTypes }: Props) {
  const appContext = useContext(AppContext);
  const { project } = appContext;

  const { accessToken, contentCloud, environment, space } = contentCloudData ?? {};

  const graphQlUrlDev =
    contentCloud &&
    space &&
    environment &&
    getContentCloudSatelliteUrl(contentCloud, {
      service: 'dev',
      environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
      api: 'graphql',
      contentUserDataTypes: contentTypes.filter((c) => isContentUserDataType(c)).map((c) => c.machineName),
    });
  const graphQlUrlLive =
    contentCloud &&
    space &&
    environment &&
    getContentCloudSatelliteUrl(contentCloud, {
      service: 'live',
      environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
      api: 'graphql',
    });
  const graphQlUrlCdn =
    contentCloud &&
    space &&
    environment &&
    getContentCloudSatelliteUrl(contentCloud, {
      service: 'cdn',
      environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
      api: 'graphql',
    });
  const graphQlUrlPreview =
    contentCloud &&
    space &&
    environment &&
    getContentCloudSatelliteUrl(contentCloud, {
      service: 'preview',
      environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
      api: 'graphql',
    });

  const [showExplorer, setShowExplorer] = useState(false);

  return (
    <Container>
      <Modal show={showExplorer} fullscreen onHide={() => setShowExplorer(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{project?.name}: Apollo Sandbox</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="position-relative w-100 h-100 p-0 overflow-hidden">
            {accessToken ? (
              <ApolloSandbox
                className="h-100 w-100"
                initialEndpoint={graphQlUrlDev}
                handleRequest={(endpointUrl, options) => {
                  return fetch(endpointUrl, {
                    ...options,
                    headers: {
                      ...options.headers,
                      Authorization: `Bearer ${accessToken}`,
                    },
                  });
                }}
                hideCookieToggle
                initialState={{
                  includeCookies: false,
                  pollForSchemaUpdates: true,
                  document: ``,
                  variables: {},
                }}
                endpointIsEditable={false}
              />
            ) : (
              <LoadingBar />
            )}
            <div
              className="position-absolute bg-white text-muted ps-3 pt-2 text-truncate"
              style={{ left: '0', right: '0', top: '0', height: '48px' }}
            >
              We are using{' '}
              <a href="https://www.apollographql.com/" target="_blank" rel="noreferrer">
                Apollo
              </a>{' '}
              for providing the GraphQL explorer. The explorer is subject to Apollo's privacy policy and terms of service.
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div>
        <HeadlineWithBreadcrumbNavigation>GraphQL explorer</HeadlineWithBreadcrumbNavigation>

        <ContentBox className="p-4">
          <p>
            We are using{' '}
            <a href="https://www.apollographql.com/" target="_blank" rel="noreferrer">
              Apollo
            </a>{' '}
            for providing the GraphQL explorer. The explorer linked below is subject to Apollo's privacy policy and terms of service.
          </p>
          <p>
            <IconButton variant="primary" icon={faCircleA} onClick={() => setShowExplorer(true)}>
              Open Apollo sandbox
            </IconButton>
          </p>
          <p className="fw-bold text-danger">
            This endpoint is not using a type cache. This allows you to query for the most up-to-date type definitions with minimal delay
            but also results in queries performing much slower than through the endpoints below.
          </p>
        </ContentBox>

        <h1 className="mt-5">API endpoints</h1>
        <ContentBox className="p-4">
          <div className="mb-4">
            <div className="small fw-bold border-bottom text-muted pb-1">CDN GraphQL endpoint</div>
            <div className="pt-1">
              <CopyToClipboardButton name="copy-graphql-url-cdn" text={graphQlUrlCdn ?? 'loading...'} buttonText="" />
            </div>
            <div className="text-muted small">
              The CDN endpoint caches entities to deliver published content with better performance and at a lower cost than the live API.
              Please note that if you are using our global replication feature, satellite regions that are not the publisher region will
              receive updates with a delay of 1 to 90 seconds.
            </div>
          </div>

          <div className="mb-4">
            <div className="small fw-bold border-bottom text-muted pb-1">Live GraphQL endpoint</div>
            <div className="pt-1">
              <CopyToClipboardButton name="copy-graphql-url-live" text={graphQlUrlLive ?? 'loading...'} buttonText="" />
            </div>
            <div className="text-muted small">
              The live endpoint doesn't cache entities to deliver the most up-to-date, published versions when queried with as little delay
              as possible. Please note that if you are using our global replication feature, satellite regions that are not the publisher
              region will receive updates with a delay of 1 to 60 seconds.
            </div>
          </div>

          <div className="mb-4">
            <div className="small fw-bold border-bottom text-muted pb-1">Preview GraphQL endpoint</div>
            <div className="pt-1">
              <CopyToClipboardButton name="copy-graphql-url-preview" text={graphQlUrlPreview ?? 'loading...'} buttonText="" />
            </div>
            <div className="text-muted small">
              The preview endpoint allows querying unpublished content and always requires an Authorization header with a signed JWT. Please
              note that if you are using our global replication feature, satellite regions that are not the publisher region will receive
              updates with a delay of 1 to 60 seconds.
            </div>
          </div>
        </ContentBox>
      </div>
    </Container>
  );
}

export default withContentCloud(withContentTypes(GraphQlExplorer), [
  Permission.SPACE_READ,
  Permission.CONTENT_READ,
  Permission.ASSET_READ_FILE,
  Permission.EXTERNAL_LINK_READ,
  Permission.CONTENT_TYPE_READ,
  Permission.DEVELOPER,
  Permission.USER_DATA_READ,
  Permission.USER_DATA_WRITE,
]);
