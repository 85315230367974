import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';

export function ClientGenerationPage() {
  return (
    <Container>
      <div>
        <HeadlineWithBreadcrumbNavigation>Automated client generation</HeadlineWithBreadcrumbNavigation>

        <Alert className={'mt-4'} variant="light">
          Client generation is not enabled for this space.
        </Alert>
      </div>
    </Container>
  );
}
