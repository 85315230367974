import { ClientContractEntity, ClientProjectLinkDraft } from '@edgebox/api-rest-client';
import { AddButton, LeftRightContainer, Right } from '@edgebox/react-components';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { PagedProjectLinkList } from '../../Shared/PagedProjectLinkList';
import { ApiComponent, IApiComponentState } from '../../../common';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { IAppContextProp, WithAppContext, withAppContext } from '../../../common/contexts/AppContext';
import { UserRoleGate } from '../../../common/components/UserRoleGate';
import { CustomerRole } from '@edgebox/data-definitions';
import { AddProjectLinkForm } from '../../../common/components/Syndication/Forms/ProjectLinkForm';
import { PrimaryActionButton } from '../../Shared/PrimaryActionButton';
import { SiteApplicationType } from '@edgebox/sync-core-data-definitions';
import { ContentCloudPageLayout } from '../ContentCloud/Layouts/ContentCloudPageLayout';
import { ContentCloudPitch } from '../ContentCloud/ContentCloudPitch';

interface IState extends IApiComponentState {
  reload?: number;
  contracts?: ClientContractEntity[];
  add?: boolean;
  hideAddButton?: boolean;
}

interface IProps extends JSX.IntrinsicAttributes, IAppContextProp {
  projectConnection: 'source' | 'target';
}

class ProjectLinkListComponent extends ApiComponent<IProps, IState> {
  async load() {
    const contracts = await this.api.billing.contracts.search();

    return {
      contracts: contracts.items,
    };
  }

  render() {
    const { contracts, reload, add, hideAddButton } = this.state;
    const { projectConnection } = this.props;

    if (!contracts) {
      return this.renderRequest();
    }

    const typeName = projectConnection === 'source' ? 'Target' : projectConnection === 'target' ? 'Source' : 'Link';

    const addForm = () =>
      this.props.appContext.project && (
        <AddProjectLinkForm
          projectConnection={projectConnection}
          onSave={async (draft) => {
            await this.api.syndication.projectLinks.create(draft as ClientProjectLinkDraft);
            this.setState({
              reload: Date.now(),
              add: false,
              hideAddButton: false,
            });
          }}
          project={this.props.appContext.project}
        >
          <Right>
            <PrimaryActionButton type={'submit'}>Add {typeName}</PrimaryActionButton>
          </Right>
        </AddProjectLinkForm>
      );

    return (
      <ContentCloudPageLayout>
        <div className="p-3">
          <LeftRightContainer
            className="mb-4"
            left={<HeadlineWithBreadcrumbNavigation className="ms-2">{typeName}s</HeadlineWithBreadcrumbNavigation>}
            right={
              <UserRoleGate allowed={[CustomerRole.Owner, CustomerRole.ManageSites]}>
                {() =>
                  hideAddButton ? null : (
                    <AddButton variant="primary" onClick={() => this.setState({ add: true })}>
                      {typeName}
                    </AddButton>
                  )
                }
              </UserRoleGate>
            }
          />
          <Modal show={add} scrollable onHide={() => this.setState({ add: undefined })}>
            <Modal.Header closeButton>
              <Modal.Title>Add {typeName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{addForm()}</Modal.Body>
          </Modal>
          <WithAppContext>
            {(appContext) => (
              <PagedProjectLinkList
                projectConnection={projectConnection}
                customerId={appContext?.customer?.id}
                onEmpty={() => this.setState({ hideAddButton: true })}
                projectId={appContext?.project?.id}
                key={(reload?.toString() || '') + appContext?.customer?.id + appContext?.project?.id}
                emptyMessageWithNoFilters={
                  <div className="my-5">
                    <ContentCloudPitch
                      onClick={() => this.setState({ add: true })}
                      reverse={appContext?.project?.appType !== SiteApplicationType.ContentCloud}
                    />
                  </div>
                }
              />
            )}
          </WithAppContext>
        </div>
      </ContentCloudPageLayout>
    );
  }
}

export const ProjectLinkList = withAppContext(ProjectLinkListComponent);
