import { useContext, useEffect, useMemo, useState } from 'react';
import { ContentCloudComponentData, initContentCloud, updateAccessToken } from '../WithContentCloud';
import { RestInterfaceDataTypes } from '../RestClient';
import { Permission } from '../shared-permissions';
import { getContentCloudSatelliteUrl, isUsingDomainBasedRouting } from '../content-cloud-helper';
import { ApiContext } from '../../../../common';
import { AppContext } from '../../../../common/contexts/AppContext';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import React from 'react';
import { ContentCloudDetailsPageLayout } from '../Layouts/ContentCloudDetailsPageLayout';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import { CopyToClipboardButton } from '@edgebox/react-components';
import { ILocationProp, INavigateProp, IParamsProp, withLocationAndNavigate, withParams } from '../../../RouterHelper';

const ALLOWED_PERMISSIONS = Object.values(Permission).filter(
  (c) =>
    !c.includes('organization') && !c.includes('client') && (!c.includes('write') || c.includes('user-data')) && !c.includes('developer')
);

type RouteParams = {
  project: string;
  tab?: string;
};
interface Props extends INavigateProp, ILocationProp, IParamsProp<RouteParams> {}

const PERMISSIONS_PER_REST_INTERFACE_DATA_TYPE: { [key in RestInterfaceDataTypes]: Permission[] } = {
  space: [Permission.SPACE_READ],
  content_types: [Permission.SPACE_READ, Permission.CONTENT_TYPE_READ],
  entries: [Permission.SPACE_READ, Permission.CONTENT_READ, Permission.ASSET_READ_FILE],
  assets: [Permission.SPACE_READ, Permission.CONTENT_READ, Permission.ASSET_READ_FILE],
  tags: [Permission.SPACE_READ, Permission.CONTENT_READ],
  locales: [Permission.SPACE_READ],
};

class RestRequestSettings {
  constructor(properties?: Partial<RestRequestSettings>) {
    this.service = properties?.service ?? 'live';
    this.apiVersion = properties?.apiVersion ?? 'latest';
    this.entryType = properties?.entryType ?? 'entries';
    this.entryId = properties?.entryId;
    this.queryParameters = properties?.queryParameters ?? {};
    this.autoManagePermissions = properties?.autoManagePermissions ?? true;
    this.permissions = properties?.permissions ?? PERMISSIONS_PER_REST_INTERFACE_DATA_TYPE[this.entryType];
    this.accessToken = properties?.accessToken;
  }

  service: 'live' | 'cdn' | 'preview';
  apiVersion: string;

  entryType: RestInterfaceDataTypes;
  entryId?: string;

  queryParameters: Record<string, unknown>;

  autoManagePermissions: boolean;
  permissions: Permission[];

  accessToken?: string;
}

const PARAMETER_NAMES: Record<string, string> = {
  content_type: 'Content type filter',
  user_data_types: 'Use user data',
  select: 'Select specific fields only',
  query: 'Search',
  order: 'Order results',
  assigned_tag_names: 'Filter by tag names',
  assigned_tag_ids: 'Filter by tag IDs',
  skip: 'Pagination',
  limit: 'Limit number of returned entries',
  include: 'Include entry links inline (nesting limit)',
  embed: 'Include embedded entries (nesting limit)',
  code: 'Filter',
  machine_name: 'Filter',
  locale: 'Localize results',
};
function ExplainUrl({ url: urlIn, domainBasedRouting, className }: { url: string; domainBasedRouting: boolean; className?: string }) {
  const url = useMemo(() => (urlIn ? new URL(urlIn) : null), [urlIn]);

  if (!url) {
    return null;
  }

  const hostnameParts = url.hostname.split('.');

  const pathParts = url.pathname.substring(1).split('/');
  const apiPath = domainBasedRouting ? pathParts : pathParts.slice(2);

  const parts: { name?: string; value: string }[] = [
    {
      name: url.protocol === 'https:' ? 'Secure protocol' : 'Protocol',
      value: `${url.protocol}`,
    },
    {
      value: '//',
    },
    ...(domainBasedRouting
      ? [
          {
            name: 'Environment identifier',
            value: hostnameParts[0],
          },
          {
            value: '.',
          },
          {
            name: 'Service',
            value: hostnameParts[1],
          },
          {
            value: '.',
          },
          {
            name: 'Content Cloud domain',
            value: hostnameParts.slice(2).join('.'),
          },
        ]
      : [
          {
            name: 'Content Cloud domain',
            value: url.hostname,
          },
          {
            value: '/',
          },
          {
            name: 'Environment identifier',
            value: pathParts[0],
          },
          {
            value: '/',
          },
          {
            name: 'Service',
            value: pathParts[1],
          },
        ]),
    {
      value: '/',
    },
    {
      name: 'API version',
      value: apiPath[0],
    },
    {
      value: '/',
    },
    {
      name: 'API type',
      value: apiPath[1],
    },
    {
      value: '/',
    },
    {
      name:
        apiPath[2] === 'space'
          ? 'Query space entry'
          : `Query ${apiPath[2] === 'entries' ? 'content entries' : apiPath[2].replace(/_/, ' ')}`,
      value: apiPath[2],
    },
    ...(apiPath.length > 3
      ? [
          {
            value: '/',
          },
          {
            name: 'Entry ID',
            value: apiPath[3],
          },
        ]
      : []),
    ...[...url.searchParams]
      .map(([name, value], index) => [
        {
          value: index ? '&' : '?',
        },
        {
          name: PARAMETER_NAMES[name] ?? 'Filter',
          value: `${name}=${value}`,
        },
      ])
      .flat(),
  ];

  return (
    <div className={className}>
      <div className="mw-100 overflow-auto">
        <div className="d-flex">
          {parts.map((part, index) => (
            <div
              className={`flex-grow-0 flex-shrink-1 py-1 px-2 ${index ? '-border-start' : ''} border-light`}
              key={part.name ?? index.toString()}
            >
              <div className={`${part.name ? 'text-truncate d-inline-block' : 'text-nowrap text-light'}`}>{part.value}</div>
              <div className="text-nowrap fw-bold">{part.name || <span>&nbsp;</span>}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function RestExplorerComponent({ location, navigate, params }: Props) {
  const api = useContext(ApiContext);
  const appContext = useContext(AppContext);

  const [requestSettings, setRequestSettings] = useState(new RestRequestSettings());
  const [contentCloudData, setContentCloudData] = useState<ContentCloudComponentData | null>(null);

  const [accessTokenCache, setAccessTokenCache] = useState({ accessToken: '', permissions: [] as Permission[] });

  useEffect(() => {
    if (!appContext.customer || !appContext.project || !api || !appContext.project.syncCore) {
      return;
    }

    appContext.project.syncCore?.get().then(async (contentCloud) => {
      if (!contentCloud) {
        return;
      }

      initContentCloud(api, contentCloud, [Permission.SPACE_READ], appContext.project!).then((data) => {
        data && setContentCloudData(data);
      });
    });
  }, [api, appContext]);

  const [baseUrl, setBaseUrl] = useState('');
  const [restUrl, setRestUrl] = useState('');
  const [response, setResponse] = useState<any>(null);
  useEffect(() => {
    (async () => {
      setResponse(null);

      if (!api || !contentCloudData) {
        return;
      }

      if (!requestSettings.permissions.length) {
        return;
      }

      let accessToken = requestSettings.accessToken ?? accessTokenCache.accessToken;
      if (!requestSettings.accessToken) {
        if (!accessToken || JSON.stringify(accessTokenCache.permissions.sort()) !== JSON.stringify(requestSettings.permissions.sort())) {
          accessToken = await updateAccessToken(api, contentCloudData, requestSettings.permissions);
          setAccessTokenCache({
            accessToken,
            permissions: requestSettings.permissions,
          });
        }
      }

      const response = await fetch(restUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setResponse(await response.json());
    })();
  }, [
    restUrl,
    requestSettings.permissions,
    requestSettings.accessToken,
    contentCloudData,
    api,
    setResponse,
    accessTokenCache,
    setAccessTokenCache,
  ]);

  const onChange = useMemo(
    () => () => {
      if (!contentCloudData) {
        return;
      }

      if (requestSettings.autoManagePermissions) {
        const newPermissions = [...PERMISSIONS_PER_REST_INTERFACE_DATA_TYPE[requestSettings.entryType]];
        if (requestSettings.service === 'preview') {
          newPermissions.push(Permission.PREVIEW);
        }

        if (JSON.stringify(requestSettings.permissions.sort()) !== JSON.stringify(newPermissions.sort())) {
          setRequestSettings(new RestRequestSettings({ ...requestSettings, permissions: newPermissions }));
        }
      }

      const baseUrl = getContentCloudSatelliteUrl(contentCloudData.contentCloud, {
        api: 'rest',
        service: requestSettings.service,
        version: requestSettings.apiVersion,
        environmentSubdomain: `${contentCloudData.space.domainKey}-${contentCloudData.environment.domainKey}`,
      });

      setBaseUrl(baseUrl);
      setRestUrl(`${baseUrl}/${requestSettings.entryType}${requestSettings.entryId ? `/${requestSettings.entryId}` : ''}`);
    },
    [contentCloudData, requestSettings]
  );
  useEffect(() => {
    onChange();
  }, [onChange]);

  const [updateRequestTimeout, setUpdateRequestTimeout] = useState(null as any);
  const updateRequest = useMemo(
    () =>
      (property: keyof RestRequestSettings, value: any, delay = false) => {
        (requestSettings as any)[property] = value;

        if (updateRequestTimeout) {
          clearTimeout(updateRequestTimeout);
        }

        if (delay) {
          setUpdateRequestTimeout(setTimeout(() => setRequestSettings(new RestRequestSettings(requestSettings)), 500));
        } else {
          setUpdateRequestTimeout(null);

          setRequestSettings(new RestRequestSettings(requestSettings));
        }
        //onChange();
      },
    [requestSettings, updateRequestTimeout]
  );

  const [sidebarTab, setSidebarTab] = useState('api');
  const [contentTab, _setContentTab] = useState(params.tab);
  const setContentTab = useMemo(
    () => (tab: string) => {
      _setContentTab(tab);
      navigate(`/projects/${params.project}/content-cloud/rest-explorer/${tab}${location.search}`);
    },
    [navigate, _setContentTab, params, location]
  );

  const prettyResponse = useMemo(() => JSON.stringify(response, null, 2), [response]);

  const curlCode = useMemo(
    () =>
      restUrl
        ? `
curl \\
  '${restUrl}' \\
  -H 'Authorization: Bearer ${accessTokenCache.accessToken}'
`
        : null,
    [restUrl, accessTokenCache.accessToken]
  );

  const javaScriptCode = useMemo(() => {
    return `
// index.js

/**
 * @param {('entries'|'space'|'locales'|'assets'|'tags'|'content_types')} entryType - The type of entries to query, e.g. "entries" to query for content entries.
 * @param {string} [entryId] - Pass an entry ID to return a single entry instead of a list.
 * @param {object} [parameters] - Additional options that are passed as search / query parameters.
 * @param {number} [parameters.include] - Include linked entries (depth limit).
 * @param {number} [parameters.embed] - Include embedded entries (depth limit).
 * @param {number} [parameters.skip] - Pagination for list requests.
 * @param {number} [parameters.limit] - Pagination for list requests.
 * @param {string} [parameters.query] - Search for the given text.
 * @param {string} [parameters.order] - One or more fields to order by, delimited by a comma. Prefix with "+" or "-" to sort in ascending or descending order.
 * @param {string} [parameters.select] - Only return the given fields, separated by a comma. E.g. "sys.id" or "fields.title".
 * @param {string} [parameters.locale] - Use this locale code to query for content in a specific locale.
 * @param {string} [parameters.content_type] - Filter by content type. Queries using a content type run significantly faster.
 * @param {string} [parameters.user_data_types] - Include user data of the given type in the results. Must be provided when using a type as part of a filter.
 * @param {string} [parameters."sys.*"] - Filter by a system property, e.g. {"sys.name":"Expected name"}.
 * @param {string} [parameters."field.*"] - Filter by a field, e.g. {"field.title":"Expected title"}.
 * @param {string} [parameters."user_data.*.*"] - Filter by a user data field, e.g. {"user_data.DefaultUserData.bookmarked":true}.
 *
 * @returns {object|null} The list or entry response or null when encountering a non-OK status code.
 */
async function queryContentCloud(entryType, entryId, parameters) {
  if(!process.env.CONTENT_CLOUD_BASE_URL) {
    throw new Error("Content Cloud: Missing environment variable CONTENT_CLOUD_BASE_URL.");
  }

  const url = new URL(\`\${process.env.CONTENT_CLOUD_BASE_URL}/\${entryType}\${entryId ? "/"+entryId : ""}\`);
  if(parameters) {
    url.search = new URLSearchParams(parameters).toString();
  }

  const response = await fetch(url.toString(), {
    headers: {
      ...(process.env.CONTENT_CLOUD_TOKEN ? {
        Authorization: \`Bearer \${process.env.CONTENT_CLOUD_TOKEN}\`,
      } : {}),
      "Accept": "application/json",
    },
  });

  if(response.ok) {
    return response.json();
  }

  console.warn("Content Cloud request failed:", await response.json());

  return null;
}

async function testQuery() {
  console.log(
    JSON.stringify(
      await queryContentCloud(
        "${requestSettings.entryType}"${
          requestSettings.entryId
            ? `,
        "${requestSettings.entryId}"`
            : ''
        }
      ),
      null,
      2
    )
  );
}

testQuery();
`;
  }, [requestSettings]);
  const javaScriptCallCode = useMemo(
    () =>
      baseUrl
        ? `
CONTENT_CLOUD_BASE_URL="${baseUrl}" CONTENT_CLOUD_TOKEN="${accessTokenCache.accessToken}" node index
`
        : null,
    [baseUrl, accessTokenCache.accessToken]
  );

  return (
    <ContentCloudPageLayout>
      <ContentCloudDetailsPageLayout
        pageHeader={<HeadlineWithBreadcrumbNavigation className="ms-2">REST Explorer</HeadlineWithBreadcrumbNavigation>}
        sidebar={
          <Tabs id={'sidebar-tabs'} activeKey={sidebarTab} onSelect={(k: string | null) => setSidebarTab(k ?? 'api')}>
            <Tab eventKey={'api'} title={'API'}>
              <div className="mt-4">
                <div className="small fw-bold border-bottom text-muted pb-1 mb-2">Service</div>
                <div>
                  <select onChange={(e) => updateRequest('service', e.target.value)} value={requestSettings.service}>
                    <option value="live">Live</option>
                    <option value="cdn">CDN</option>
                    <option value="preview">Preview</option>
                  </select>
                </div>
              </div>

              <div className="mt-4">
                <div className="small fw-bold border-bottom text-muted pb-1 mb-2">API version</div>
                <div>
                  <input
                    type="text"
                    onChange={(e) => updateRequest('apiVersion', e.target.value, true)}
                    value={requestSettings.apiVersion}
                  />
                </div>
              </div>
            </Tab>

            <Tab eventKey={'entries'} title={'Entries'}>
              <div className="mt-4">
                <div className="small fw-bold border-bottom text-muted pb-1 mb-2">Type</div>
                <div>
                  <select onChange={(e) => updateRequest('entryType', e.target.value)} value={requestSettings.entryType}>
                    <option value="space">Space</option>
                    <option value="locales">Locales</option>
                    <option value="content_types">Content Types</option>
                    <option value="entries">Content</option>
                    <option value="assets">Assets</option>
                    <option value="tags">Tags</option>
                  </select>
                </div>
              </div>

              <div className="mt-4">
                <div className="small fw-bold border-bottom text-muted pb-1 mb-2">Entry ID</div>
                <div>
                  <input
                    type="text"
                    onChange={(e) => updateRequest('entryId', e.target.value, true)}
                    value={requestSettings.entryId ?? ''}
                  />
                </div>
              </div>
            </Tab>

            <Tab eventKey={'access'} title={'Access'}>
              <div className="mt-4">
                <div className="small fw-bold border-bottom text-muted pb-1 mb-2">Permission management</div>
                <div>
                  <input
                    type="checkbox"
                    id="autoManagePermissions"
                    onChange={(e) => updateRequest('autoManagePermissions', e.target.checked)}
                    checked={requestSettings.autoManagePermissions}
                  />
                  <label htmlFor="autoManagePermissions">Apply automatically</label>
                </div>
              </div>

              <div className="mt-4">
                <div className="small fw-bold border-bottom text-muted pb-1 mb-2">Permissions</div>
                {Object.values(ALLOWED_PERMISSIONS).map((permission) => (
                  <div key={permission}>
                    <input
                      type="checkbox"
                      disabled={requestSettings.autoManagePermissions}
                      id={`permission-${permission}`}
                      onChange={(e) =>
                        updateRequest(
                          'permissions',
                          e.target.checked
                            ? [...requestSettings.permissions, permission]
                            : requestSettings.permissions.filter((c) => c !== permission)
                        )
                      }
                      value={1}
                      checked={requestSettings.permissions.includes(permission)}
                    />
                    <label htmlFor={`permission-${permission}`}>{permission}</label>
                  </div>
                ))}
              </div>
            </Tab>

            <Tab eventKey={'debug'} title={'Debug'}>
              <div className="mt-4">
                <div className="small fw-bold border-bottom text-muted pb-1 mb-2">Access token</div>
                <div>
                  <input
                    type="text"
                    onChange={(e) =>
                      e.target.value ? updateRequest('accessToken', e.target.value, true) : updateRequest('accessToken', undefined)
                    }
                    value={requestSettings.accessToken ?? ''}
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        }
      >
        <Tabs
          id={'content-tabs'}
          className={'mb-3'}
          activeKey={contentTab ?? 'plain'}
          onSelect={(k: string | null) => setContentTab(k ?? 'content')}
        >
          <Tab eventKey={'plain'} title={'Plain'}>
            <div className="mt-4">
              <div className="small fw-bold border-bottom text-muted pb-1 ps-2">URL</div>
              <div className="pt-3">
                <CopyToClipboardButton text={restUrl} name="url" />
              </div>
            </div>

            <div className="mt-5">
              <div className="small fw-bold border-bottom text-muted pb-1 ps-2">URL parts</div>
              <ExplainUrl
                className="mt-2"
                url={restUrl}
                domainBasedRouting={contentCloudData ? isUsingDomainBasedRouting(contentCloudData.contentCloud.baseUrl) : true}
              />
            </div>
          </Tab>
          <Tab eventKey={'curl'} title={'curl'}>
            <div className="position-relative p-3">
              <code className="p-2 d-block">
                <pre>{curlCode}</pre>
              </code>
              {curlCode ? (
                <div className="position-absolute" style={{ top: '5px', right: '5px' }}>
                  <CopyToClipboardButton text={curlCode} name="response" buttonOnly />
                </div>
              ) : null}
              <Alert variant="danger">
                The command above includes an access token. You can use it for testing but we don't recommend sharing the command with
                others.
              </Alert>
            </div>
          </Tab>
          <Tab eventKey={'javascript'} title={'JavaScript'}>
            <div className="position-relative p-3">
              <code className="p-2 d-block">
                <pre>{javaScriptCode}</pre>
              </code>
              {javaScriptCode ? (
                <div className="position-absolute" style={{ top: '5px', right: '5px' }}>
                  <CopyToClipboardButton text={javaScriptCode} name="response" buttonOnly />
                </div>
              ) : null}
            </div>
            <div className="position-relative p-3">
              <div className="mt-4">
                To use the code above, you need to provide the base URL and an access token like this:
                <div className="position-relative">
                  <code className="p-2 d-block">
                    <pre>{javaScriptCallCode}</pre>
                  </code>
                  {javaScriptCallCode ? (
                    <div className="position-absolute" style={{ top: '10px', right: '5px' }}>
                      <CopyToClipboardButton text={javaScriptCallCode} name="response" buttonOnly />
                    </div>
                  ) : null}
                </div>
                <p className="fw-bold text-danger">
                  The call above includes an access token. You can use it for testing but we don't recommend sharing the command with
                  others.
                </p>
              </div>
            </div>
          </Tab>
          <Tab eventKey={'json'} title={'JSON preview'}>
            <div className="position-relative p-3">
              <code className="p-2 d-block">
                <pre>{response ? prettyResponse : 'Loading...'}</pre>
              </code>
              {response ? (
                <div className="position-absolute" style={{ top: '5px', right: '5px' }}>
                  <CopyToClipboardButton text={prettyResponse} name="response" buttonOnly />
                </div>
              ) : null}
            </div>
          </Tab>
        </Tabs>
      </ContentCloudDetailsPageLayout>
    </ContentCloudPageLayout>
  );
}

export const RestExplorer = withParams<{}, { project: string; tab: string }>(withLocationAndNavigate<any>(RestExplorerComponent));
