import React, { useEffect, useMemo, useState } from 'react';
import { IAppContextProp, withAppContext } from '../../../../common/contexts/AppContext';
import { ILocationProp, INavigateProp, withLocationAndNavigate, withParams } from '../../../RouterHelper';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import { ContentTypeEntry, ContentCloudRestClient } from '../RestClient';
import { ContentCloudComponentProps, withContentCloud } from '../WithContentCloud';
import { CONTENT_CLOUD_API_VERSION, getContentCloudSatelliteUrl, loadAllContentTypes } from '../content-cloud-helper';
import { Permission } from '../shared-permissions';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { CopyToClipboardButton, HeaderCol, LeftRightContainer } from '@edgebox/react-components';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { ContentCloudDetailsPageLayout } from '../Layouts/ContentCloudDetailsPageLayout';
import { EntryPublishedBadge } from '../Components/EntryPublishedBadge';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';
import { SanitizeHtml } from '../SanitizeHtml';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faBracketsSquare } from '@fortawesome/pro-light-svg-icons/faBracketsSquare';
import { ContentTypePropertyType } from './Components/ContentTypePropertyType';
import { ContentTypeVisualization } from './Components/ContentTypeVisualization';

interface Props extends JSX.IntrinsicAttributes, IAppContextProp, INavigateProp, ILocationProp, ContentCloudComponentProps {
  params: {
    project: string;
    type: string;
    tab: string;
  };
}

function ContentTypeEntryPageComponent({ contentCloudData, params, location, navigate }: Props) {
  const { accessToken, contentCloud, environment, space } = contentCloudData ?? {};

  const client = useMemo(
    () =>
      contentCloud &&
      accessToken &&
      space &&
      environment &&
      new ContentCloudRestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
      }),
    [accessToken, space, environment, contentCloud]
  );

  const [sidebarTab, setSidebarTab] = useState('general');
  const [contentTab, _setContentTab] = useState(params.tab);
  const setContentTab = useMemo(
    () => (tab: string) => {
      _setContentTab(tab);
      navigate(`/projects/${params.project}/content-cloud/content-model/${params.type}/${tab}${location.search}`);
    },
    [navigate, _setContentTab, params, location]
  );

  const [contentTypes, setContentTypes] = useState<ContentTypeEntry[] | null>(null);
  useEffect(
    () => (client ? !!loadAllContentTypes(client).then((contentTypes) => setContentTypes(contentTypes)) : true) && void 0,
    [client]
  );

  const [contentTypeEntry, setContentTypeEntry] = useState<ContentTypeEntry | null>(null);
  useEffect(() => {
    setContentTypeEntry(
      contentTypes?.find((c) => c.id === params.type) ??
        contentTypes?.find((c) => c.machineName === params.type) ??
        contentTypes?.find((c) => c.customId === params.type) ??
        null
    );
  }, [contentTypes, params.type]);

  const json = useMemo(() => (contentTypeEntry ? JSON.stringify(contentTypeEntry, null, 2) : 'loading...'), [contentTypeEntry]);

  const fields = contentTypeEntry ? [...contentTypeEntry.properties].sort((a, b) => a.name.localeCompare(b.name)) : [];

  return (
    <ContentCloudPageLayout>
      <ContentCloudDetailsPageLayout
        pageHeader={
          <HeadlineWithBreadcrumbNavigation className="ms-2" names={{ [params.type]: contentTypeEntry?.name ?? '...' }}>
            {contentTypeEntry?.sys.name ?? '...'}
          </HeadlineWithBreadcrumbNavigation>
        }
        sidebar={
          <Tabs
            id={'sidebar-tabs'}
            className={'mb-3'}
            activeKey={sidebarTab}
            onSelect={(k: string | null) => setSidebarTab(k ?? 'general')}
          >
            <Tab eventKey={'general'} title={'General'}>
              <div>
                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Status</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={<span className="text-muted">Current</span>}
                    right={contentTypeEntry && <EntryPublishedBadge sys={contentTypeEntry.sys} />}
                  />
                </div>
                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Publication</div>
                  {contentTypeEntry && !contentTypeEntry.sys.isPublished && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'Draft saved at'}
                      right={<EntryRelativeDate date={contentTypeEntry.sys.versionCreatedAt} />}
                    />
                  )}
                  {contentTypeEntry && contentTypeEntry.sys.publishedAt && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'Published at'}
                      right={<EntryRelativeDate date={contentTypeEntry.sys.publishedAt} />}
                    />
                  )}
                  {contentTypeEntry &&
                    contentTypeEntry.sys.firstPublishedAt &&
                    contentTypeEntry.sys.firstPublishedAt !== contentTypeEntry.sys.versionCreatedAt && (
                      <LeftRightContainer
                        className="pt-2"
                        left={'First published at'}
                        right={<EntryRelativeDate date={contentTypeEntry.sys.firstPublishedAt} />}
                      />
                    )}
                </div>
              </div>
            </Tab>
            <Tab eventKey={'info'} title={'Info'}>
              <div>
                <div className="mb-4">
                  <div className="pt-2 pb-1 border-bottom">
                    <span className="small fw-bold text-muted">Details</span>
                  </div>

                  <LeftRightContainer className="pt-2" left={<span>Name</span>} right={<span>{contentTypeEntry?.name}</span>} />

                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Machine name</span>}
                    right={<span>{contentTypeEntry?.machineName}</span>}
                  />

                  <LeftRightContainer className="pt-2" left={<span>ID</span>} right={<span>{contentTypeEntry?.customId}</span>} />

                  {
                    <div className="small text-muted pb-1">
                      {contentTypeEntry?.description ? (
                        <SanitizeHtml>{contentTypeEntry.description}</SanitizeHtml>
                      ) : (
                        <em>No description provided.</em>
                      )}
                    </div>
                  }
                </div>

                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Entry</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>ID</span>}
                    right={
                      <span>
                        {contentTypeEntry?.sys.id}{' '}
                        <CopyToClipboardButton name="copy-entry-id" text={contentTypeEntry?.sys.id ?? '...'} buttonText="" buttonOnly />
                      </span>
                    }
                  />
                </div>

                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Version</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Entry</span>}
                    right={<span>{contentTypeEntry?.sys.entryVersion}</span>}
                  />
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Localization</span>}
                    right={<span>{contentTypeEntry?.sys.localizationVersion}</span>}
                  />
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Version ID</span>}
                    right={
                      <CopyToClipboardButton
                        name="copy-version-id"
                        text={contentTypeEntry?.sys.versionId ?? '...'}
                        buttonText=" Copy"
                        buttonOnly
                      />
                    }
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        }
      >
        <Tabs id={'content-tabs'} className={'mb-3'} activeKey={contentTab} onSelect={(k: string | null) => setContentTab(k ?? 'content')}>
          <Tab eventKey={'fields'} title={'Fields'}>
            <Row>
              <HeaderCol xs={5}>Name</HeaderCol>
              <HeaderCol xs={3}>Field Type</HeaderCol>
              <HeaderCol xs={2}>Localization</HeaderCol>
              <HeaderCol xs={2}>Multiple</HeaderCol>
            </Row>
            {fields.map((contentTypePropertyEntry) => (
              <Row className="m-0 p-0 mb-3" key={contentTypePropertyEntry.id}>
                <Col xs={5} className="ms-0 me-0 pt-2">
                  <strong>
                    {contentTypePropertyEntry.sys.name}
                    {contentTypePropertyEntry.isRequired ? ` *` : ''}
                  </strong>
                  <div className="small text-muted">
                    {contentTypePropertyEntry.sys.description && <SanitizeHtml>{contentTypePropertyEntry.sys.description}</SanitizeHtml>}
                  </div>
                </Col>
                <Col xs={3} className="ms-0 me-0 pt-2">
                  <ContentTypePropertyType
                    contentTypePropertyEntry={contentTypePropertyEntry}
                    contentTypeLink={
                      contentTypes?.find((c) => c.machineName === contentTypePropertyEntry.type) ??
                      (contentTypePropertyEntry.allowedTypes?.length === 1
                        ? contentTypes?.find((c) => c.machineName === contentTypePropertyEntry.allowedTypes?.[0])
                        : undefined)
                    }
                  />
                </Col>
                <Col xs={2} className="ms-0 me-0 pt-2">
                  {contentTypePropertyEntry.isLocalized ? (
                    <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                  ) : (
                    <span className="text-light">—</span>
                  )}
                </Col>
                <Col xs={2} className="ms-0 me-0 pt-2">
                  {contentTypePropertyEntry.isArray ? (
                    <FontAwesomeIcon icon={faBracketsSquare} className="text-dark" />
                  ) : (
                    <span className="text-light">—</span>
                  )}
                </Col>
              </Row>
            ))}
          </Tab>
          <Tab eventKey={'visualize'} title={'Visualize'} style={{ height: '100%' }}>
            {contentTypeEntry && (
              <ContentTypeVisualization
                rootEntry={contentTypeEntry}
                onOpenContentType={(contentType) =>
                  navigate(`/projects/${params.project}/content-cloud/content-model/${contentType.id}/visualize${location.search}`)
                }
              />
            )}
          </Tab>
          <Tab eventKey={'json'} title={'JSON Preview'}>
            <code className="border rounded border-light p-2 d-block mx-2 mb-2 position-relative">
              <div className="position-absolute" style={{ top: '10px', right: '10px' }}>
                <CopyToClipboardButton name="copy-json" buttonText=" Copy" text={json} buttonOnly />
              </div>
              <pre>{json}</pre>
            </code>
          </Tab>
        </Tabs>
      </ContentCloudDetailsPageLayout>
    </ContentCloudPageLayout>
  );
}

export const ContentTypeEntryPage = withParams<{}, { project: string; tab: string }>(
  withLocationAndNavigate<any>(
    withAppContext<any>(
      withContentCloud(ContentTypeEntryPageComponent, [
        //Permission.CONTENT_READ,
        Permission.SPACE_READ,
        Permission.CONTENT_TYPE_READ,
        Permission.PREVIEW,
        //Permission.EXTERNAL_LINK_READ,
      ])
    )
  )
);
