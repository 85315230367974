import { InternalId } from '@edgebox/data-definition-kit';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { ILocationProp, INavigateProp, withLocation, withNavigate } from '../../components/RouterHelper';
import { Link } from 'react-router-dom';
import { ApiContext } from '../contexts';
import { SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';

const BREADCRUMB_LOADING = '...';

function Breadcrumb({ parts, active, name: propName, route }: { parts: string[]; route: string; active?: boolean; name?: string }) {
  const api = useContext(ApiContext);

  let [name, setName] = useState(propName ?? '');

  useEffect(() => {
    if (propName) {
      setName(propName);
      return;
    }

    if (parts[0] === 'subscriptions' && parts.length === 2) {
      setName(BREADCRUMB_LOADING);
      api?.billing.contractRevisions.getMostRecentForContract(parts[1] as InternalId).then(({ current }) => {
        if (current) {
          setName(
            `${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue(
              'Product',
              current.product
            )} ${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('Package', current.packageType)}`
          );
        }
      });
    } else if (parts[0] === 'sites' && parts.length === 2 && parts[1] !== 'register-multiple') {
      setName(BREADCRUMB_LOADING);
      api?.billing.sites.item(parts[1] as InternalId).then((entity) => {
        if (entity) {
          setName(entity.name);
        }
      });
    } else if (parts[0] === 'projects' && parts.length === 4 && ['sites', 'dashboard', 'content', 'updates'].includes(parts[2])) {
      setName(BREADCRUMB_LOADING);
      api?.billing.sites.item(parts[3] as InternalId).then((entity) => {
        if (entity) {
          setName(entity.name);
        }
      });
    } else if (parts[0] === 'sync-cores' && parts.length === 2) {
      setName(BREADCRUMB_LOADING);
      api?.syndication.remoteServices.item(parts[1] as InternalId).then((entity) => {
        if (entity) {
          setName(entity.name);
        }
      });
    } else if (parts[2] === 'links' && parts.length === 4) {
      setName(BREADCRUMB_LOADING);
      api?.syndication.projectLinks.item(parts[3] as InternalId).then((entity) => {
        if (entity) {
          setName(entity.name);
        }
      });
    } else if (parts[0] === 'projects' && parts.length === 2) {
      setName(BREADCRUMB_LOADING);
      api?.syndication.projects.item(parts[1] as InternalId).then((entity) => {
        if (entity) {
          setName(entity.name);
        }
      });
    } else if (parts[0] === 'checkout' && parts.length === 2) {
      setName(BREADCRUMB_LOADING);
      api?.billing.baskets.item(parts[1] as InternalId).then((entity) => {
        if (entity) {
          entity.pricing.get().then((pricing) => {
            if (pricing) {
              setName(
                `${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue(
                  'Product',
                  pricing.product
                )} ${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('Package', pricing.packageType)}`
              );
            }
          });
        }
      });
    } else {
      const lastPart = parts[parts.length - 1];
      let guessedName =
        lastPart === 'json' ? 'JSON Preview' : lastPart.substring(0, 1).toLocaleUpperCase() + lastPart.substring(1).replace(/-/g, ' ');
      guessedName = guessedName.replace(/(^|\s)rest(\s|$)/gi, '$1REST$2').replace(/(^|\s)graphql(\s|$)/gi, '$1GraphQL$2');

      setName(guessedName);
    }
  }, [parts, setName, api, propName]);

  return (
    <Link to={route} className={`${active ? 'text-decoration-none' : ''} text-dark`}>
      {name}
    </Link>
  );
}

function BreadcrumbNavigationComponent(props: INavigateProp & ILocationProp & { names?: Record<string, string> }) {
  const partsOriginal = props.location.pathname.substring(1).split('/');
  const routes = partsOriginal.map((c, i) => `/${partsOriginal.slice(0, i + 1).join('/')}`);
  const parts = [...partsOriginal];
  if (parts.length < 1) {
    return null;
  }

  if (parts[0] === 'subscriptions' && parts[2] === 'revisions') {
    parts.splice(2, 2);
    routes.splice(2, 2);
  }

  if (parts[0] === 'projects' && parts[2] === 'content-cloud') {
    parts.splice(2, 1);
    routes.splice(2, 1);
  }

  return (
    <span className="d-inline-block ms-1">
      {parts.map((c, index) => (
        <React.Fragment key={index}>
          <Breadcrumb
            route={routes[index]}
            parts={parts.slice(0, index + 1)}
            name={props.names?.[parts[index]]}
            active={index === parts.length - 1}
          />
          {index < parts.length - 1 ? <span className="ms-2 me-2"> / </span> : null}
        </React.Fragment>
      ))}
    </span>
  );
}

export const BreadcrumbNavigation = withNavigate<{ names?: Record<string, string> }>(
  withLocation<INavigateProp>(BreadcrumbNavigationComponent)
);

export function HeadlineWithBreadcrumbNavigation({
  children,
  className,
  names,
}: { className?: string; names?: Record<string, string> } & PropsWithChildren) {
  return (
    <>
      <div className={`${className}`}>
        <BreadcrumbNavigation names={names} />
        <h1 className={`mt-0`}>{children}</h1>
      </div>
    </>
  );
}
