import { ClientContractEntity, ClientProjectDraft } from '@edgebox/api-rest-client';
import { StaticReference } from '@edgebox/data-definition-kit';
import { AddButton, ExternalLink, InfoBox, LeftRightContainer, Right } from '@edgebox/react-components';
import React from 'react';
import { Alert, Button, Col, Image, Modal, Row } from 'react-bootstrap';
import ProjectExplanation from '../../../images/undraw_Mind_map_re_nlb6.svg';
import CheckContentSyncRequirements from '../../../images/undraw_check_boxes_m3d0.svg';
import { ContentBox } from '../../Shared/ContentBox';
import { PagedProjectList } from '../../Shared/PagedProjectList';
import { ProjectStatus, SiteEnvironmentType } from '@edgebox/sync-core-data-definitions';
import { ApiComponent, IApiComponentState, ProjectForm } from '../../../common';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { IAppContextProp, WithAppContext, withAppContext } from '../../../common/contexts/AppContext';
import { UserRoleGate } from '../../../common/components/UserRoleGate';
import { CustomerRole } from '@edgebox/data-definitions';
import { INavigateProp, withNavigate } from '../../RouterHelper';

interface IState extends IApiComponentState {
  projectDraft?: ClientProjectDraft;
  reload?: number;
  contracts?: ClientContractEntity[];
  add?: boolean;
}

interface IProps extends JSX.IntrinsicAttributes, IAppContextProp, INavigateProp {}

const POOL_DOCS = 'https://support.content-sync.io/support/solutions/articles/80000811215-pools-categorize-your-content';
const FLOW_DOCS = 'https://support.content-sync.io/support/solutions/articles/80000811216-flows-configure-how-to-distribute-content';

class ProjectListComponent extends ApiComponent<IProps, IState> {
  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (prevProps.appContext.customer?.id !== this.props.appContext.customer?.id) {
      this.setState({
        contracts: undefined,
        projectDraft: undefined,
      });
      this.load().then((state) => this.setState(state));
    }
  }

  async load() {
    const customer = this.props.appContext.customer ?? (await this.getCurrentCustomer(true));
    const contracts = await this.api.billing.contracts.search(undefined, { itemsPerPage: 1 }, customer.id);
    const projectDraft = contracts.items.length
      ? new ClientProjectDraft({
          customer: new StaticReference(customer),
          contract: new StaticReference(contracts.items[0]),
          name: '',
          type: SiteEnvironmentType.Testing,
          status: ProjectStatus.Active,
        })
      : undefined;

    return {
      contracts: contracts.items,
      projectDraft,
    };
  }

  render() {
    const { projectDraft, contracts, reload, add } = this.state;

    if (!contracts) {
      return this.renderRequest();
    }

    const addForm = () => (
      <ProjectForm
        onSave={async (draft) => {
          const project = await this.api.syndication.projects.create(draft as ClientProjectDraft);
          this.setState({
            reload: Date.now(),
            add: false,
          });

          this.props.appContext.refreshProjects?.();

          this.props.navigate(`/projects/${project.id}/onboarding`);
        }}
        entity={projectDraft!}
        mode={'create'}
      >
        <Right>
          <Button type={'submit'} variant={'primary'}>
            Create
          </Button>
        </Right>
      </ProjectForm>
    );

    return (
      <>
        <LeftRightContainer
          className="mb-4"
          left={<HeadlineWithBreadcrumbNavigation className="ms-2">Projects</HeadlineWithBreadcrumbNavigation>}
          right={
            <UserRoleGate allowed={[CustomerRole.Owner, CustomerRole.ManageSites]}>
              {() => (
                <AddButton variant="primary" onClick={() => this.setState({ add: true })}>
                  Project
                </AddButton>
              )}
            </UserRoleGate>
          }
        />
        {projectDraft && (
          <Modal show={add} scrollable onHide={() => this.setState({ add: undefined })}>
            <Modal.Header closeButton>
              <Modal.Title>Add Project</Modal.Title>
            </Modal.Header>
            <Modal.Body>{addForm()}</Modal.Body>
          </Modal>
        )}
        <WithAppContext>
          {(appContext) => (
            <PagedProjectList
              customerId={appContext?.customer?.id}
              key={(reload?.toString() || '') + appContext?.customer?.id}
              emptyMessageWithNoFilters={
                <>
                  <h2>Create your first space</h2>

                  {contracts.length ? undefined : <Alert variant="warning">Please connect a site first.</Alert>}

                  <ContentBox className={'pb-4'}>
                    <Row className={'mt-5'}>
                      <Col xs={6} className={'ps-5 pt-3'}>
                        <Image src={ProjectExplanation} height={150} />
                      </Col>
                      <Col xs={6}>
                        <h3>Group your sites</h3>
                        <ul>
                          <li>Projects logically separate sites.</li>
                          <li>You can connect a site to only one project.</li>
                          <li>Content will never be shared between sites of different projects.</li>
                        </ul>
                        <InfoBox>
                          To control what sites can push and pull content on a per-content basis, connect your sites to the same project and
                          then control the flow of content by creating different <ExternalLink to={POOL_DOCS}>Pools</ExternalLink> and{' '}
                          <ExternalLink to={FLOW_DOCS}>Flows</ExternalLink>.
                        </InfoBox>
                      </Col>
                    </Row>

                    {projectDraft ? (
                      <UserRoleGate allowed={[CustomerRole.Owner, CustomerRole.ManageSites]}>
                        {() => (
                          <Row className={'mt-5'}>
                            <Col xs={6}>
                              <h3>Getting started</h3>
                              <p>Just enter a name to continue:</p>
                              {addForm()}
                            </Col>
                            <Col xs={6} className={'ps-5 pt-3'}>
                              <Image src={CheckContentSyncRequirements} height={150} />
                            </Col>
                          </Row>
                        )}
                      </UserRoleGate>
                    ) : undefined}
                  </ContentBox>
                </>
              }
            />
          )}
        </WithAppContext>
      </>
    );
  }
}

export const ProjectList = withNavigate<{}>(withAppContext(ProjectListComponent));
