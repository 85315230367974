import { ClientCustomerEntity, ClientProjectLinkEntity } from '@edgebox/api-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { UserType } from '@edgebox/data-definitions';
import { ButtonCheckbox, CustomCardColumns, HeaderCol } from '@edgebox/react-components';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { ApiComponent, CustomerSelector, IApiComponentState, PagedList, ProjectLink } from '../../common/index';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SiteApplicationIcon } from '../Icons';

interface IProps {
  backend?: boolean;
  customerId?: InternalId;
  projectId?: InternalId;
  projectConnection?: 'source' | 'target' | 'any';
  emptyMessage?: React.ReactNode;
  emptyMessageWithNoFilters?: React.ReactNode;
  itemDisplay?: 'list' | 'cards';
  onEmpty?: () => void;
}

interface IFilters {
  search?: string;
  customerId?: InternalId;
  includeInactive?: boolean;
}

interface IState extends IApiComponentState {
  search?: string;
  filterCustomer?: ClientCustomerEntity;
  includeInactive?: boolean;
}

export class PagedProjectLinkList extends ApiComponent<IProps, IState> {
  async load() {
    const { customerId } = this.props;
    const filterCustomer = customerId ? await this.api.billing.customers.item(customerId) : undefined;

    return {
      filterCustomer,
    };
  }

  render() {
    const { backend, customerId, emptyMessage, emptyMessageWithNoFilters, itemDisplay, projectId, projectConnection } = this.props;
    const { filterCustomer, includeInactive } = this.state;

    const renderBackendProperties = this.api.currentUser?.type === UserType.Internal && backend;

    const showSingleProject = projectConnection === 'source' || projectConnection === 'target';

    return (
      <PagedList<ClientProjectLinkEntity, IFilters>
        hidePagerIfNotNeeded
        renderList={(items) => (itemDisplay === 'cards' ? <CustomCardColumns columnCount={3}>{items}</CustomCardColumns> : items)}
        request={(page, filter) =>
          this.api.syndication.projectLinks
            .search(
              {
                customerId: filter?.customerId || customerId || this.api.currentUser?.customer?.getId(),
                ...(projectId
                  ? {
                      [projectConnection === 'source'
                        ? 'sourceProjectId'
                        : projectConnection === 'target'
                          ? 'targetProjectId'
                          : 'anyProjectId']: projectId,
                    }
                  : {}),
              },
              { page, itemsPerPage: 9 },
              {
                includeInactive: filter?.includeInactive,
              }
            )
            .then((response) => {
              if (this.props.onEmpty && !response.totalNumberOfItems) {
                this.props.onEmpty();
              }

              return response;
            })
        }
        renderFilters={(onChange) => {
          const onType = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | React.KeyboardEvent<any>) => {
            const value = (e.target as HTMLInputElement).value;
            onChange('search', value);
            this.setState({ search: value });
          };
          const backendFilters =
            renderBackendProperties && !customerId ? (
              <Col xs={3}>
                <CustomerSelector
                  value={filterCustomer}
                  onRemove={() => {
                    this.setState({ filterCustomer: undefined });
                    onChange('customerId', undefined, true);
                  }}
                  onSelected={(filterCustomer) => {
                    this.setState({ filterCustomer });
                    onChange('customerId', filterCustomer.id, true);
                  }}
                  emptyLabel={'Filter by customer'}
                />
              </Col>
            ) : undefined;
          return (
            <Row>
              <Col xs={2}>
                <ButtonCheckbox
                  checked={!!includeInactive}
                  onToggle={() => {
                    this.setState({ includeInactive: !includeInactive });
                    onChange('includeInactive', !includeInactive, true);
                  }}
                >
                  Show inactive
                </ButtonCheckbox>
              </Col>
              {backendFilters}
            </Row>
          );
        }}
        emptyMessageWithNoFilters={emptyMessageWithNoFilters}
        emptyMessage={emptyMessage || <Alert variant={'light'}>No space links match your filter.</Alert>}
        renderListHeader={() => (
          <Row className="m-0 p-0">
            <HeaderCol xs={3}>Name</HeaderCol>
            {projectConnection !== 'source' && <HeaderCol xs={showSingleProject ? 4 : 2}>Source</HeaderCol>}
            {projectConnection !== 'target' && <HeaderCol xs={showSingleProject ? 4 : 2}>Target</HeaderCol>}
            <HeaderCol xs={4}>Mapping</HeaderCol>
          </Row>
        )}
        renderItem={(projectLink) => (
          <Link
            to={`/projects/${projectConnection === 'source' ? projectLink.sourceProject.getId() : projectLink.targetProject.getId()}/links/${projectLink.id}`}
            className={'text-black ps-3 pe-1 pt-1 pb-1 d-block rounded bg-white mb-2 hover-shadow text-decoration-none'}
            key={projectLink.id}
          >
            <Row className="m-0 pt-3">
              <Col className="m-0 p-0 text-truncate mw-100" sm={3}>
                {projectLink.name}
              </Col>
              {projectConnection !== 'source' ? (
                <Col sm={showSingleProject ? 4 : 2} className="fs-6">
                  <SiteApplicationIcon type={projectLink.sourceAppType} /> <ProjectLink entityId={projectLink.sourceProject.getId()} />
                </Col>
              ) : null}
              {projectConnection !== 'target' ? (
                <Col sm={showSingleProject ? 4 : 2} className="fs-6">
                  <SiteApplicationIcon type={projectLink.targetAppType} /> <ProjectLink entityId={projectLink.targetProject.getId()} />
                </Col>
              ) : null}
              <Col sm={4} className="fs-6 text-truncate mw-100">
                {projectLink.sourcePoolMachineNames.length === 1 ? (
                  <>
                    From <em>{projectLink.sourcePoolMachineNames[0]}</em> to <strong>{projectLink.targetPoolMachineName}</strong>{' '}
                    environment
                  </>
                ) : (
                  <>
                    From {projectLink.sourcePoolMachineNames.length} pools to <strong>{projectLink.targetPoolMachineName}</strong>{' '}
                    environment
                  </>
                )}
              </Col>
              <Col sm={1} className="text-end fs-4 text-light">
                <FontAwesomeIcon icon={faChevronRight} className="d-inline-block align-top" />
              </Col>
            </Row>
          </Link>
        )}
      />
    );
  }
}
