import React, { useEffect, useMemo, useState } from 'react';
import { Permission } from '../shared-permissions';
import { ContentCloudComponentProps, withContentCloud } from '../WithContentCloud';
import { ContentEntry, ContentTypeEntry, LocaleEntry, ContentCloudRestClient } from '../RestClient';
import { CONTENT_CLOUD_API_VERSION, getContentCloudSatelliteUrl, loadAllContentTypes } from '../content-cloud-helper';
import { Alert, Badge, Col, Form, Row } from 'react-bootstrap';
import { getStyleColors } from '../../../../common';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { AsyncPaginate } from 'react-select-async-paginate';
import { HeaderCol, LoadingBar, Pager } from '@edgebox/react-components';
import { faBarsSort } from '@fortawesome/pro-light-svg-icons/faBarsSort';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import { ContentCloudListPageLayout } from '../Layouts/ContentCloudListPageLayout';
import { EntryPublishedBadge } from '../Components/EntryPublishedBadge';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';

const ENTRIES_PER_PAGE = 10;
const FILTER_ENTRIES_PER_PAGE = 10;

interface Props extends ContentCloudComponentProps {
  byStatus?: 'published' | 'draft' | null;
  byContentTypeId?: string | null;
}

function ContentCollectionPage({ contentCloudData, byContentTypeId, byStatus }: Props) {
  const { environment, space, accessToken, contentCloud } = contentCloudData ?? {};

  const client = useMemo(
    () =>
      contentCloud &&
      accessToken &&
      space &&
      environment &&
      new ContentCloudRestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
      }),
    [accessToken, space, environment, contentCloud]
  );
  const previewClient = useMemo(
    () =>
      contentCloud && accessToken && space && environment
        ? new ContentCloudRestClient({
            accessToken,
            baseUrl: getContentCloudSatelliteUrl(contentCloud, {
              api: 'rest',
              environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
              service: 'preview',
              version: CONTENT_CLOUD_API_VERSION,
            }),
          })
        : null,
    [accessToken, space, environment, contentCloud]
  );

  const [localeEntry, setLocaleEntry] = useState<LocaleEntry | null>(null);

  const [contentTypes, setContentTypes] = useState<ContentTypeEntry[] | null>(null);
  useEffect(
    () => (client ? !!loadAllContentTypes(client).then((contentTypes) => setContentTypes(contentTypes)) : true) && void 0,
    [client]
  );

  const { primary, danger } = getStyleColors();

  const displayContentTypes = contentTypes?.filter((c) => c.isIndependent);

  const displayComponentTypes = contentTypes?.filter((c) => !c.isIndependent && !c.isInline);

  const [entries, setEntries] = useState<ContentEntry[] | null>(null);
  const [page, setPage] = useState<number | null>(null);
  const [numberOfItems, setNumberOfItems] = useState<number | null>(null);

  // When the filters change, update the count and navigate to the first page.
  useEffect(() => {
    setPage(0);
    setNumberOfItems(null);
  }, [byStatus, byContentTypeId, localeEntry]);

  // Refresh the entry list.
  useEffect(() => {
    setEntries(null);

    const useClient = byStatus === 'published' ? client : previewClient;

    useClient &&
      typeof page === 'number' &&
      useClient
        .contentCollection({
          limit: ENTRIES_PER_PAGE,
          skip: page * ENTRIES_PER_PAGE,
          ...(localeEntry
            ? {
                locale: localeEntry.code,
              }
            : {}),
          include: 0,
          embed: 0,
          ...(byContentTypeId
            ? {
                content_type: byContentTypeId,
              }
            : {}),
          order: '-sys.versionCreatedAt',
          ...(byStatus === 'draft'
            ? {
                'sys.localizationFirstPublishedAt[exists]': 'false',
              }
            : {}),
        })
        .then((response) => {
          setNumberOfItems(response.total);
          setEntries(response.items);
        });
  }, [client, previewClient, byStatus, byContentTypeId, localeEntry, page]);

  return (
    <ContentCloudPageLayout>
      <ContentCloudListPageLayout
        filterHeader={
          <>
            <div className={`px-2 pb-3 border-bottom`}>
              <Link to={`?`} className="text-decoration-none text-black">
                <div className={`${!byContentTypeId && !byStatus ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faBarsSort} className="me-2" />
                  All content
                </div>
              </Link>
            </div>
            <div className={`px-2 py-3 border-bottom`}>
              <div className="text-muted small pe-2">Status</div>
              <Link to={`?status=published`} className="text-decoration-none text-black">
                <div className={`${byStatus === 'published' ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faGlobe} className="me-2" />
                  Published
                </div>
              </Link>
              <Link to={`?status=draft`} className="text-decoration-none text-black">
                <div className={`${byStatus === 'draft' ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faLock} className="me-2" />
                  Draft
                </div>
              </Link>
            </div>
          </>
        }
        filterBody={
          <>
            <div className={`px-2 py-3 border-bottom`}>
              <div className="text-muted small pe-2">Content Types</div>
              {displayContentTypes?.map((contentType) => (
                <Link key={contentType.id} to={`?contentTypeId=${contentType.id}`} className="text-decoration-none text-black">
                  <div className={`${byContentTypeId === contentType.id ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                    {contentType.sys.name}
                  </div>
                </Link>
              )) ?? '...'}
            </div>
            <div className={`px-2 py-3`}>
              <div className="text-muted small pe-2">Component Types</div>
              {displayComponentTypes?.map((contentType) => (
                <Link key={contentType.id} to={`?contentTypeId=${contentType.id}`} className="text-decoration-none text-black">
                  <div className={`${byContentTypeId === contentType.id ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                    {contentType.sys.name}
                  </div>
                </Link>
              )) ?? '...'}
            </div>
          </>
        }
        contentHeader={
          <HeadlineWithBreadcrumbNavigation className="ms-2">
            {byStatus === 'draft' ? 'Draft' : byStatus === 'published' ? 'Published' : 'All'}{' '}
            {byContentTypeId ? (contentTypes?.find((c) => c.id === byContentTypeId)?.name ?? 'Content') : 'Content'}
          </HeadlineWithBreadcrumbNavigation>
        }
      >
        <div>
          <Form className={'mb-3'}>
            <Col xs={9}></Col>
            <Col xs={3}>
              {client ? (
                <AsyncPaginate
                  isClearable={true}
                  isMulti={false}
                  loadOptions={async (search, lastOptions, additional: any) => {
                    const page = additional ? additional.page + 1 : 0;
                    const response = await client.localeCollection({
                      skip: page * FILTER_ENTRIES_PER_PAGE,
                      limit: FILTER_ENTRIES_PER_PAGE,
                      'sys.name': search,
                    });

                    return {
                      options: response.items,
                      hasMore: response.skip < response.total,
                      additional: { page },
                    };
                  }}
                  placeholder="Change Locale..."
                  value={localeEntry}
                  getOptionValue={(option) => option.sys.id}
                  getOptionLabel={(option) => option.sys.name!}
                  onChange={(option) => {
                    setLocaleEntry(option);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary,
                      danger,
                    },
                  })}
                />
              ) : (
                <LoadingBar />
              )}
            </Col>
          </Form>
        </div>
        <div>
          {!!entries?.length && (
            <Row>
              <HeaderCol xs={4}>Name</HeaderCol>
              <HeaderCol xs={3}>Content Type</HeaderCol>
              <HeaderCol xs={2}>Updated</HeaderCol>
              <HeaderCol xs={2}>Status</HeaderCol>
              <HeaderCol xs={1}>Actions</HeaderCol>
            </Row>
          )}
          {entries?.length ? (
            entries.map((contentEntry) => (
              <Link
                to={`/content-cloud/content/${contentEntry.sys.id}?locale=${localeEntry?.code ?? ''}`}
                className={'text-black ps-3 pe-1 pt-1 pb-1 d-block rounded bg-white mb-2 hover-shadow text-decoration-none'}
                key={contentEntry.sys.id}
              >
                <Row className="m-0 p-0">
                  <Col xs={4} className="ms-0 me-0 pt-2">
                    {contentEntry.sys.name}
                  </Col>
                  <Col xs={3} className="ms-0 me-0 pt-2">
                    {contentEntry.sys.contentType?.sys.type === 'ContentType' ? (
                      contentEntry.sys.contentType.sys.name
                    ) : contentTypes ? (
                      contentTypes.find((c) => c.id === contentEntry.sys.contentType?.sys.id)?.sys.name
                    ) : (
                      <Badge bg="neutral">...</Badge>
                    )}
                  </Col>
                  <Col xs={2} className="ms-0 me-0 pt-2">
                    <EntryRelativeDate date={contentEntry.sys.versionCreatedAt} />
                  </Col>
                  <Col xs={2} className="ms-0 me-0 pt-2">
                    <EntryPublishedBadge sys={contentEntry.sys} />
                  </Col>
                  <Col xs={1} className="text-end fs-4 text-light pt-1">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Col>
                </Row>
              </Link>
            ))
          ) : entries ? (
            <Alert variant="light">No matches.</Alert>
          ) : (
            <LoadingBar />
          )}
        </div>
        {!!numberOfItems && entries ? (
          <Pager
            numberOfPages={Math.ceil(numberOfItems / ENTRIES_PER_PAGE)}
            page={page ?? 0}
            totalNumberOfItems={numberOfItems}
            onChange={(page) => setPage(page)}
          />
        ) : null}
      </ContentCloudListPageLayout>
    </ContentCloudPageLayout>
  );
}

export default withContentCloud(ContentCollectionPage, [
  Permission.CONTENT_READ,
  Permission.SPACE_READ,
  Permission.CONTENT_TYPE_READ,
  Permission.PREVIEW,
]);
