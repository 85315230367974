import Dagre from '@dagrejs/dagre';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ReactFlow,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  useReactFlow,
  Handle,
  Position,
  Background,
  BackgroundVariant,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { ContentTypeEntry, ContentCloudRestClient } from '../../RestClient';
import { ContentCloudComponentProps, withContentCloud } from '../../WithContentCloud';
import { Permission } from '../../shared-permissions';
import { CONTENT_CLOUD_API_VERSION, getContentCloudSatelliteUrl, loadAllContentTypes } from '../../content-cloud-helper';
import { LoadingBar } from '@edgebox/react-components';
import { NodeProps, Node, Edge } from '@xyflow/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { getStyleColors } from '../../../../../common';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { useNavigate } from 'react-router';

type LayoutDirection = 'TB' | 'LR';

type ContentTypeCallback = (contentType: ContentTypeEntry) => void;

type ContentTypePropertyData = {
  id: string;
  name: string;
  type: string;
  link: boolean;
};

export type ContentTypeNodeType = Node<
  {
    name: string;
    direction: LayoutDirection;
    expanded: boolean;
    onToggleExpansion: () => void;
    properties: ContentTypePropertyData[];
  },
  'contentType'
>;

const NODE_HEADER_HEIGHT = 40;
const NODE_ROW_HEIGHT = 33;
export function ContentTypeNode(props: NodeProps<ContentTypeNodeType>) {
  const { direction, name, onToggleExpansion, expanded, properties } = props.data;

  return (
    <div
      className={`bg-white shadow rounded border ${props.selected ? 'border-primary' : 'border-white'}`}
      style={
        {
          width: `${DEFAULT_WIDTH}px`,
          '--xy-handle-background-color-default': props.selected ? getStyleColors().primary : '#b1b1b7',
          '--xy-handle-border-color-default': props.selected ? getStyleColors().primary : '#FFF',
        } as React.CSSProperties
      }
    >
      <div className="py-2 px-3" style={{ height: `${NODE_HEADER_HEIGHT}px` }}>
        <Handle
          type="target"
          position={direction === 'TB' ? Position.Top : Position.Left}
          isConnectable={false}
          style={{ top: NODE_HEADER_HEIGHT / 2 }}
        />
        <div className="d-flex">
          <div className="flex-grow-0 flex-shrink-0">
            <Button variant="light" className="p-0 me-2 color-light" onClick={onToggleExpansion}>
              <FontAwesomeIcon icon={faChevronDown} flip={expanded ? 'vertical' : undefined} />
            </Button>
          </div>
          <div className="fw-bold flex-grow-1 flex-shrink-1 text-truncate">{name}</div>
        </div>
        <Handle
          type="source"
          position={direction === 'TB' ? Position.Bottom : Position.Right}
          isConnectable={false}
          id={'type'}
          style={{ top: NODE_HEADER_HEIGHT / 2, ...(expanded ? { opacity: '0' } : {}) }}
        />
      </div>
      {expanded && (
        <div>
          {properties.map((property, index) => (
            <div key={property.id}>
              <div className="d-flex py-1 px-2 border-top border-light" style={{ height: `${NODE_ROW_HEIGHT}px` }}>
                <div className="flex-grow-1 flex-shrink-1 text-truncate" title={property.name}>
                  {property.name}
                </div>
                <div className="text-dark flex-grow-1 flex-shrink-1 text-truncate text-end" title={property.type}>
                  {property.type}
                </div>
                {property.link && (
                  <Handle
                    type="source"
                    position={Position.Right}
                    isConnectable={false}
                    id={property.id}
                    style={{ top: NODE_HEADER_HEIGHT + (index + 0.5) * NODE_ROW_HEIGHT }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const DEFAULT_WIDTH = 350;
const DEFAULT_HEIGHT = 40;
const DEFAULT_MARGIN_FLOW_DIRECTION = 100;
const DEFAULT_MARGIN_PERPENDICULAR_FLOW_DIRECTION = 50;
const SNAP_GRID = [25, 25] as [number, number];

const getLayoutedElements = (nodes: ContentTypeNodeType[], edges: Edge[], options: { direction: 'TB' | 'LR' }) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({
    rankdir: options.direction,
    nodesep: DEFAULT_MARGIN_PERPENDICULAR_FLOW_DIRECTION,
    edgesep: DEFAULT_MARGIN_PERPENDICULAR_FLOW_DIRECTION,
    ranksep: DEFAULT_MARGIN_FLOW_DIRECTION,
  });

  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) =>
    g.setNode(node.id, {
      ...node,
      width: node.measured?.width ?? DEFAULT_WIDTH,
      height:
        (node.measured?.height ?? node.data.expanded) ? NODE_HEADER_HEIGHT + NODE_ROW_HEIGHT * node.data.properties.length : DEFAULT_HEIGHT,
    })
  );

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const position = g.node(node.id);
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      const x = position.x - (node.measured?.width ?? DEFAULT_WIDTH) / 2;
      const y =
        position.y -
        ((node.measured?.height ?? node.data.expanded)
          ? NODE_HEADER_HEIGHT + NODE_ROW_HEIGHT * node.data.properties.length
          : DEFAULT_HEIGHT) /
          2;

      return { ...node, position: { x, y } };
    }),
    edges,
  };
};

const NULL_CALLBACK = () => void 0;
const LayoutFlow = ({
  contentTypes,
  rootEntry,
  direction: directionIn,
  onOpenContentType,
}: {
  rootEntry?: ContentTypeEntry;
  contentTypes: ContentTypeEntry[];
  direction?: LayoutDirection;
  onOpenContentType?: ContentTypeCallback;
}) => {
  const direction: LayoutDirection = directionIn ?? 'LR';
  const flow = useReactFlow();
  const [expanded, setExpanded] = useState<string[]>([]);

  const computed = useMemo<{ nodes: ContentTypeNodeType[]; edges: Edge[] }>(() => {
    function addType(type: ContentTypeEntry, addTargets?: string[]) {
      if (nodes.find((c) => c.id === type.id)) {
        return;
      }

      nodes.push({
        selectable: true,
        selected: rootEntry?.id === type.id,
        id: type.id,
        type: 'contentType',
        data: {
          name: type.name,
          direction,
          properties: type.properties.map((property) => ({
            id: property.id,
            name: property.name,
            type: property.type,
            link: property.type === 'AnyEntry' || !!contentTypes.find((c) => c.machineName === property.type),
          })),
          expanded: false,
          onToggleExpansion: NULL_CALLBACK,
        },
        position: { x: 0, y: 0 },
      });

      for (const property of type.properties) {
        let targets: ContentTypeEntry[] = [];
        if (property.allowedTypes) {
          targets = contentTypes.filter((c) => property.allowedTypes?.includes(c.machineName));
        } else {
          const otherType = contentTypes.find((c) => c.machineName === property.type);
          if (otherType) {
            targets.push(otherType);
          }
        }

        if (addTargets) {
          targets = targets.filter((target) => !!addTargets.includes(target.id));
        }

        for (const target of targets) {
          addType(target, addTargets);
          edges.push({
            id: `${type.id}-${property.id}-${target.id}`,
            source: type.id,
            target: target.id,
            sourceHandle: 'type',
          });
        }
      }
    }

    const nodes: ContentTypeNodeType[] = [];
    const edges: Edge[] = [];

    if (rootEntry) {
      addType(rootEntry);

      // Add sources referencing the rendered type
      for (const type of contentTypes) {
        const hasTarget = type.properties.find((c) => c.type === rootEntry.machineName || c.allowedTypes?.includes(rootEntry.machineName));
        if (hasTarget) {
          addType(type, [rootEntry.id]);
        }
      }
    } else {
      for (const type of contentTypes) {
        addType(type);
      }
    }

    return { nodes, edges };
  }, [contentTypes, rootEntry, direction]);

  const styled = useMemo<{ nodes: ContentTypeNodeType[]; edges: Edge[] }>(() => {
    return {
      nodes: computed.nodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          expanded: expanded.includes(node.id),
          onToggleExpansion: () => {
            console.log(node, expanded);
            if (expanded.includes(node.id)) {
              setExpanded(expanded.filter((c) => c !== node.id));
            } else {
              setExpanded([...expanded, node.id]);
            }
          },
        },
      })),
      edges: computed.edges.map((edge) => {
        const sourceExpanded = expanded.includes(edge.source);
        return {
          ...edge,
          ...(sourceExpanded
            ? {
                sourceHandle: edge.id.split('-')[1],
              }
            : {}),
        };
      }),
    };
  }, [computed, expanded, setExpanded]);

  const [nodes, setNodes, onNodesChange] = useNodesState(styled.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(styled.edges);

  const onLayout = useCallback(
    (direction: LayoutDirection) => {
      const layouted = getLayoutedElements(styled.nodes, styled.edges, { direction });

      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);
    },
    [setNodes, setEdges, styled]
  );
  useEffect(() => onLayout(direction), [direction, onLayout]);
  useEffect(() => {
    window.requestAnimationFrame(() => {
      flow.fitView();
      setTimeout(() => flow.fitView(), 0);
      setTimeout(() => flow.fitView(), 100);
    });
  }, [flow, rootEntry]);

  return (
    <ReactFlow
      maxZoom={2}
      minZoom={0.25}
      nodes={nodes}
      edges={edges}
      nodeTypes={{ contentType: ContentTypeNode }}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitView
      onNodeDoubleClick={onOpenContentType ? (e, node) => onOpenContentType(contentTypes.find((c) => c.id === node.id)!) : undefined}
      defaultViewport={{
        x: 0,
        y: 0,
        zoom: 0.25,
      }}
      snapGrid={SNAP_GRID}
    >
      <Background color="#aaa" variant={BackgroundVariant.Dots} />
    </ReactFlow>
  );
};

function ContentTypeVisualizationComponent({
  contentCloudData,
  rootEntry,
  onOpenContentType,
  fullscreen,
}: { rootEntry?: ContentTypeEntry; onOpenContentType?: ContentTypeCallback; fullscreen?: boolean } & ContentCloudComponentProps) {
  const { accessToken, contentCloud, environment, space } = contentCloudData ?? {};

  const navigate = useNavigate();

  const client = useMemo(
    () =>
      contentCloud &&
      accessToken &&
      space &&
      environment &&
      new ContentCloudRestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
      }),
    [accessToken, space, environment, contentCloud]
  );

  const [contentTypes, setContentTypes] = useState<ContentTypeEntry[] | null>(null);
  useEffect(
    () => (client ? !!loadAllContentTypes(client).then((contentTypes) => setContentTypes(contentTypes)) : true) && void 0,
    [client]
  );

  return (
    <ReactFlowProvider>
      <div
        className="bg-white"
        style={
          fullscreen
            ? {
                position: 'fixed',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                zIndex: 101,
              }
            : { width: '100%', height: 'calc(-248px + 100vh)' }
        }
      >
        <div style={{ background: 'rgba(0, 68, 227, 0.02)', width: '100%', height: '100%' }}>
          {contentTypes ? (
            <LayoutFlow contentTypes={contentTypes} rootEntry={rootEntry} onOpenContentType={onOpenContentType} />
          ) : (
            <LoadingBar />
          )}
        </div>
        {fullscreen && (
          <div style={{ position: 'absolute', top: 10, right: 10 }} className="cursor-pointer" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faTimes} size="2x" className="text-dark" />
          </div>
        )}
      </div>
    </ReactFlowProvider>
  );
}

export const ContentTypeVisualization = withContentCloud(ContentTypeVisualizationComponent, [
  Permission.SPACE_READ,
  Permission.CONTENT_TYPE_READ,
]);
