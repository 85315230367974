import { Onboarding, TaskStatusList } from './OnboardingDefinitions';

export type ContentCloudOnboardingTask = 'create-space' | 'connect-source' | 'integrate' | 'explore' | 'content-user-data' | 'next-steps';

export const CONTENT_CLOUD_TASK_CREATE_SPACE: ContentCloudOnboardingTask = 'create-space';
export const CONTENT_CLOUD_TASK_CONNECT_SOURCE: ContentCloudOnboardingTask = 'connect-source';
export const CONTENT_CLOUD_TASK_INTEGRATE: ContentCloudOnboardingTask = 'integrate';
export const CONTENT_CLOUD_TASK_EXPLORE: ContentCloudOnboardingTask = 'explore';
export const CONTENT_CLOUD_TASK_CONTENT_USER_DATA: ContentCloudOnboardingTask = 'content-user-data';
export const CONTENT_CLOUD_TASK_NEXT_STEPS: ContentCloudOnboardingTask = 'next-steps';

export const CONTENT_CLOUD_ONBOARDING_TASK_NAMES: ContentCloudOnboardingTask[] = [
  'create-space',
  'connect-source',
  'integrate',
  'explore',
  'content-user-data',
  'next-steps',
];

export const CONTENT_CLOUD_DEFAULT_TASK_STATUS: TaskStatusList<ContentCloudOnboardingTask> = {
  'create-space': 'loading',
  'connect-source': 'loading',
  integrate: 'loading',
  explore: 'loading',
  'content-user-data': 'loading',
  'next-steps': false,
};

export const DRUPAL_ONBOARDING: Onboarding<ContentCloudOnboardingTask> = {
  defaultStatuses: CONTENT_CLOUD_DEFAULT_TASK_STATUS,
  taskNames: CONTENT_CLOUD_ONBOARDING_TASK_NAMES,
};
