import { ClientCustomerEntity, ClientProjectDraft, ClientProjectEntity } from '@edgebox/api-rest-client';
import { DynamicReference, StaticReference } from '@edgebox/data-definition-kit';
import { ApiContext } from '../../../contexts';
import { AppContext } from '../../../contexts/AppContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Product, ProjectStatus, SiteApplicationType, SiteEnvironmentType } from '@edgebox/sync-core-data-definitions';
import { LoadingBar, Right } from '@edgebox/react-components';
import React from 'react';
import { NULL_ID } from '../../../constants';
import { CustomerRole } from '@edgebox/data-definitions';
import { StartTrial } from '../../../../components/Shared/StartTrial';
import { Alert } from 'react-bootstrap';
import { ProjectForm } from './ProjectForm';
import { PrimaryActionButton } from '../../../../components/Shared/PrimaryActionButton';

export default function ProjectFormNewCustomer({
  forceNew,
  onProject,
}: {
  forceNew?: boolean;
  onProject: (project: ClientProjectEntity) => void;
}) {
  const api = useContext(ApiContext);

  const app = useContext(AppContext);

  const [newProjectEntity, setNewProjectEntity] = useState(
    new ClientProjectDraft({
      customer: new DynamicReference(NULL_ID, (() => null) as any),
      contract: new DynamicReference(NULL_ID, (() => null) as any),
      name: api?.currentUser
        ? api.currentUser.firstName.endsWith('s')
          ? `${api.currentUser.firstName} Space`
          : `${api.currentUser.firstName}s Space`
        : '',
      type: SiteEnvironmentType.Production,
      appType: SiteApplicationType.Drupal,
      status: ProjectStatus.Active,
    })
  );

  const [project, setProject] = useState(forceNew ? null : app.project);
  useEffect(() => (project ? onProject(project) : void 0), [onProject, project]);

  const saveNewProject = useMemo(
    () => async (projectEntity: ClientProjectDraft | ClientProjectEntity) => {
      if (!api) {
        return;
      }
      const saved = await api.syndication.projects.create(projectEntity);
      setProject(saved);
    },
    [api]
  );

  const [customer, setCustomer] = useState<ClientCustomerEntity | null>(null);
  useEffect(() => {
    if (!api?.currentUser) {
      return;
    }

    if (api.currentUser.customer?.getId() === customer?.id) {
      return;
    }

    api.currentUser.customer?.get().then((assignCustomer) => {
      if (assignCustomer && customer?.id !== assignCustomer.id) {
        setCustomer(assignCustomer);
        setNewProjectEntity(new ClientProjectDraft({ ...newProjectEntity, customer: new StaticReference(assignCustomer) }));
      }
    });
  }, [api?.currentUser, newProjectEntity, customer]);

  useEffect(() => {
    if (!api || !customer) {
      return;
    }

    if (newProjectEntity.contract.getId() !== NULL_ID) {
      return;
    }

    // Get the first active contract to see if there are any. If there are
    // none, we can then check if the user is allowed to start a new one and
    // if they are, we show the <StartTrial>. Otherwise we show an error.
    api.billing.contracts.search(undefined, { itemsPerPage: 1 }, customer.id, false).then((allContracts) => {
      if (allContracts.items.length) {
        setNewProjectEntity(new ClientProjectDraft({ ...newProjectEntity, contract: new StaticReference(allContracts.items[0]) }));
      }
    });
  }, [api, customer, newProjectEntity]);

  if (!customer) {
    return <LoadingBar />;
  }

  const canPurchaseNew =
    api?.currentUser?.customerRoles?.includes(CustomerRole.Owner) || api?.currentUser?.customerRoles?.includes(CustomerRole.ManageFinances);

  const startNew = newProjectEntity.contract.getId() === NULL_ID;

  if (startNew) {
    if (!canPurchaseNew) {
      return (
        <Alert variant="warn">
          You don't have the necessary permissions to start a trial. Please ask the account owner to start a new trial or update your user
          permissions.
        </Alert>
      );
    }

    return (
      <StartTrial
        forceNew={true}
        forCustomer={customer.id}
        onChange={(contract, isInitial) => {
          setNewProjectEntity(new ClientProjectDraft({ ...newProjectEntity, contract: new StaticReference(contract) }));
        }}
        numberOfSites={2}
        forceProduct={Product.Syndication}
      />
    );
  }

  return (
    <ProjectForm entity={newProjectEntity} mode="create" onSave={saveNewProject}>
      <Right>
        <PrimaryActionButton type={'submit'}>Create space</PrimaryActionButton>
      </Right>
    </ProjectForm>
  );
}
