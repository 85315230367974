import { ClientProjectLinkEntity } from '@edgebox/api-rest-client';
import { CustomerRole, UserType } from '@edgebox/data-definitions';
import { EditButton, LeftRightContainer, Right } from '@edgebox/react-components';
import React from 'react';
import { Button, Col, Image, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { ApiComponent, CustomerLink, IApiComponentState, ProjectLink } from '../../../common/index';
import { ILocationProp, INavigateProp, IParamsProp, withLocationAndNavigate, withParams } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';
import ProjectLinkDetailImage from '../../../images/undraw_Mind_map_re_nlb6.svg';
import { IconSummaryItem } from '../../Shared/IconSummaryItem';
import { faFingerprint } from '@fortawesome/pro-light-svg-icons/faFingerprint';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { ProjectLinkStatus } from '@edgebox/sync-core-data-definitions';
import { propertyChangeReload } from '../../../common/helpers/propertyChangeReload';
import { IAppContextProp, withAppContext } from '../../../common/contexts/AppContext';
import { UserRoleGate } from '../../../common/components/UserRoleGate';
import { InternalId } from '@edgebox/data-definition-kit';
import { ProjectLinkForm } from '../../../common/components/Syndication/Forms/ProjectLinkForm';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { ProjectLinkIcons, SiteApplicationIcon } from '../../Icons';

interface IExternalProps {
  tab: 'details';
}
interface IProps
  extends JSX.IntrinsicAttributes,
    IExternalProps,
    IAppContextProp,
    INavigateProp,
    ILocationProp,
    IParamsProp<{
      link: InternalId;
    }> {}

interface IState extends IApiComponentState {
  projectLink?: ClientProjectLinkEntity;
  editProjectLink?: ClientProjectLinkEntity;
  saving?: boolean;
  confirmAction?: 'deactivate' | 'reactivate';
}

class ProjectLinkDetailComponent extends ApiComponent<IProps, IState> {
  async load() {
    const projectLink = await this.api.syndication.projectLinks.item(this.props.params.link);

    return {
      projectLink,
    };
  }

  render() {
    const { tab } = this.props;
    const { projectLink, editProjectLink, saving, confirmAction } = this.state;

    if (!projectLink) {
      return this.renderRequest();
    }

    const renderBackendProperties = this.api.currentUser?.type === UserType.Internal;

    return (
      <>
        <LeftRightContainer
          left={
            <HeadlineWithBreadcrumbNavigation>
              <span className="text-truncate mw-100 d-inline-block">
                <ProjectLinkIcons projectLink={projectLink} />
                <span className="ms-3" title={projectLink.name}>
                  {projectLink.name}
                </span>{' '}
              </span>
            </HeadlineWithBreadcrumbNavigation>
          }
          right={
            <>
              {!projectLink.status || projectLink.status === ProjectLinkStatus.Active ? (
                <Button variant="danger" onClick={() => this.setState({ confirmAction: 'deactivate' })}>
                  Deactivate
                </Button>
              ) : (
                <Button variant="primary" onClick={() => this.setState({ confirmAction: 'reactivate' })}>
                  Reactivate
                </Button>
              )}
              {editProjectLink || projectLink.status !== ProjectLinkStatus.Active ? null : (
                <UserRoleGate allowed={[CustomerRole.Owner, CustomerRole.ManageSites]}>
                  {() => <EditButton onClick={() => this.setState({ editProjectLink: new ClientProjectLinkEntity(projectLink) })} />}
                </UserRoleGate>
              )}
            </>
          }
        />

        <Tabs
          id="project-link-tabs"
          activeKey={tab}
          onSelect={(activeTab) => this.props.navigate(`/projects/${this.props.appContext.project?.id}/links/${activeTab}`)}
        >
          <Tab eventKey="details" title={'Details'}>
            <ContentBox className="pb-3">
              <Row>
                <Col xs={6} className={'p-5'}>
                  {editProjectLink ? (
                    <Image style={{ marginLeft: '25%' }} src={ProjectLinkDetailImage} width={'50%'} />
                  ) : (
                    <>
                      <Row className="pt-2">
                        <Col className="bg-lighter rounded py-2">
                          <div>
                            <SiteApplicationIcon type={projectLink.sourceAppType} />{' '}
                            <ProjectLink entityId={projectLink.sourceProject.getId()} />
                          </div>
                          <div className="text-muted mt-3">
                            {projectLink.sourcePoolMachineNames.length > 1 ? (
                              <ul>
                                {projectLink.sourcePoolMachineNames.map((name, index) => (
                                  <li key={index}>{name}</li>
                                ))}
                              </ul>
                            ) : (
                              projectLink.sourcePoolMachineNames[0]
                            )}
                          </div>
                        </Col>
                        <Col xs={1}>
                          <div className="d-table w-100 h-100">
                            <div className="d-table-cell align-middle text-center">
                              <FontAwesomeIcon className="text-light" icon={faArrowRight} />
                            </div>
                          </div>
                        </Col>
                        <Col className="bg-lighter rounded py-2">
                          <div>
                            <SiteApplicationIcon type={projectLink.targetAppType} />{' '}
                            <ProjectLink entityId={projectLink.targetProject.getId()} />
                          </div>
                          <div className="text-muted mt-3">{projectLink.targetPoolMachineName}</div>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
                <Col className={'pt-4'}>
                  {editProjectLink ? (
                    <ProjectLinkForm
                      projectConnection={this.props.appContext.project?.id === projectLink.sourceProject.getId() ? 'source' : 'target'}
                      key={editProjectLink ? 'edit' : 'view'}
                      name={<></>}
                      onSave={async (update) => {
                        this.setState({
                          saving: true,
                        });
                        try {
                          const projectLink = await this.api.syndication.projectLinks.update(update as ClientProjectLinkEntity);
                          this.setState({
                            projectLink,
                            editProjectLink: undefined,
                          });
                        } finally {
                          this.setState({
                            saving: false,
                          });
                        }
                      }}
                      mode={editProjectLink ? 'edit-full' : 'view-full'}
                      entity={editProjectLink || projectLink}
                    >
                      {editProjectLink && (
                        <Right>
                          <Button disabled={saving} variant="light" onClick={() => this.setState({ editProjectLink: undefined })}>
                            Cancel
                          </Button>
                          <Button disabled={saving} type="submit" variant="primary">
                            Save
                          </Button>
                        </Right>
                      )}
                    </ProjectLinkForm>
                  ) : (
                    <>
                      <h4>Details</h4>
                      {renderBackendProperties && (
                        <IconSummaryItem icon={faBuilding}>
                          <CustomerLink entityId={projectLink.customer.getId()} />
                        </IconSummaryItem>
                      )}

                      <IconSummaryItem icon={faFingerprint}>{projectLink.uuid}</IconSummaryItem>
                    </>
                  )}
                </Col>
              </Row>
            </ContentBox>
          </Tab>
        </Tabs>

        <Modal show={!!confirmAction} scrollable onHide={() => this.setState({ confirmAction: undefined })}>
          <Modal.Header closeButton>
            <Modal.Title>{confirmAction === 'deactivate' ? 'Deactivate project link' : 'Reactivate project link'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {confirmAction === 'deactivate' ? (
              <>
                <strong>Are you sure you want to deactivate this project link?</strong>
                <br />
                <br />
                Content will no longer be shared, but you can reactivate the project link later.
              </>
            ) : (
              <>
                <strong>Are you sure you want to reactivate this project link?</strong>
                <br />
                <br />
                Content will be shared again with the target space when it's updated in the source space.
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Right>
              <Button
                disabled={saving}
                variant={confirmAction === 'deactivate' ? 'danger' : 'primary'}
                onClick={async () => {
                  this.setState({ saving: true });
                  try {
                    projectLink.status = confirmAction === 'deactivate' ? ProjectLinkStatus.Inactive : ProjectLinkStatus.Active;
                    const updatedProjectLink = await this.api.syndication.projectLinks.update(projectLink);
                    this.setState({ projectLink: updatedProjectLink, confirmAction: undefined });
                  } finally {
                    this.setState({ saving: false });
                  }
                }}
              >
                Continue
              </Button>
            </Right>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export const ProjectLinkDetail = withParams<IExternalProps, { link: string }>(
  withLocationAndNavigate(propertyChangeReload<any>(withAppContext<any>(ProjectLinkDetailComponent), (props) => props.projectLink))
);
