import React from 'react';

type Props = {
  pageHeader?: React.ReactNode;

  contentHeader?: React.ReactNode;
  children?: React.ReactNode;

  sidebar?: React.ReactNode;
};

export function ContentCloudDetailsPageLayout({ pageHeader, sidebar, contentHeader, children }: Props) {
  return (
    <div className="d-flex flex-column">
      <div className="flex-grow-0 flex-shrink-0">
        <div className="pt-3 ps-3 pe-3 pb-1">{pageHeader}</div>
      </div>

      <div className="flex-grow-1 flex-shrink-1 min-height-0 flex-basis-100">
        <div className="d-flex flex-row">
          <div className="flex-grow-1 flex-shrink-1 min-width-0" style={{ height: 'calc(100vh - 160px)' }}>
            <div className="d-flex flex-column h-100">
              {contentHeader && <div className="flex-grow-0 flex-shrink-0">{contentHeader}</div>}
              <div className="flex-grow-1 flex-shrink-1 min-height-0 flex-basis-100 overflow-auto px-4">{children}</div>
            </div>
          </div>
          {sidebar && (
            <div className="flex-grow-0 flex-shrink-0 px-3" style={{ width: '400px', height: 'calc(100vh - 160px)' }}>
              <div className="overflow-auto h-100">{sidebar}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
