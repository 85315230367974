import { ButtonLink, ExternalLink } from '@edgebox/react-components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { ProcessTask, ProcessTaskList, ProcessTaskStep, ProcessTaskStepDescription } from '../../Shared/UserTasks';
import { ClientProjectEntity, ClientRemoteServiceEntity, ClientSiteEntity } from '@edgebox/api-rest-client';
import ProjectFormNewCustomer from '../../../common/components/Syndication/Forms/ProjectFormNewCustomer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import ScreenshotFlowStep1 from '../../../images/screenshot-flow-step-1.png';
import { Button, Image } from 'react-bootstrap';
import { AppContext } from '../../../common/contexts/AppContext';
import { Embed } from '../../Shared/Embed';
import { ApiContext } from '../../../common';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { RegisterMultipleSites } from '../Syndication/RegisterMultipleSites';
import { useNavigate } from 'react-router';
import { TaskStatusList } from './OnboardingDefinitions';
import {
  DRUPAL_DEFAULT_TASK_STATUS,
  DRUPAL_ONBOARDING_TASK_NAMES,
  DRUPAL_TASK_CONFIGURE_MODULE,
  DRUPAL_TASK_CREATE_SPACE,
  DRUPAL_TASK_INSTALL_MODULE,
  DRUPAL_TASK_NEXT_STEPS,
  DRUPAL_TASK_PUSH_ALL_CONTENT,
  DRUPAL_TASK_PUSH_TEST_CONTENT,
  DrupalOnboardingTask,
} from './DrupalOnboarding';
import { PrimaryActionButton, PrimaryActionLinkButton } from '../../Shared/PrimaryActionButton';
import { Link } from 'react-router-dom';
import { ContentCloudPitch } from '../ContentCloud/ContentCloudPitch';

function HelpdeskScreenshot({ src, height, className }: { src: string; height: number; className?: string }) {
  return (
    <div className={`${className ?? ''} position-relative d-inline-block rounded shadow`}>
      <Image height={height} src={src} className="screenshot rounded" />
      <div style={{ background: 'rgba(0,0,0,.67)', position: 'absolute', left: 0, top: 0, bottom: 0, right: 0 }} className="rounded">
        <div style={{ margin: '-24px auto 0 auto', width: '48px', height: '48px', top: '50%', position: 'relative' }}>
          <FontAwesomeIcon icon={faQuestionCircle} className="text-light" size="3x" />
        </div>
      </div>
    </div>
  );
}

const PUBLIC_IPS_DOCS = 'https://support.content-sync.io/support/solutions/articles/80000811241-technical-requirements';
const TECHNICAL_REQUIREMENTS_DOCS = 'https://support.content-sync.io/support/solutions/articles/80000811241-technical-requirements';
const FLOW_SETUP_DOCS = 'https://support.content-sync.io/support/solutions/articles/80000811216-flows-configure-how-to-distribute-content';
const FLOW_DETAILS_DOCS =
  'https://support.content-sync.io/support/solutions/articles/80000811216-flows-configure-how-to-distribute-content';
const VIEWS_INTEGRATION_DOCS = 'https://support.content-sync.io/support/solutions/articles/80000811248-views-integration';
const CICD_INTEGRATION_DOCS = 'https://support.content-sync.io/support/solutions/articles/80000811244-deploy-configuration';

export function DrupalOnboardingInstructions({ forceNew }: { forceNew?: boolean }) {
  const app = useContext(AppContext);
  const api = useContext(ApiContext);

  const [project, setProject] = useState<ClientProjectEntity | null>(null);
  const [pushingFlowCount, setPushingFlowCount] = useState<number | null>(null);
  const [pullingFlowCount, setPullingFlowCount] = useState<number | null>(null);
  const [singlePushCount, setsinglePushCount] = useState<number | null>(null);
  const [singlePullCount, setsinglePullCount] = useState<number | null>(null);
  const [massPushCount, setMassPushCount] = useState<number | null>(null);

  const [initialTaskOpened, setInitialTaskOpened] = useState(false);
  const [activeTaskGroup, setActiveTaskGroup] = useState<null | string>(null);

  const [taskStatus, setTaskStatus] = useState<TaskStatusList<DrupalOnboardingTask>>({ ...DRUPAL_DEFAULT_TASK_STATUS });
  const setDone = useMemo(
    () => (task: DrupalOnboardingTask, done: boolean) => {
      if (taskStatus[task] === done) {
        return;
      }

      const taskStatusList = {
        ...taskStatus,
        [task]: done,
      };
      setTaskStatus(taskStatusList);

      if (initialTaskOpened) {
        return;
      }

      for (let i = DRUPAL_ONBOARDING_TASK_NAMES.length - 1; i >= 0; i--) {
        const task = DRUPAL_ONBOARDING_TASK_NAMES[i];
        if (taskStatusList[task] === 'loading') {
          return;
        }
        const previousTask = DRUPAL_ONBOARDING_TASK_NAMES[i === DRUPAL_ONBOARDING_TASK_NAMES.length - 1 ? i : i + 1];
        if (taskStatusList[task] === true) {
          setInitialTaskOpened(true);
          setActiveTaskGroup(previousTask);
          break;
        }
      }
    },
    [taskStatus, initialTaskOpened]
  );
  const navigate = useNavigate();
  useEffect(() => {
    setDone('create-space', !!project);

    if (project && !initialTaskOpened) {
      app.setProject?.(project);
      navigate(`/projects/${project.id}/onboarding`);
    } else if (app && !app.project && !initialTaskOpened) {
      setInitialTaskOpened(true);
      setActiveTaskGroup('create-space');
      setDone('install-module', false);
      setDone('configure-module', false);
      setDone('push-test-content', false);
      setDone('push-all-content', false);
    }
  }, [project, setDone, navigate, app, initialTaskOpened]);
  useEffect(() => {
    if (typeof app?.customerHasMultipleSites === 'boolean') {
      setDone('install-module', !!app?.customerHasMultipleSites);
      if (!app?.customerHasMultipleSites) {
        setDone('install-module', false);
        setDone('configure-module', false);
        setDone('push-test-content', false);
        setDone('push-all-content', false);
      }
    }
  }, [setDone, app?.customerHasMultipleSites]);
  useEffect(
    () =>
      pushingFlowCount !== null && pushingFlowCount !== null
        ? setDone('configure-module', !!pushingFlowCount && !!pushingFlowCount)
        : void 0,
    [pushingFlowCount, pullingFlowCount, setDone]
  );
  useEffect(
    () =>
      singlePushCount !== null && singlePullCount !== null ? setDone('push-test-content', !!singlePushCount && !!singlePullCount) : void 0,
    [singlePushCount, singlePullCount, setDone]
  );
  useEffect(() => (massPushCount !== null ? setDone('push-all-content', !!massPushCount) : void 0), [massPushCount, setDone]);

  const [site, setSite] = useState<ClientSiteEntity | null>(null);
  const [syncCore, setSyncCore] = useState<ClientRemoteServiceEntity | null>(null);
  useEffect(() => {
    if (!project || !app?.customerHasAnySites || !api) {
      return;
    }

    api.billing.sites
      .search(
        { itemsPerPage: 1, page: 0 },
        {
          projectId: project.id,
        },
        {
          includeInactive: false,
        }
      )
      .then((list) => {
        if (list.items.length) {
          setSite(list.items[0]);
        }
      });

    project.syncCore?.get().then((syncCore) => setSyncCore(syncCore ?? null));
  }, [api, project, app?.customerHasAnySites]);

  return (
    <>
      <ProcessTaskList
        activeTaskGroup={activeTaskGroup}
        onSelect={(taskGroup) => {
          setInitialTaskOpened(true);
          setActiveTaskGroup(taskGroup);
        }}
      >
        <ProcessTask id={DRUPAL_TASK_CREATE_SPACE} title={'Create a space'} hint="1 minute" status={taskStatus[DRUPAL_TASK_CREATE_SPACE]}>
          <ProcessTaskStep
            index={1}
            last
            done={!!project}
            title="Create a space"
            description={
              <>
                <ProcessTaskStepDescription>Start a trial if you haven't yet.</ProcessTaskStepDescription>
                <ProcessTaskStepDescription>
                  Select the type of service you'd like to use for your space. We recommend starting with Drupal.
                </ProcessTaskStepDescription>
              </>
            }
          >
            {project ? (
              <>
                <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> {project.name}{' '}
                <ButtonLink variant="link" to={`/projects/${project.id}`}>
                  view
                </ButtonLink>
              </>
            ) : (
              <ProjectFormNewCustomer
                forceNew={forceNew}
                onProject={(project) => {
                  setProject(project);
                }}
              />
            )}
          </ProcessTaskStep>
        </ProcessTask>

        <ProcessTask
          id={DRUPAL_TASK_INSTALL_MODULE}
          title="Install the module"
          hint="5 minutes"
          status={taskStatus[DRUPAL_TASK_INSTALL_MODULE]}
        >
          <ProcessTaskStep
            index={1}
            done={!!app?.customerHasAnySites}
            title="Install the module"
            description={
              <>
                <ProcessTaskStepDescription>
                  Add the <ExternalLink to={'https://www.drupal.org/project/cms_content_sync'}>Content Sync module</ExternalLink> to your
                  site.
                </ProcessTaskStepDescription>
                <ProcessTaskStepDescription>
                  On localhost sites, please enable the submodule <em>cms_content_sync_private_environment</em>. On protected sites, please
                  whitelist <ExternalLink to={PUBLIC_IPS_DOCS}>these IPs</ExternalLink>. See the documentation if you are{' '}
                  <ExternalLink to={TECHNICAL_REQUIREMENTS_DOCS}>having issues with the installation</ExternalLink>.
                </ProcessTaskStepDescription>
              </>
            }
          >
            <SyntaxHighlighter language={'bash'}>{`# Use composer to automatically resolve all dependencies
composer require drupal/cms_content_sync:~3.0

# Then enable the module
drush en cms_content_sync`}</SyntaxHighlighter>
          </ProcessTaskStep>

          <ProcessTaskStep
            index={2}
            done={!!app?.customerHasAnySites}
            title="Connect your publishing site"
            description={
              <>
                <ProcessTaskStepDescription>
                  Open the Content Sync <em>Registration</em> page on your site and click <em>Register</em> or use the drush command on the
                  right.
                </ProcessTaskStepDescription>
              </>
            }
          >
            {project && <RegisterMultipleSites embedded />}
          </ProcessTaskStep>

          <ProcessTaskStep
            index={3}
            last
            done={!!app?.customerHasMultipleSites}
            title="Connect a subscribing site"
            description={
              <>
                <ProcessTaskStepDescription>
                  Follow the steps above for a second site to push content from site A to site B.
                </ProcessTaskStepDescription>
              </>
            }
          ></ProcessTaskStep>
        </ProcessTask>

        <ProcessTask
          id={DRUPAL_TASK_CONFIGURE_MODULE}
          title="Configure content sharing"
          hint="3 minutes"
          status={taskStatus[DRUPAL_TASK_CONFIGURE_MODULE]}
        >
          <ProcessTaskStep
            index={1}
            done={!!pushingFlowCount}
            title="Configure your publishing site"
            description={
              <>
                <ProcessTaskStepDescription>Create a Flow to Push content.</ProcessTaskStepDescription>
                <ProcessTaskStepDescription>Flows define the rules for pushing or pulling content.</ProcessTaskStepDescription>
                <ProcessTaskStepDescription>
                  Each site can have any number of Flows and you can reuse Flows between sites later by using Drupal's config management.
                </ProcessTaskStepDescription>
              </>
            }
          >
            {site && syncCore && (
              <div className="m-3">
                <Embed
                  embed="box/onboarding/configuration/push"
                  site={site}
                  syncCore={syncCore}
                  scope="configuration"
                  onCount={(count) => setPushingFlowCount(count)}
                />
              </div>
            )}
          </ProcessTaskStep>

          <ProcessTaskStep
            index={2}
            last
            done={!!pullingFlowCount}
            title="Configure your subscribing site"
            description={
              <>
                <ProcessTaskStepDescription>Create another Flow to Pull content on the other site.</ProcessTaskStepDescription>
                <ProcessTaskStepDescription>
                  See the documentation for{' '}
                  <ExternalLink to={FLOW_SETUP_DOCS}>details on how to configure different use-cases</ExternalLink>.
                </ProcessTaskStepDescription>
              </>
            }
          >
            {site && syncCore && (
              <div className="m-3">
                <Embed
                  embed="box/onboarding/configuration/pull"
                  site={site}
                  syncCore={syncCore}
                  scope="configuration"
                  onCount={(count) => setPullingFlowCount(count)}
                />
              </div>
            )}
          </ProcessTaskStep>
        </ProcessTask>

        <ProcessTask
          id={DRUPAL_TASK_PUSH_TEST_CONTENT}
          title="Push your first content item"
          hint="2 minutes"
          status={taskStatus[DRUPAL_TASK_PUSH_TEST_CONTENT]}
        >
          <ProcessTaskStep
            index={1}
            done={!!singlePushCount}
            title="Create and push test content"
            description={
              <>
                <ProcessTaskStepDescription>
                  Create a new content item and use the <em>Save and Push</em> button to push the new content item to the Content Sync
                  service.
                </ProcessTaskStepDescription>
                <ProcessTaskStepDescription>
                  You can watch the update progress in Drupal or open the <em>Updates</em> tab here to see what's happening.
                </ProcessTaskStepDescription>
              </>
            }
          >
            {site && syncCore && (
              <div className="m-3">
                <Embed
                  embed="box/onboarding/syndication/push"
                  site={site}
                  syncCore={syncCore}
                  scope="configuration"
                  onCount={(count) => setsinglePushCount(count)}
                />
              </div>
            )}
          </ProcessTaskStep>

          <ProcessTaskStep
            index={2}
            last
            done={!!singlePullCount}
            title="Pull in content"
            description={
              <>
                <ProcessTaskStepDescription>
                  If you have configured your Flow to pull content <em>Immediately</em>, pushing the content will already lead to an update
                  on your second site.
                </ProcessTaskStepDescription>
                <ProcessTaskStepDescription>
                  If you have configured your Flow to pull content <em>Manually</em>, open the <em>Content Repository</em> tab in your{' '}
                  <em>Content</em> admin dashboard and use the <em>Pull</em> action.
                </ProcessTaskStepDescription>
              </>
            }
          >
            {site && syncCore && (
              <div className="m-3">
                <Embed
                  embed="box/onboarding/syndication/pull"
                  site={site}
                  syncCore={syncCore}
                  scope="configuration"
                  onCount={(count) => setsinglePullCount(count)}
                />
              </div>
            )}
          </ProcessTaskStep>
        </ProcessTask>

        <ProcessTask
          id={DRUPAL_TASK_PUSH_ALL_CONTENT}
          title="Push all existing content"
          hint="2 minutes"
          status={taskStatus[DRUPAL_TASK_PUSH_ALL_CONTENT]}
        >
          <ProcessTaskStep
            index={1}
            last
            done={!!massPushCount}
            title="Mass-push existing content"
            description={
              <>
                <ProcessTaskStepDescription>
                  Open the <em>Updates</em> tab in the site that's configured to <em>Push</em> content.
                </ProcessTaskStepDescription>
                <ProcessTaskStepDescription>
                  Navigate to the <em>Mass updates</em> section.
                </ProcessTaskStepDescription>
                <ProcessTaskStepDescription>
                  Use the <em>Start new</em> button to start a <em>Push all</em> update.{' '}
                  <strong>Depending on how much content you have on your sites, this can take a while.</strong>
                </ProcessTaskStepDescription>
              </>
            }
          >
            {site && syncCore && (
              <div className="m-3">
                <Embed
                  embed="box/onboarding/migration/push"
                  site={site}
                  syncCore={syncCore}
                  scope="configuration"
                  onCount={(count) => setMassPushCount(count)}
                />
              </div>
            )}
          </ProcessTaskStep>
        </ProcessTask>

        <ProcessTask id={DRUPAL_TASK_NEXT_STEPS} title="Next steps" hint="-" status={taskStatus[DRUPAL_TASK_NEXT_STEPS]}>
          <ProcessTaskStep index={1} done={false} title="Explore your Content Cloud" description={<></>}>
            {project && <ContentCloudPitch onClick={() => navigate(`/projects/${project.id}/links`)} reverse />}
          </ProcessTaskStep>

          <ProcessTaskStep
            index={2}
            done={false}
            title="Invite your team"
            description={
              <>
                <ProcessTaskStepDescription>
                  Head over to your <Link to={`/account/users`}>account user list</Link> to invite more people to this account.
                </ProcessTaskStepDescription>
              </>
            }
          >
            <div className="bg-lighter rounded p-3">
              <ButtonLink to="/account/users" variant="link">
                Add team member
              </ButtonLink>
            </div>
          </ProcessTaskStep>

          <ProcessTaskStep
            index={3}
            done={false}
            title="Leverage our views integration"
            description={
              <>
                <ProcessTaskStepDescription>
                  Read the docs to{' '}
                  <ExternalLink to={VIEWS_INTEGRATION_DOCS}>
                    learn how to show the sync state in the content dashboard of Drupal.
                  </ExternalLink>
                </ProcessTaskStepDescription>
              </>
            }
          >
            <SyntaxHighlighter language={'bash'}>{`# Enable the views module
drush en cms_content_sync_views

# Then follow the instructions outlined here to update your Content view:
open "${VIEWS_INTEGRATION_DOCS}"`}</SyntaxHighlighter>
          </ProcessTaskStep>

          <ProcessTaskStep
            index={4}
            done={false}
            title="Deployment process"
            description={
              <>
                <ProcessTaskStepDescription>
                  Read the docs to{' '}
                  <ExternalLink to={CICD_INTEGRATION_DOCS}>learn how to integrate Content Sync into your CI/CD workflows.</ExternalLink>
                </ProcessTaskStepDescription>
              </>
            }
          >
            <SyntaxHighlighter language={'bash'}>{`# Always run this after every deployment of your site
drush cse`}</SyntaxHighlighter>
          </ProcessTaskStep>

          <ProcessTaskStep
            index={5}
            last
            done={false}
            title="Flow configurations"
            description={
              <>
                <ProcessTaskStepDescription>
                  Read the docs to{' '}
                  <ExternalLink to={FLOW_DETAILS_DOCS}>learn more about possible Flow configurations for different use-cases.</ExternalLink>
                </ProcessTaskStepDescription>
              </>
            }
          >
            {/*<ExternalLink to={FLOW_DETAILS_DOCS}>
              <HelpdeskScreenshot height={300} src={ScreenshotFlowStep1} className="mb-3" />
            </ExternalLink>*/}
          </ProcessTaskStep>
        </ProcessTask>
      </ProcessTaskList>
    </>
  );
}
