import React from 'react';
import { PropsWithChildren } from 'react';

export function PrimaryActionHighlightBox({ children }: PropsWithChildren) {
  return (
    <div className="mx-auto border border-light rounded bg-soft-highlight shadow-sm p-5" style={{ width: '600px' }}>
      {children}
    </div>
  );
}
