import React, { useMemo } from 'react';
import { Permission } from '../shared-permissions';
import { ContentCloudComponentProps, withContentCloud } from '../WithContentCloud';
import { LocaleEntry, ContentCloudRestClient } from '../RestClient';
import { CONTENT_CLOUD_API_VERSION, convertListResponse, getContentCloudSatelliteUrl } from '../content-cloud-helper';
import { Badge, Col, Row } from 'react-bootstrap';
import { PagedList } from '../../../../common';
import { HeaderCol, LoadingBar } from '@edgebox/react-components';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';

const ENTRIES_PER_PAGE = 25;

interface Props extends ContentCloudComponentProps {}

function LocaleCollection({ contentCloudData }: Props) {
  const { environment, space, accessToken, contentCloud } = contentCloudData ?? {};

  const client = useMemo(
    () =>
      contentCloud &&
      accessToken &&
      space &&
      environment &&
      new ContentCloudRestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
      }),
    [accessToken, space, environment, contentCloud]
  );

  if (!client) {
    return <LoadingBar />;
  }

  return (
    <PagedList<LocaleEntry, {}>
      renderListHeader={() => (
        <Row>
          <HeaderCol xs={4}>Name</HeaderCol>
          <HeaderCol xs={2}>Code</HeaderCol>
          <HeaderCol xs={2}>Fallback</HeaderCol>
          <HeaderCol xs={2}>Updated</HeaderCol>
          <HeaderCol xs={2}>Status</HeaderCol>
        </Row>
      )}
      renderItem={(localeEntry) => (
        <Row className="m-0 px-0 py-3" key={localeEntry.sys.id}>
          <Col xs={4} className="ms-0 me-0 pt-2">
            <span className="fw-bold pt-1">{localeEntry.sys.name}</span>{' '}
            {localeEntry.id === space?.defaultLocale.id ? <Badge bg="primary">default</Badge> : null}
          </Col>
          <Col xs={2} className="ms-0 me-0 pt-2">
            {localeEntry.code}
          </Col>
          <Col xs={2} className="ms-0 me-0 pt-2">
            {localeEntry.fallbackCode ?? <span className="text-light">—</span>}
          </Col>
          <Col xs={2} className="ms-0 me-0 pt-2">
            <EntryRelativeDate date={localeEntry.sys.versionCreatedAt} />
          </Col>
          <Col xs={2} className="ms-0 me-0 pt-2">
            {localeEntry.sys.isPublished ? <Badge bg="success">active</Badge> : <Badge bg="danger">inactive</Badge>}
          </Col>
        </Row>
      )}
      request={async (page, filter) => {
        const response = await convertListResponse(
          client.localeCollection({
            limit: ENTRIES_PER_PAGE,
            skip: page * ENTRIES_PER_PAGE,
          }),
          ENTRIES_PER_PAGE
        );

        return response;
      }}
    />
  );
}

export default withContentCloud(LocaleCollection, [Permission.SPACE_READ]);
