import { InternalId } from '@edgebox/data-definition-kit';
import { Location } from '@remix-run/router';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { NavigateFunction } from 'react-router/dist/lib/hooks';

export interface INavigateProp {
  navigate: NavigateFunction;
}
export function withNavigate<Props>(Component: React.ComponentType<Props & INavigateProp>): React.ComponentType<Props> {
  return function WithNavigation(props: Props) {
    const navigate = useNavigate();

    return <Component {...props} navigate={navigate} />;
  };
}

export interface ILocationProp {
  location: Location;
}
export function withLocation<Props>(Component: React.ComponentType<Props & ILocationProp>): React.ComponentType<Props> {
  return function WithLocation(props: Props) {
    const location = useLocation();

    return <Component {...props} location={location} />;
  };
}
export function WithLocation({ children }: { children: (location?: Location) => React.ReactNode }) {
  const location = useLocation();
  return children(location);
}

export interface IParamsProp<Params extends Record<string, string | undefined>> {
  params: Params;
}
export function withParams<Props, Params extends Record<string, string | undefined>>(
  Component: React.ComponentType<Props & IParamsProp<Params>>
): React.ComponentType<Props> {
  return function WithIdParam(props: Props) {
    const params = useParams<Params>();

    return <Component {...props} params={params as Params} />;
  };
}
export function WithParams<NodeType extends React.ReactNode>({ children }: { children: (params?: any) => NodeType }) {
  const params = useParams();
  return children(params);
}

export interface IIdParamProp {
  id: InternalId;
}
export function withIdParam<Props>(Component: React.ComponentType<Props & IIdParamProp>): React.ComponentType<Props> {
  return function WithIdParam(props: Props) {
    const param = useParams<{ id: InternalId }>();

    return <Component {...props} id={param.id!} />;
  };
}

export interface IProjectParamProp {
  project: InternalId;
}
export function withProjectParam<Props>(Component: React.ComponentType<Props & IProjectParamProp>): React.ComponentType<Props> {
  return function WithProjectParam(props: Props) {
    const param = useParams<{ project: InternalId }>();

    return <Component {...props} project={param.project!} />;
  };
}

export interface ISiteParamProp {
  site: string;
}
export function withSiteParam<Props>(Component: React.ComponentType<Props & ISiteParamProp>): React.ComponentType<Props> {
  return function WithSiteParam(props: Props) {
    const param = useParams<{ site: string }>();

    return <Component {...props} site={param.site!} />;
  };
}

export function withLocationAndNavigate<Props>(
  Component: React.ComponentType<Props & ILocationProp & INavigateProp>
): React.ComponentType<Props> {
  const ComponentWithLocation: React.ComponentType<Props & INavigateProp> = withLocation(Component);
  const ComponentWithNavigate: React.ComponentType<Props> = withNavigate(ComponentWithLocation);
  return ComponentWithNavigate;
}
export function withParamsAndNavigate<Props, Params extends Record<string, string | undefined>>(
  Component: React.ComponentType<Props & IParamsProp<Params> & INavigateProp>
): React.ComponentType<Props> {
  const ComponentWithParams: React.ComponentType<Props & INavigateProp> = withParams<Props & INavigateProp, Params>(Component);
  const ComponentWithNavigate: React.ComponentType<Props> = withNavigate(ComponentWithParams);
  return ComponentWithNavigate;
}
export function withIdParamAndNavigate<Props>(
  Component: React.ComponentType<Props & IIdParamProp & INavigateProp>
): React.ComponentType<Props> {
  const ComponentWithIdParam: React.ComponentType<Props & INavigateProp> = withIdParam(Component);
  const ComponentWithNavigate: React.ComponentType<Props> = withNavigate(ComponentWithIdParam);
  return ComponentWithNavigate;
}
export function withIdParamAndLocationAndNavigate<Props>(
  Component: React.ComponentType<Props & IIdParamProp & ILocationProp & INavigateProp>
): React.ComponentType<Props> {
  const ComponentWithIdParam: React.ComponentType<Props & INavigateProp & ILocationProp> = withIdParam(Component);
  const ComponentWithLocation: React.ComponentType<Props & INavigateProp> = withLocation(ComponentWithIdParam);
  const ComponentWithNavigate: React.ComponentType<Props> = withNavigate(ComponentWithLocation);
  return ComponentWithNavigate;
}
