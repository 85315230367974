import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ContentBox } from '../../Shared/ContentBox';
import { PagedSiteList } from '../../Shared/PagedSiteList';
import { DrupalOnboardingInstructions } from '../Onboarding/DrupalOnboardingInstructions';
import { IAppContextProp, withAppContext } from '../../../common/contexts/AppContext';
import { propertyChangeReload } from '../../../common/helpers/propertyChangeReload';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { RegisterMultipleSites } from './RegisterMultipleSites';
import { UserRoleGate } from '../../../common/components/UserRoleGate';
import { CustomerRole } from '@edgebox/data-definitions';

interface IProps extends IAppContextProp, JSX.IntrinsicAttributes {}

class SiteListClass extends React.Component<IProps, { registerMultiple?: boolean }> {
  async load() {
    return {};
  }

  render() {
    const registerMultiple = !!this.state?.registerMultiple;

    return (
      <>
        <HeadlineWithBreadcrumbNavigation className="ms-2">Sites</HeadlineWithBreadcrumbNavigation>
        <PagedSiteList
          customerId={this.props.appContext.customer?.id}
          forContract={this.props.appContext.contract}
          forProject={this.props.appContext.project}
          emptyMessageWithNoFilters={
            <>
              <h2>Connect your first site</h2>

              <DrupalOnboardingInstructions />
            </>
          }
          message={
            <UserRoleGate allowed={[CustomerRole.Owner, CustomerRole.ManageSites]}>
              {() => (
                <Alert variant="primary" className="hero p-3 ps-4">
                  {registerMultiple ? (
                    <>
                      <h3>Register multiple sites at once</h3>
                      <RegisterMultipleSites embedded />
                    </>
                  ) : (
                    <>
                      If you have many sites,{' '}
                      <Button
                        variant="primary"
                        className="ps-2 pe-2 pt-1 pb-1 ms-1 me-1"
                        onClick={() => this.setState({ registerMultiple: true })}
                      >
                        Register multiple sites at once
                      </Button>
                    </>
                  )}
                </Alert>
              )}
            </UserRoleGate>
          }
        />
      </>
    );
  }
}

export const SiteList = withAppContext(propertyChangeReload(SiteListClass, (props) => props.appContext.projectKey));
