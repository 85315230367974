import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { PropsWithChildren } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';

export function ProcessTaskList({
  children,
  defaultActiveTaskGroup: defaultActiveKey,
  activeTaskGroup: activeKey,
  onSelect,
}: {
  defaultActiveTaskGroup?: string;
  activeTaskGroup?: string | null;
  onSelect?: (activeTaskGroup: string | null) => void;
} & PropsWithChildren) {
  return (
    <Accordion
      activeKey={activeKey}
      defaultActiveKey={defaultActiveKey}
      className="separate-items"
      onSelect={onSelect ? (key) => onSelect(key === undefined ? null : Array.isArray(key) ? key[0] : key) : undefined}
    >
      {children}
    </Accordion>
  );
}

export function ProcessTask({
  id,
  title,
  hint,
  children,
  status,
}: { id: string; title: React.ReactNode; status: boolean | 'loading'; hint?: React.ReactNode } & PropsWithChildren) {
  return (
    <Accordion.Item eventKey={id} className="shadow bg-white rounded mb-3">
      <Accordion.Header className="bg-white color-primary fw-bold rounded">
        <div className="d-flex justify-content-between w-100">
          <div className="flex-grow-1 flex-shrink-1">
            <h2>
              {
                <FontAwesomeIcon
                  icon={status === 'loading' ? faSpinner : status ? faCheckCircle : faCircle}
                  className={status === true ? 'text-success' : 'text-light'}
                  spin={status === 'loading'}
                />
              }{' '}
              {title}
            </h2>
          </div>
          <div className="flex-grow-0 flex-shrink-0 align-self-center pe-3">
            <span className="text-muted">{hint}</span>
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>{children}</Accordion.Body>
    </Accordion.Item>
  );
}

export function ProcessTaskStep({
  index,
  title,
  description,
  children,
  done,
  last,
}: { index: number; title: React.ReactNode; description?: React.ReactNode; done: boolean; last?: boolean } & PropsWithChildren) {
  return (
    <Row className={'m-0 align-items-stretch'}>
      <Col xs={6} className="p-0 d-flex">
        <div className="flex-grow-0 flex-shrink-0" style={{ width: '30px' }}>
          <div className="position-relative h-100">
            {!last && (
              <div
                style={{ bottom: '-20px', position: 'absolute', left: '10px', top: done ? '35px' : '35px', width: '1px' }}
                className={`${done ? 'bg-success' : 'bg-light'}`}
              ></div>
            )}
            {done ? (
              <div
                style={{
                  top: '16px',
                  left: '2px',
                  position: 'relative',
                  borderRadius: '20px',
                  display: 'inline-block',
                  height: '16px',
                  lineHeight: '16px',
                }}
                className="bg-white"
              >
                <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
              </div>
            ) : (
              <div
                style={{ borderRadius: '20px', width: '20px', height: '20px', lineHeight: '19px', position: 'relative', top: '16px' }}
                className="border border-light d-inline-block text-center text-muted bg-white"
              >
                {index}
              </div>
            )}
          </div>
        </div>
        <div className="flex-grow-1 flex-shrink-1 w-100 pb-5">
          <div className="p-3 rounded bg-lighter">
            <p className="fw-bold">{title}</p>
            {description}
          </div>
        </div>
      </Col>
      <Col xs={6} className="pb-5">
        {children}
      </Col>
    </Row>
  );
}

export function ProcessTaskStepDescription({ children }: PropsWithChildren) {
  return <p className="text-muted">{children}</p>;
}
