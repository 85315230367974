import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import React from 'react';

export function TaskStatusIcon({ done }: { done: boolean | 'loading' | null | number }) {
  if (done === 'loading' || done === null) {
    return <FontAwesomeIcon icon={faSpinner} spin className="text-light" />;
  }
  if (done) {
    return <FontAwesomeIcon icon={faCheckCircle} className="text-success" />;
  }
  return <FontAwesomeIcon icon={faCircle} className="text-light" />;
}
