import React, { useMemo, useState } from 'react';
import { Permission } from '../shared-permissions';
import { ContentCloudComponentProps, withContentCloud } from '../WithContentCloud';
import { AssetEntry, LocaleEntry, MimeTypeGroup, ContentCloudRestClient } from '../RestClient';
import { CONTENT_CLOUD_API_VERSION, convertListResponse, getContentCloudSatelliteUrl } from '../content-cloud-helper';
import { Col, Image, Row } from 'react-bootstrap';
import { getStyleColors, PagedList } from '../../../../common';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { AsyncPaginate } from 'react-select-async-paginate';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import { ContentCloudListPageLayout } from '../Layouts/ContentCloudListPageLayout';
import { faBarsSort } from '@fortawesome/pro-light-svg-icons/faBarsSort';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { HeaderCol, LoadingBar } from '@edgebox/react-components';
import { EntryPublishedBadge } from '../Components/EntryPublishedBadge';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';

const ENTRIES_PER_PAGE = 10;
const FILTER_ENTRIES_PER_PAGE = 10;

interface Props extends ContentCloudComponentProps {
  byStatus?: 'published' | 'draft' | null;
  byMimeTypeGroup?: string | null;
}

function AssetCollectionPage({ contentCloudData, byMimeTypeGroup, byStatus }: Props) {
  const { environment, space, accessToken, contentCloud } = contentCloudData ?? {};

  const client = useMemo(
    () =>
      contentCloud &&
      accessToken &&
      space &&
      environment &&
      new ContentCloudRestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
      }),
    [accessToken, space, environment, contentCloud]
  );
  const previewClient = useMemo(
    () =>
      contentCloud &&
      accessToken &&
      space &&
      environment &&
      new ContentCloudRestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'preview',
          version: CONTENT_CLOUD_API_VERSION,
        }),
      }),
    [accessToken, space, environment, contentCloud]
  );

  const [localeEntry, setLocaleEntry] = useState<LocaleEntry | null>(null);

  const { primary, danger } = getStyleColors();

  let mimeTypeGroupDisplayName = Object.entries(MimeTypeGroup).find(([, id]) => id === byMimeTypeGroup)?.[0];
  if (mimeTypeGroupDisplayName && ['Code', 'Markup', 'Plaintext', 'Other'].includes(mimeTypeGroupDisplayName)) {
    mimeTypeGroupDisplayName = `${mimeTypeGroupDisplayName} document`;
  }

  return (
    <ContentCloudPageLayout>
      <ContentCloudListPageLayout
        filterHeader={
          <>
            <div className={`px-2 pb-3 border-bottom`}>
              <Link to={`?`} className="text-decoration-none text-black">
                <div className={`${!byMimeTypeGroup && !byStatus ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faBarsSort} className="me-2" />
                  All media
                </div>
              </Link>
            </div>
            <div className={`px-2 py-3 border-bottom`}>
              <div className="text-muted small pe-2">Status</div>
              <Link to={`?status=published`} className="text-decoration-none text-black">
                <div className={`${byStatus === 'published' ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faGlobe} className="me-2" />
                  Published
                </div>
              </Link>
              <Link to={`?status=draft`} className="text-decoration-none text-black">
                <div className={`${byStatus === 'draft' ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faLock} className="me-2" />
                  Draft
                </div>
              </Link>
            </div>
          </>
        }
        filterBody={
          <>
            <div className={`px-2 py-3`}>
              <div className="text-muted small pe-2">File Type</div>
              {Object.entries(MimeTypeGroup)
                .sort(([a, b]) => a[0].localeCompare(b[0]))
                .map(([displayName, mimeTypeGroup]) => (
                  <Link key={mimeTypeGroup} to={`?mimeTypeGroup=${mimeTypeGroup}`} className="text-decoration-none text-black">
                    <div className={`${byMimeTypeGroup === mimeTypeGroup ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                      {displayName.replace(/([a-z])([A-Z])/g, '$1 $2').replace('Pdf', 'PDF')}
                    </div>
                  </Link>
                )) ?? '...'}
            </div>
          </>
        }
        contentHeader={
          <HeadlineWithBreadcrumbNavigation className="ms-2">
            {byStatus === 'draft' ? 'Draft' : byStatus === 'published' ? 'Published' : 'All'}{' '}
            {mimeTypeGroupDisplayName ? `${mimeTypeGroupDisplayName}s` : 'Media'}
          </HeadlineWithBreadcrumbNavigation>
        }
      >
        {client && previewClient ? (
          <PagedList<AssetEntry, { locale?: string; mimeTypeGroup?: string | null; status?: string | null }>
            searchable
            filter={{
              mimeTypeGroup: byMimeTypeGroup,
              status: byStatus,
            }}
            renderListHeader={() => (
              <Row>
                <HeaderCol xs={4}>Name</HeaderCol>
                <HeaderCol xs={2}>Dimensions</HeaderCol>
                <HeaderCol xs={2}>Type</HeaderCol>
                <HeaderCol xs={2}>Updated</HeaderCol>
                <HeaderCol xs={2}>Status</HeaderCol>
              </Row>
            )}
            renderFilters={(onChange) => (
              <>
                <Col xs={9}></Col>
                <Col xs={3}>
                  <AsyncPaginate
                    isClearable={true}
                    isMulti={false}
                    loadOptions={async (search, lastOptions, additional: any) => {
                      const page = additional ? additional.page + 1 : 0;
                      const response = await client.localeCollection({
                        skip: page * FILTER_ENTRIES_PER_PAGE,
                        limit: FILTER_ENTRIES_PER_PAGE,
                        'sys.name': search,
                      });

                      return {
                        options: response.items,
                        hasMore: response.skip < response.total,
                        additional: { page },
                      };
                    }}
                    placeholder="Change Locale..."
                    value={localeEntry}
                    getOptionValue={(option) => option.sys.id}
                    getOptionLabel={(option) => option.sys.name!}
                    onChange={(option) => {
                      setLocaleEntry(option);
                      onChange('locale', option?.code);
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary,
                        danger,
                      },
                    })}
                  />
                </Col>
              </>
            )}
            renderItem={(assetEntry) => (
              <Link
                to={`/content-cloud/assets/${assetEntry.sys.id}?locale=${localeEntry?.code ?? ''}`}
                className={'text-black ps-3 pe-1 pt-1 pb-1 d-block rounded bg-white mb-2 hover-shadow text-decoration-none'}
                key={assetEntry.sys.id}
              >
                <Row className="m-0 p-0">
                  <Col xs={4} className="ms-0 me-0 pt-2">
                    {assetEntry.fields.imageUrl ? (
                      <span className="d-inline-block me-2" style={{ width: '32px', height: '32px' }}>
                        <Image src={assetEntry.fields.imageUrl} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                      </span>
                    ) : (
                      <span className="d-inline-block me-2" style={{ width: '32px', height: '32px' }}>
                        &nbsp;
                      </span>
                    )}
                    <span className="fw-bold pt-1">{assetEntry.sys.name}</span>
                  </Col>
                  <Col xs={2} className="ms-0 me-0 pt-2">
                    {assetEntry.fields.details?.image
                      ? `${assetEntry.fields.details.image.width}px x ${assetEntry.fields.details.image.height}px`
                      : ''}
                  </Col>
                  <Col xs={2} className="ms-0 me-0 pt-2">
                    {assetEntry.fields.mimeType?.replace(
                      /^([a-z])([a-z]*)\/(.*)$/,
                      (_, firstLetter, group, specificType) => `${firstLetter.toUpperCase()}${group} / ${specificType}`
                    )}
                  </Col>
                  <Col xs={2} className="ms-0 me-0 pt-2">
                    <EntryRelativeDate date={assetEntry.sys.versionCreatedAt} />
                  </Col>
                  <Col xs={1} className="ms-0 me-0 pt-2">
                    <EntryPublishedBadge sys={assetEntry.sys} />
                  </Col>
                  <Col xs={1} className="text-end fs-4 text-light pt-1">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Col>
                </Row>
              </Link>
            )}
            request={async (page, filter) => {
              const response = await convertListResponse(
                (filter?.status === 'published' ? client : previewClient).assetCollection({
                  limit: ENTRIES_PER_PAGE,
                  skip: page * ENTRIES_PER_PAGE,
                  ...(filter
                    ? {
                        locale: filter.locale,
                      }
                    : {}),
                  order: '-sys.versionCreatedAt',
                  ...(filter?.status === 'draft'
                    ? {
                        'sys.localizationFirstPublishedAt[exists]': 'false',
                      }
                    : {}),
                  ...(filter?.mimeTypeGroup ? { 'fields.mimeTypeGroup': filter.mimeTypeGroup } : {}),
                }),
                ENTRIES_PER_PAGE
              );

              return response;
            }}
          />
        ) : (
          <LoadingBar />
        )}
      </ContentCloudListPageLayout>
    </ContentCloudPageLayout>
  );
}

export default withContentCloud(AssetCollectionPage, [
  Permission.CONTENT_READ,
  Permission.PREVIEW,
  Permission.ASSET_READ_FILE,
  Permission.SPACE_READ,
]);
