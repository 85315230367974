import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext, IAppContextProp, withAppContext } from '../../../../common/contexts/AppContext';
import { ILocationProp, INavigateProp, withLocationAndNavigate, withParams } from '../../../RouterHelper';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import { ContentCloudComponentProps, withContentCloud } from '../WithContentCloud';
import { CONTENT_CLOUD_API_VERSION } from '../content-cloud-helper';
import { Permission } from '../shared-permissions';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { ButtonLink, CopyToClipboardButton, LeftRightContainer, LoadingBar } from '@edgebox/react-components';
import { EntryPublishedBadge } from '../Components/EntryPublishedBadge';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';
import { ContentCloudDetailsPageLayout } from '../Layouts/ContentCloudDetailsPageLayout';
import { ContentEntryPropertyWrapper, StringPropertyValue } from './Components/ContentEntryProperties';
import { PublisherClient, SystemClient } from '../PublisherClient';
import { ApiContext } from '../../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';

interface Props extends JSX.IntrinsicAttributes, IAppContextProp, INavigateProp, ILocationProp, ContentCloudComponentProps {
  params: {
    project: string;
    id: string;
  };
}

function ClientEntryPageComponent({ contentCloudData, params, location, navigate }: Props) {
  const { contentCloud, environment, space } = contentCloudData ?? {};
  const api = useContext(ApiContext);

  const app = useContext(AppContext);
  const { project } = app;

  const [publisherClient, setPublisherClient] = useState<PublisherClient | null>(null);
  useEffect(() => {
    (async () => {
      if (!api || !contentCloud?.id || !project || !environment?.id) {
        return;
      }

      const accessToken = await api.syndication.remoteServices.getContentCloudRequestToken(
        contentCloud.id,
        project.id,
        [Permission.SPACE_READ, Permission.CLIENT_READ, Permission.CLIENT_SECRET],
        {
          environmentIds: [environment.id],
        }
      );

      setPublisherClient(
        new PublisherClient({
          baseUrl: contentCloud.baseUrl,
          version: CONTENT_CLOUD_API_VERSION,
          accessToken,
        })
      );
    })();
  }, [api, contentCloud?.id, contentCloud?.baseUrl, environment?.id, project]);

  const [sidebarTab, setSidebarTab] = useState('general');
  const [contentTab, _setContentTab] = useState('content');
  const setContentTab = useMemo(
    () => (tab: string) => {
      _setContentTab(tab);
      navigate(`/projects/${params.project}/clients/${params.id}/${tab}${location.search}`);
    },
    [navigate, _setContentTab, params, location]
  );

  const search = new URLSearchParams(location.search);
  const locale = search.get('locale');
  const [clientEntry, setClientEntry] = useState<SystemClient | null>(null);
  useEffect(() => {
    setClientEntry(null);

    if (!publisherClient) {
      return;
    }

    publisherClient.getClient(params.id).then((clientEntry) => setClientEntry(clientEntry));
  }, [publisherClient, params.id, locale]);

  const json = useMemo(() => JSON.stringify(clientEntry, null, 2), [clientEntry]);

  const [secret, setSecret] = useState<string | null>(null);
  const getSecret = useMemo(
    () => () => {
      if (!publisherClient) {
        return;
      }

      publisherClient.getClientSecret(params.id).then((secret) => setSecret(secret));
    },
    [publisherClient, params.id]
  );

  if (!publisherClient) {
    return <LoadingBar />;
  }

  if (!clientEntry) {
    return (
      <ContentCloudPageLayout>
        <LoadingBar />
      </ContentCloudPageLayout>
    );
  }

  return (
    <ContentCloudDetailsPageLayout
      pageHeader={
        <h1 className="ms-0">
          <ButtonLink variant="light" className="me-2" to={`/content-cloud/clients`}>
            <FontAwesomeIcon icon={faArrowLeft} size="2x" />
          </ButtonLink>
          {clientEntry.sys.name}
        </h1>
      }
      sidebar={
        <Tabs id={'sidebar-tabs'} className={'mb-3'} activeKey={sidebarTab} onSelect={(k: string | null) => setSidebarTab(k ?? 'general')}>
          <Tab eventKey={'general'} title={'General'}>
            <div>
              <div className="mb-4">
                <div className="small fw-bold border-bottom text-muted pb-1">Status</div>
                <LeftRightContainer
                  className="pt-2"
                  left={<span className="text-muted">Current</span>}
                  right={<EntryPublishedBadge sys={clientEntry.sys} />}
                />
              </div>

              <div className="mb-4">
                <div className="small fw-bold border-bottom text-muted pb-1">Publication</div>
                {!clientEntry.sys.isPublished && (
                  <LeftRightContainer
                    className="pt-2"
                    left={'Draft saved at'}
                    right={clientEntry.sys.versionCreatedAt ? <EntryRelativeDate date={clientEntry.sys.versionCreatedAt} /> : null}
                  />
                )}
                {clientEntry.sys.publishedAt && (
                  <LeftRightContainer
                    className="pt-2"
                    left={'Published at'}
                    right={<EntryRelativeDate date={clientEntry.sys.publishedAt} />}
                  />
                )}
                {clientEntry.sys.firstPublishedAt && clientEntry.sys.firstPublishedAt !== clientEntry.sys.versionCreatedAt && (
                  <LeftRightContainer
                    className="pt-2"
                    left={'First published at'}
                    right={<EntryRelativeDate date={clientEntry.sys.firstPublishedAt} />}
                  />
                )}
              </div>
            </div>
          </Tab>

          <Tab eventKey={'info'} title={'Info'}>
            <div>
              <div className="mb-4">
                <div className="small fw-bold border-bottom text-muted pb-1">Entry</div>
                <LeftRightContainer
                  className="pt-2"
                  left={<span>ID</span>}
                  right={
                    <span>
                      {clientEntry.sys.id}{' '}
                      <CopyToClipboardButton name="copy-entry-id" text={clientEntry.sys.id ?? ''} buttonText="" buttonOnly />
                    </span>
                  }
                />
              </div>

              <div className="mb-4">
                <div className="small fw-bold border-bottom text-muted pb-1">Version</div>
                <LeftRightContainer className="pt-2" left={<span>Entry</span>} right={<span>{clientEntry.sys.entryVersion}</span>} />
                <LeftRightContainer
                  className="pt-2"
                  left={<span>Localization</span>}
                  right={<span>{clientEntry.sys.localizationVersion}</span>}
                />
                <LeftRightContainer
                  className="pt-2"
                  left={<span>Version ID</span>}
                  right={
                    <CopyToClipboardButton
                      name="copy-version-id"
                      text={`${clientEntry.sys.id}/${clientEntry.sys.entryVersion}/${clientEntry.sys.localizationVersion}`}
                      buttonText=" Copy"
                      buttonOnly
                    />
                  }
                />
              </div>
            </div>
          </Tab>
        </Tabs>
      }
    >
      <Tabs id={'content-tabs'} className={'mb-3'} activeKey={contentTab} onSelect={(k: string | null) => setContentTab(k ?? 'content')}>
        <Tab eventKey={'content'} title={'Content'}>
          <ContentEntryPropertyWrapper label="Title">
            <StringPropertyValue>{clientEntry.name}</StringPropertyValue>
          </ContentEntryPropertyWrapper>

          {clientEntry.sys.description && (
            <ContentEntryPropertyWrapper label="Description">
              <StringPropertyValue>{clientEntry.sys.description}</StringPropertyValue>
            </ContentEntryPropertyWrapper>
          )}

          <ContentEntryPropertyWrapper label="Secret">
            {secret ? (
              <CopyToClipboardButton text={secret} name="client-secret" />
            ) : (
              <Button variant="light" onClick={getSecret}>
                Reveal
              </Button>
            )}
          </ContentEntryPropertyWrapper>
        </Tab>
        {/*<Tab eventKey={'links'} title={'Links'}>
          Links
        </Tab>*/}
        <Tab eventKey={'json'} title={'JSON Preview'}>
          <code className="border rounded border-light p-2 d-block mx-2 mb-2 position-relative">
            <div className="position-absolute" style={{ top: '10px', right: '10px' }}>
              <CopyToClipboardButton name="copy-json" buttonText=" Copy" text={json} buttonOnly />
            </div>
            <pre>{json}</pre>
          </code>
        </Tab>
      </Tabs>
    </ContentCloudDetailsPageLayout>
  );
}

export const ClientEntryPage = withParams<{ id: string }, { tab: string }>(
  withLocationAndNavigate<any>(
    withAppContext<any>(
      withContentCloud(ClientEntryPageComponent, [
        Permission.CONTENT_READ,
        Permission.SPACE_READ,
        Permission.PREVIEW,
        Permission.EXTERNAL_LINK_READ,
      ])
    )
  )
);
