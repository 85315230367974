export enum FeatureFlagUserData {
  ContentUserDataEnabled = 'contentUserData.enabled',
}

export enum FeatureFlagDelivery {
  DefaultCacheTimeInSeconds = 'delivery.defaultCacheTime',
  CDNAPIEnabled = 'delivery.enableCdnApi',
}

export enum FeatureFlagRequest {
  MaxExecutionTimeInSeconds = 'request.maxExecutionTime',
}

export type FeatureFlag = FeatureFlagUserData | FeatureFlagDelivery | FeatureFlagRequest;
