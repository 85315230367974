import React from 'react';
import { ContentTypeVisualization } from './Components/ContentTypeVisualization';
import { ILocationProp, INavigateProp, IParamsProp, withLocationAndNavigate, withParams } from '../../../RouterHelper';

interface Props
  extends INavigateProp,
    ILocationProp,
    IParamsProp<{
      project: string;
    }> {}

function ContentTypeVisualizationPageComponent({ navigate, params, location }: Props) {
  return (
    <ContentTypeVisualization
      fullscreen
      onOpenContentType={(contentType) =>
        navigate(`/projects/${params.project}/content-cloud/content-model/${contentType.id}/visualize${location.search}`)
      }
    />
  );
}

export const ContentTypeVisualizationPage = withParams<{}, { project: string }>(
  withLocationAndNavigate<
    IParamsProp<{
      project: string;
    }>
  >(ContentTypeVisualizationPageComponent)
);
