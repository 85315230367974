import {
  BooleanProperty,
  EnumProperty,
  ExternalServiceIdProperty,
  NameProperty,
  UnformattedTextProperty,
  UuidProperty,
} from '@edgebox/data-definition-kit';
import {
  AddSystemContentTypePropertyInput,
  SystemContentType,
  SystemContentTypePropertyType,
  SystemSearchIndexFormat,
} from '../../PublisherClient';
import {
  EditMode,
  EntityContext,
  EntityForm,
  EntityFormRow,
  entityFormWithValidation,
  EnumAsDropdown,
  IEntityFormProps,
  IEntityFormState,
} from '../../../../../common';
import React, { useEffect, useState } from 'react';
import { FormField, IconButton, LoadingBar, RenderFormFieldsCallback, Right } from '@edgebox/react-components';
import { getContentTypePropertyCustomId, getContentTypePropertyMachineName } from '../../content-cloud-helper';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { PrimaryActionButton } from '../../../../Shared/PrimaryActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { ContentTypeSelector } from '../../Components/ContentTypeSelector';
import { ContentTypeEntry } from '../../RestClient';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { ContentTypesProps, withContentTypes } from '../WithContentTypes';
import { ContentCloudComponentProps, withContentCloud } from '../../WithContentCloud';
import { Permission } from '../../shared-permissions';

const TYPE_NAMES: { [key in SystemContentTypePropertyType]: string } = {
  [SystemContentTypePropertyType.Boolean]: 'Boolean flag',
  [SystemContentTypePropertyType.Date]: 'Date / time',
  [SystemContentTypePropertyType.Int]: 'Integer',
  [SystemContentTypePropertyType.Float]: 'Float',
  [SystemContentTypePropertyType.JSON]: 'JSON',
  [SystemContentTypePropertyType.String]: 'Text',
  [SystemContentTypePropertyType.AnyEntry]: 'Link',
};

export class EditContentTypeProperty implements AddSystemContentTypePropertyInput {
  constructor(set?: AddSystemContentTypePropertyInput) {
    if (set) {
      Object.assign(this, set);
    }
  }

  @ExternalServiceIdProperty(false)
  id?: string | null;

  @UuidProperty(false)
  uuid?: string | null;

  @BooleanProperty(false)
  isPublished?: boolean | null;

  @BooleanProperty(false)
  isItemRequired?: boolean | null;

  @BooleanProperty(false)
  isLink?: boolean | null;

  @BooleanProperty(false)
  isParentLink?: boolean | null;

  @ExternalServiceIdProperty(false, { each: true })
  allowedTypes?: string[] | null;

  @UnformattedTextProperty(false, 4_096)
  description?: string | null;

  @EnumProperty(false, SystemSearchIndexFormat, 'SystemSearchIndexFormat')
  searchFormat?: SystemSearchIndexFormat | null;

  @NameProperty()
  name!: string;

  @NameProperty()
  machineName!: string;

  @NameProperty()
  customId!: string;

  @BooleanProperty(true)
  isArray!: boolean;

  @BooleanProperty(true)
  isRequired!: boolean;

  @BooleanProperty(true)
  isLocalized!: boolean;

  @BooleanProperty(true)
  isBig!: boolean;

  @EnumProperty(true, SystemContentTypePropertyType, 'SystemContentTypePropertyType')
  type!: SystemContentTypePropertyType;
}

interface EditContentTypePropertyFormProps extends IEntityFormProps<EditContentTypeProperty, EditContentTypeProperty> {
  contentType: SystemContentType;
  onSave: (draft: AddSystemContentTypePropertyInput) => Promise<void>;

  children: RenderFormFieldsCallback<EditContentTypeProperty>;
}
interface EditContentTypePropertyFormState extends IEntityFormState<EditContentTypeProperty> {}
const ContentTypePropertyFormWithValidation = entityFormWithValidation<EditContentTypeProperty, EditContentTypeProperty>(
  EditContentTypeProperty,
  EditContentTypeProperty
);
export class EditContentTypePropertyInnerForm extends EntityForm<
  EditContentTypeProperty,
  EditContentTypeProperty,
  EditContentTypePropertyFormProps,
  EditContentTypePropertyFormState
> {
  constructor(props: EditContentTypePropertyFormProps) {
    super(props, {
      edit: EditMode.Full,
    });
  }

  async load() {
    return {};
  }

  render() {
    return (
      <>
        <EntityContext.Provider value={this.entityContext}>
          <ContentTypePropertyFormWithValidation
            {...this.formProperties()}
            apiComponent={this}
            initialValues={this.props.entity}
            labels={'above'}
            onSubmit={async (draft) => {
              this.setState({
                extendProperties: [],
              });

              // Based on the name property.
              if (!draft.id) {
                const machineName = getContentTypePropertyMachineName(draft.name);
                const customId = getContentTypePropertyCustomId(this.props.contentType.customId, machineName);

                draft.machineName = machineName;
                draft.customId = customId;
              }

              // Based on the type property.
              if (
                ![SystemContentTypePropertyType.Int, SystemContentTypePropertyType.Float, SystemContentTypePropertyType.String].includes(
                  draft.type
                )
              ) {
                draft.isBig = false;
              }

              if (draft.type !== SystemContentTypePropertyType.AnyEntry) {
                draft.allowedTypes = null;
              }
              draft.isLink = draft.type === SystemContentTypePropertyType.AnyEntry;
              if (draft.isLink) {
                if (!draft.allowedTypes?.length) {
                  draft.allowedTypes = null;
                }
              } else {
                draft.isParentLink = false;
                draft.allowedTypes = null;
              }

              if (![SystemContentTypePropertyType.String].includes(draft.type)) {
                draft.searchFormat = null;
              }

              // Based on the isArray property
              if (draft.isArray) {
                if (typeof draft.isItemRequired !== 'boolean') {
                  draft.isItemRequired = true;
                }
                if (typeof draft.isParentLink !== 'boolean') {
                  draft.isParentLink = false;
                }
              } else {
                draft.isItemRequired = false;
              }

              await this.props.onSave(draft);
            }}
          >
            {this.props.children}
          </ContentTypePropertyFormWithValidation>
        </EntityContext.Provider>
      </>
    );
  }
}

export function EditContentTypePropertyFormComponent(
  props: Omit<EditContentTypePropertyFormProps, 'children'> &
    ContentTypesProps &
    ContentCloudComponentProps & { asRow?: boolean; disabled?: boolean; onCancel?: () => void }
) {
  const { asRow, disabled, onCancel, entity: initialValues, contentTypes } = props;

  const [selectedContentTypes, setSelectedContentTypes] = useState<ContentTypeEntry[] | null>(null);
  useEffect(() => {
    if (!initialValues.allowedTypes?.length) {
      setSelectedContentTypes(null);
      return;
    }

    setSelectedContentTypes(initialValues.allowedTypes.map((c) => contentTypes.find((type) => type.machineName === c)!));
  }, [initialValues.allowedTypes, contentTypes]);

  const isEditing = !!props.entity.id;

  return (
    <EditContentTypePropertyInnerForm {...props}>
      {({ setValue, values }) => {
        const editName = (
          <EntityFormRow<EditContentTypeProperty>
            name={'name'}
            label={'Name'}
            edit={() => (
              <FormField<EditContentTypeProperty>
                type={'text'}
                name={'name'}
                labelPlacement={'none'}
                noRow
                autoFocus={!isEditing}
                description={
                  isEditing ? `Machine name: ${values.machineName}` : `Machine name: ${getContentTypePropertyMachineName(values.name)}`
                }
              />
            )}
            view={values.name ? () => values.name : undefined}
            noRow={asRow}
          />
        );
        const editType = [
          SystemContentTypePropertyType.Int,
          SystemContentTypePropertyType.Float,
          SystemContentTypePropertyType.String,
        ].includes(values.type) ? (
          <Row>
            <Col xs={6} className="p-0">
              <EnumAsDropdown
                currentValue={values.type}
                enumValues={TYPE_NAMES}
                name={'type'}
                setValue={setValue}
                label={'Type'}
                noRow={asRow}
              />
            </Col>
            <Col xs={6} className="p-0">
              <div className={asRow ? 'ms-3 mt-2' : ''}>
                <EntityFormRow<EditContentTypeProperty>
                  name={'isBig'}
                  label={values.type === SystemContentTypePropertyType.String ? 'Long' : 'Big'}
                  edit={() => (
                    <FormField<EditContentTypeProperty>
                      type={'checkbox'}
                      name={'isBig'}
                      labelPlacement={'none'}
                      noRow
                      label={asRow ? (values.type === SystemContentTypePropertyType.String ? 'Long' : 'Big') : 'Yes'}
                      description={values.type === SystemContentTypePropertyType.String ? '>256 characters' : '64 bits'}
                    />
                  )}
                  view={values.name ? () => values.name : undefined}
                  noRow={asRow}
                />
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <div>
              <EnumAsDropdown
                currentValue={values.type}
                enumValues={TYPE_NAMES}
                name={'type'}
                setValue={setValue}
                label={'Type'}
                noRow={asRow}
              />
            </div>
            {values.type === SystemContentTypePropertyType.AnyEntry && (
              <>
                <EntityFormRow<EditContentTypeProperty>
                  name={'allowedTypes'}
                  label={'Restrict to'}
                  edit={() => (
                    <>
                      {selectedContentTypes?.map((type) => (
                        <div key={type.id}>
                          <FontAwesomeIcon icon={faCheck} className="text-success" /> {type.name}{' '}
                          <IconButton
                            icon={faTrashCan}
                            variant="danger"
                            className="p-0 border-0"
                            onClick={() => {
                              const setTypes = (selectedContentTypes ?? []).filter((existing) => existing.id !== type.id);
                              if (setTypes.length) {
                                setSelectedContentTypes(setTypes);
                                setValue(
                                  'allowedTypes',
                                  setTypes.map((c) => c.machineName)
                                );
                              } else {
                                setSelectedContentTypes(null);
                                setValue('allowedTypes', null);
                              }
                            }}
                          />
                        </div>
                      ))}
                      {selectedContentTypes?.length ? (
                        <div>
                          <FontAwesomeIcon icon={faTimes} className="text-danger" /> All others
                        </div>
                      ) : (
                        <div>
                          <FontAwesomeIcon icon={faCheck} className="text-success" /> Allow all
                        </div>
                      )}
                      <ContentTypeSelector
                        key={selectedContentTypes?.length ?? 'new'}
                        independentOnly
                        semantics="content"
                        exclude={selectedContentTypes?.map((c) => c.id)}
                        onSelected={(type) => {
                          const setTypes = [...(selectedContentTypes ?? []), type];
                          setSelectedContentTypes(setTypes);
                          setValue(
                            'allowedTypes',
                            setTypes.map((c) => c.machineName)
                          );
                        }}
                        emptyLabel={selectedContentTypes?.length ? 'Restrict types...' : 'Allow another type...'}
                      />
                    </>
                  )}
                />
              </>
            )}
          </>
        );
        const editMultiple = (
          <div className={asRow ? 'mt-2' : ''}>
            <EntityFormRow<EditContentTypeProperty>
              name={'isArray'}
              label={'Multiple'}
              edit={() => (
                <FormField<EditContentTypeProperty> type={'checkbox'} name={'isArray'} labelPlacement={'none'} noRow label="Yes" />
              )}
              view={values.name ? () => values.name : undefined}
              noRow={asRow}
            />
          </div>
        );

        const criticalChange =
          isEditing &&
          (values.type !== initialValues.type ||
            values.isBig !== initialValues.isBig ||
            values.isArray !== initialValues.isArray ||
            values.isLocalized !== initialValues.isLocalized);

        const saveButton = asRow ? (
          <Button disabled={disabled} variant={criticalChange ? 'danger' : 'primary'} type="submit">
            {disabled && <FontAwesomeIcon icon={faSpinner} spin />} Save
          </Button>
        ) : (
          <PrimaryActionButton disabled={disabled} type="submit">
            {disabled && <FontAwesomeIcon icon={faSpinner} spin />} Save
          </PrimaryActionButton>
        );
        const cancelButton = onCancel && (
          <Button disabled={disabled} variant="light" onClick={onCancel}>
            Cancel
          </Button>
        );
        const actions = asRow ? (
          <>
            {saveButton}
            {cancelButton}
          </>
        ) : (
          <>
            {cancelButton}
            {saveButton}
          </>
        );
        const warning = criticalChange && (
          <Alert variant="danger">
            <strong>
              Applying this change will render existing field data inaccessible. Users who have already stored this data will no longer be
              able to access it in this environment.
            </strong>
          </Alert>
        );

        if (props.asRow) {
          return (
            <>
              <Row>
                <Col xs={4}>{editName}</Col>
                <Col xs={4}>{editType}</Col>
                <Col xs={2}>{editMultiple}</Col>
                <Col xs={2}>{actions}</Col>
              </Row>
              {warning}
            </>
          );
        } else {
          return (
            <>
              {editName}

              {editType}

              {editMultiple}

              {warning}

              <Right className="mt-4">{actions}</Right>
            </>
          );
        }
      }}
    </EditContentTypePropertyInnerForm>
  );
}

export const EditContentTypePropertyForm = withContentCloud(withContentTypes(EditContentTypePropertyFormComponent), [
  Permission.SPACE_READ,
  Permission.CONTENT_TYPE_READ,
]);
