import { InternalId, IPagedListResponse } from '@edgebox/data-definition-kit';
import { ITextSearchFilter } from '../../../../common/components/PagedList';
import { EntitySelector, IEntitySelectorProps, IEntitySelectorState } from '../../../../common/components/Form/EntitySelector';
import React from 'react';
import { ClientRemoteServiceEntity } from '@edgebox/api-rest-client';
import { getReplicationGroupName } from '../content-cloud-helper';

export interface ReplicationGroup {
  id: string;
  name: string;
  satellites: string[];
  contentCloudId: InternalId;
}
interface Props extends IEntitySelectorProps<ReplicationGroup> {
  contentCloud: ClientRemoteServiceEntity;
  withoutReplication?: boolean;
}
interface State extends IEntitySelectorState<ReplicationGroup> {}

export class ReplicationGroupSelector extends EntitySelector<ReplicationGroup, Props, State> {
  protected _options: ReplicationGroup[] = [];
  protected get options() {
    if (!this._options || this._options.length === 0 || this._options[0].contentCloudId !== this.props.contentCloud.id) {
      this._options = Object.entries(this.props.contentCloud.contentCloudSettings!.replicationGroups).map(([id, satellites]) => ({
        id,
        name: getReplicationGroupName(satellites),
        satellites,
        contentCloudId: this.props.contentCloud.id,
      }));
    }
    return this._options;
  }

  async search(page: number, filter?: ITextSearchFilter | undefined): Promise<IPagedListResponse<ReplicationGroup>> {
    let items = this.options;

    if (this.props.withoutReplication) {
      items = items.filter((c) => c.satellites.length === 1);
    }

    if (filter?.search) {
      const search = filter.search;
      items = items.filter((c) => c.id.toLowerCase().includes(search.toLowerCase()));
    }

    return {
      items,
      itemsPerPage: items.length,
      numberOfPages: 1,
      page: 0,
      totalNumberOfItems: items.length,
    };
  }

  renderItemAsText(entity: ReplicationGroup): string {
    return entity.name;
  }

  renderItem(entity: ReplicationGroup): React.ReactNode {
    return entity.name;
  }

  renderEmpty(): React.ReactNode {
    return 'None';
  }

  renderCurrentValue(selected: ReplicationGroup): React.ReactNode {
    return selected.name;
  }
}
