import React, { useContext, useEffect, useState } from 'react';
import { Permission } from '../shared-permissions';
import { ContentCloudComponentProps, withContentCloud } from '../WithContentCloud';
import { CONTENT_CLOUD_API_VERSION } from '../content-cloud-helper';
import { Badge, Col, Row } from 'react-bootstrap';
import { ApiContext, PagedList } from '../../../../common';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { HeaderCol, LoadingBar } from '@edgebox/react-components';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';
import { PublisherClient, SystemClient } from '../PublisherClient';
import { useParams } from 'react-router';
import { ClientEntryPage } from './ClientEntryPage';
import { AppContext } from '../../../../common/contexts/AppContext';

const ENTRIES_PER_PAGE = 50;

interface Props extends ContentCloudComponentProps {}

function ClientCollection({ contentCloudData }: Props) {
  const { environment, contentCloud } = contentCloudData ?? {};
  const api = useContext(ApiContext);

  const app = useContext(AppContext);
  const { project } = app;

  const [publisherClient, setPublisherClient] = useState<PublisherClient | null>(null);
  useEffect(() => {
    (async () => {
      if (!api || !contentCloud?.id || !project || !environment?.id) {
        return;
      }

      const accessToken = await api.syndication.remoteServices.getContentCloudRequestToken(
        contentCloud.id,
        project.id,
        [Permission.SPACE_READ, Permission.CLIENT_READ],
        {
          environmentIds: [environment.id],
        }
      );

      setPublisherClient(
        new PublisherClient({
          baseUrl: contentCloud.baseUrl,
          version: CONTENT_CLOUD_API_VERSION,
          accessToken,
        })
      );
    })();
  }, [api, contentCloud?.id, contentCloud?.baseUrl, environment?.id, project]);

  const param = useParams<{ id: string }>();
  if (param.id) {
    return <ClientEntryPage id={param.id} />;
  }

  if (!publisherClient) {
    return <LoadingBar />;
  }

  return (
    <PagedList<SystemClient, {}>
      renderListHeader={() => (
        <Row>
          <HeaderCol xs={5}>
            <div className="ps-3">Name</div>
          </HeaderCol>
          <HeaderCol xs={3}>Identifier</HeaderCol>
          <HeaderCol xs={2}>Updated</HeaderCol>
          <HeaderCol xs={2}>Status</HeaderCol>
        </Row>
      )}
      renderItem={(clientEntry) => (
        <Link
          to={`/content-cloud/clients/${clientEntry.id}`}
          className={'text-black ps-3 pe-1 pt-1 pb-1 d-block rounded bg-white mb-2 hover-shadow text-decoration-none'}
          key={clientEntry.id}
        >
          <Row className="m-0 p-0">
            <Col xs={5} className="ms-0 me-0 pt-2">
              <span className="fw-bold pt-1">{clientEntry.name}</span>
            </Col>
            <Col xs={3} className="ms-0 me-0 pt-2">
              {clientEntry.id}
            </Col>
            <Col xs={2} className="ms-0 me-0 pt-2">
              {clientEntry.sys.versionCreatedAt ? <EntryRelativeDate date={clientEntry.sys.versionCreatedAt} /> : null}
            </Col>
            <Col xs={1} className="ms-0 me-0 pt-2">
              {clientEntry.sys.isPublished ? <Badge bg="success">active</Badge> : <Badge bg="danger">inactive</Badge>}
            </Col>
            <Col xs={1} className="text-end fs-4 text-light pt-1">
              <FontAwesomeIcon icon={faChevronRight} />
            </Col>
          </Row>
        </Link>
      )}
      request={async (page, filter) => {
        const response = await publisherClient.getClients();

        return {
          items: response,
          itemsPerPage: ENTRIES_PER_PAGE,
          numberOfPages: 1,
          page: 0,
          totalNumberOfItems: response.length,
        };
      }}
    />
  );
}

export default withContentCloud(ClientCollection, [Permission.SPACE_READ, Permission.CLIENT_READ]);
