import { Onboarding, TaskStatusList } from './OnboardingDefinitions';

export type DrupalOnboardingTask =
  | 'create-space'
  | 'install-module'
  | 'configure-module'
  | 'push-test-content'
  | 'push-all-content'
  | 'next-steps';

export const DRUPAL_TASK_CREATE_SPACE: DrupalOnboardingTask = 'create-space';
export const DRUPAL_TASK_INSTALL_MODULE: DrupalOnboardingTask = 'install-module';
export const DRUPAL_TASK_CONFIGURE_MODULE: DrupalOnboardingTask = 'configure-module';
export const DRUPAL_TASK_PUSH_TEST_CONTENT: DrupalOnboardingTask = 'push-test-content';
export const DRUPAL_TASK_PUSH_ALL_CONTENT: DrupalOnboardingTask = 'push-all-content';
export const DRUPAL_TASK_NEXT_STEPS: DrupalOnboardingTask = 'next-steps';

export const DRUPAL_ONBOARDING_TASK_NAMES: DrupalOnboardingTask[] = [
  'create-space',
  'install-module',
  'configure-module',
  'push-test-content',
  'push-all-content',
  'next-steps',
];

export const DRUPAL_DEFAULT_TASK_STATUS: TaskStatusList<DrupalOnboardingTask> = {
  'create-space': 'loading',
  'install-module': 'loading',
  'configure-module': 'loading',
  'push-test-content': 'loading',
  'push-all-content': 'loading',
  'next-steps': false,
};

export const DRUPAL_ONBOARDING: Onboarding<DrupalOnboardingTask> = {
  defaultStatuses: DRUPAL_DEFAULT_TASK_STATUS,
  taskNames: DRUPAL_ONBOARDING_TASK_NAMES,
};
