import { useEffect, useState } from 'react';
import { ContentTypeEntry, ContentCloudRestClient } from '../RestClient';
import { CONTENT_CLOUD_API_VERSION, getContentCloudSatelliteUrl, loadAllContentTypes } from '../content-cloud-helper';
import { ContentCloudComponentProps } from '../WithContentCloud';
import { LoadingBar } from '@edgebox/react-components';
import React from 'react';

export interface ContentTypesProps {
  contentTypes: ContentTypeEntry[];
}

export function withContentTypes<P extends ContentCloudComponentProps & ContentTypesProps>(
  Component: React.FunctionComponent<P>
): React.FunctionComponent<Omit<P, 'contentTypes'>> {
  return function ComponentWithContentTypes(otherProps: Omit<P, 'contentTypes'>) {
    const { contentCloudData } = otherProps;
    const [contentTypes, setContentTypes] = useState<ContentTypeEntry[] | null>(null);
    useEffect(() => {
      if (!contentCloudData?.accessToken) {
        return;
      }

      const satelliteClient = new ContentCloudRestClient({
        accessToken: contentCloudData.accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloudData.contentCloud, {
          api: 'rest',
          environmentSubdomain: `${contentCloudData.space.domainKey}-${contentCloudData.environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
      });

      loadAllContentTypes(satelliteClient).then((contentTypes) => setContentTypes(contentTypes));
    }, [contentCloudData?.accessToken, contentCloudData?.contentCloud, contentCloudData?.space, contentCloudData?.environment]);

    if (!contentTypes) {
      return <LoadingBar />;
    }

    return <Component {...({ ...otherProps, contentTypes } as P)} />;
  };
}
