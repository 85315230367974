import React, { useContext, useEffect, useState } from 'react';
import { Permission } from '../shared-permissions';
import { ContentCloudComponentProps, withContentCloud } from '../WithContentCloud';
import { CONTENT_CLOUD_API_VERSION } from '../content-cloud-helper';
import { Badge, Col, Row } from 'react-bootstrap';
import { ApiContext, PagedList } from '../../../../common';
import { HeaderCol, LoadingBar } from '@edgebox/react-components';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';
import { PublisherClient, SystemEnvironment } from '../PublisherClient';

const ENTRIES_PER_PAGE = 100;

interface Props extends ContentCloudComponentProps {}

function EnvironmentCollection({ contentCloudData }: Props) {
  const { space, contentCloud, accessToken } = contentCloudData ?? {};
  const api = useContext(ApiContext);

  const [publisherClient, setPublisherClient] = useState<PublisherClient | null>(null);
  useEffect(() => {
    (async () => {
      if (!api || !contentCloud?.baseUrl || !accessToken) {
        return;
      }

      setPublisherClient(
        new PublisherClient({
          baseUrl: contentCloud.baseUrl,
          version: CONTENT_CLOUD_API_VERSION,
          accessToken,
        })
      );
    })();
  }, [api, contentCloud?.baseUrl, accessToken]);

  if (!publisherClient || !space) {
    return <LoadingBar />;
  }

  return (
    <PagedList<SystemEnvironment, {}>
      renderListHeader={() => (
        <Row>
          <HeaderCol xs={6}>Name</HeaderCol>
          <HeaderCol xs={2}>Identifier</HeaderCol>
          <HeaderCol xs={2}>Updated</HeaderCol>
          <HeaderCol xs={2}>Status</HeaderCol>
        </Row>
      )}
      renderItem={(environmentEntry) => (
        <Row className="m-0 px-0 py-3" key={environmentEntry.id}>
          <Col xs={6} className="ms-0 me-0 pt-2">
            <span className="fw-bold pt-1">{environmentEntry.name}</span>{' '}
            {environmentEntry.id === space?.defaultEnvironment.id ? <Badge bg="primary">default</Badge> : null}
          </Col>
          <Col xs={2} className="ms-0 me-0 pt-2">
            {environmentEntry.customId}
          </Col>
          <Col xs={2} className="ms-0 me-0 pt-2">
            {environmentEntry.sys.versionCreatedAt ? <EntryRelativeDate date={environmentEntry.sys.versionCreatedAt} /> : null}
          </Col>
          <Col xs={2} className="ms-0 me-0 pt-2">
            {environmentEntry.sys.isPublished ? <Badge bg="success">active</Badge> : <Badge bg="danger">inactive</Badge>}
          </Col>
        </Row>
      )}
      request={async (page, filter) => {
        const environments = space.environments;

        return {
          items: environments,
          itemsPerPage: environments.length,
          numberOfPages: 1,
          page: 0,
          totalNumberOfItems: environments.length,
        };
      }}
    />
  );
}

export default withContentCloud(EnvironmentCollection, [Permission.SPACE_READ]);
