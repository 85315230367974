import { ClientContractEntity, ClientProjectDraft, ClientProjectEntity, ClientRemoteServiceEntity } from '@edgebox/api-rest-client';
import { ISerializedProjectProperties, UserType } from '@edgebox/data-definitions';
import { RemoteServiceHostingType } from '@edgebox/sync-core-data-definitions';
import { EditButton, FormField } from '@edgebox/react-components';
import {
  EnumTranslation,
  SiteApplicationType,
  SiteEnvironmentType,
  SyncCoreDataDefinitionsEnumTranslator,
} from '@edgebox/sync-core-data-definitions';
import React, { PropsWithChildren } from 'react';
import { EditMode, EntityContext } from '../../../contexts/EntityContext';
import { ContractLink } from '../../Billing/Links/ContractLink';
import { CustomerLink } from '../../Billing/Links/CustomerLink';
import { ContractSelector } from '../../Billing/Selectors/ContractSelector';
import { EntityForm, entityFormWithValidation, IEntityFormProps, IEntityFormState } from '../../Form/EntityForm';
import { EntityFormRow } from '../../Form/EntityFormRow';
import { EnumAsRadios } from '../../Form';
import { SyncCoreLink } from '../Links/SyncCoreLink';
import { Button, Col, Row } from 'react-bootstrap';
import { SyncCoreSelector } from '../Selectors/SyncCoreSelector';
import { getReplicationGroupName } from '../../../../components/Customer/ContentCloud/content-cloud-helper';
import {
  ReplicationGroup,
  ReplicationGroupSelector,
} from '../../../../components/Customer/ContentCloud/Components/ReplicationGroupSelector';

type OnSaveCallback = (entity: ClientProjectDraft) => Promise<void>;
type OnProfileCompleteCallback = (entity: ClientProjectEntity) => void;

interface IProps extends IEntityFormProps<ClientProjectDraft, ISerializedProjectProperties>, PropsWithChildren {
  mode: 'view-full' | 'edit-full' | 'create' | 'inline-edit';
  staticType?: boolean;
  staticAppType?: boolean;
  onSave: OnSaveCallback;
  onProfileComplete?: OnProfileCompleteCallback;
  fixedContract?: boolean;
}

const EDIT_MODES = {
  // Create project.
  create: EditMode.Full,
  // Viewing their own profile
  'inline-edit': EditMode.Inline,
  'view-full': EditMode.None,
  'edit-full': EditMode.Full,
};

interface IState extends IEntityFormState<ClientProjectDraft> {
  savedEntity?: ClientProjectEntity;
  selectedContract?: ClientContractEntity;
  selectedSyncCore?: ClientRemoteServiceEntity;
  selectedReplicationGroup?: ReplicationGroup;
  selectPublicSyncCore?: boolean;
  selectSyncCoreManually?: boolean;
}

const FormWithValidation = entityFormWithValidation<ISerializedProjectProperties, ClientProjectDraft>(
  ClientProjectDraft,
  ClientProjectEntity as any
);

// TODO: show throbber when saving / loading.

export class ProjectForm extends EntityForm<ClientProjectDraft, ISerializedProjectProperties, IProps, IState> {
  constructor(props: IProps) {
    super(props, {
      savedEntity: (props.entity as any).id ? (props.entity as ClientProjectEntity) : undefined,
      edit: EDIT_MODES[props.mode],
      extendProperties: [],
    });
  }

  async load(): Promise<Partial<IState>> {
    const selectedContract = await this.props.entity.contract.get();
    const selectedSyncCore = await this.props.entity.syncCore?.get();
    return {
      selectedContract,
      selectedSyncCore,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    // Person was saved => show "Upload profile picture" component.
    if (!(prevProps.entity as any).id && (this.props.entity as any).id) {
      this.createdNew();
    }
    if (this.props.mode !== prevProps.mode) {
      this.setState({
        edit: EDIT_MODES[this.props.mode],
      });
    }
  }

  async createdNew() {
    this.setState({
      savedEntity: this.props.entity as ClientProjectEntity,
    });
  }

  render(): React.ReactElement {
    let { staticType, staticAppType } = this.props;
    const { children, fixedContract } = this.props;

    const { selectedContract, selectedSyncCore, selectPublicSyncCore, selectSyncCoreManually, selectedReplicationGroup } = this.state;

    const renderBackendProperties = this.api.currentUser!.type === UserType.Internal;

    const allTypes = SyncCoreDataDefinitionsEnumTranslator.translateEnum('SiteEnvironmentType');
    const types: EnumTranslation<SiteEnvironmentType> = {
      [SiteEnvironmentType.Testing]: allTypes[SiteEnvironmentType.Testing],
      [SiteEnvironmentType.Production]: allTypes[SiteEnvironmentType.Production],
    } as EnumTranslation<SiteEnvironmentType>;

    const createNew = this.props.mode === 'create';
    if (!createNew) {
      staticType = true;
      staticAppType = true;
    }

    let allowedApplicationTypes = SyncCoreDataDefinitionsEnumTranslator.translateEnum('SiteApplicationType');
    const DEFAULT_ALLOWED_TYPES =
      this.api.currentUser?.type === UserType.Internal
        ? [SiteApplicationType.Drupal, SiteApplicationType.ContentCloud]
        : [SiteApplicationType.Drupal];
    for (const key of Object.keys(allowedApplicationTypes)) {
      if (!DEFAULT_ALLOWED_TYPES.includes(key as SiteApplicationType)) {
        delete allowedApplicationTypes[key];
      }
    }

    return (
      <EntityContext.Provider value={this.entityContext}>
        <FormWithValidation
          {...this.formProperties()}
          apiComponent={this}
          initialValues={this.props.entity}
          labels={'above'}
          onSubmit={async (entity) => {
            this.setState({
              extendProperties: [],
            });

            await this.props.onSave(entity);
          }}
        >
          {({ values, setValue, errors }) => (
            <>
              {this.props.name}

              {renderBackendProperties && (
                <EntityFormRow<ClientProjectDraft>
                  name={'customer'}
                  label={'Customer'}
                  view={() => <CustomerLink entityId={values.customer.id} />}
                />
              )}

              <EntityFormRow<ClientProjectDraft>
                name={'contract'}
                label={'Subscription'}
                view={() => <ContractLink entityId={values.contract.id} />}
                edit={
                  fixedContract
                    ? undefined
                    : () => (
                        <ContractSelector
                          name={'contract'}
                          value={selectedContract}
                          forCustomer={values.customer.id}
                          onSelected={(contract) => {
                            this.setState({
                              selectedContract: contract,
                            });

                            setValue('contract.id' as 'contract', contract.id);
                          }}
                        />
                      )
                }
              />

              <EntityFormRow<ClientProjectDraft>
                name={'name'}
                label={'Name'}
                edit={() => (
                  <FormField<ClientProjectDraft> type={'text'} name={'name'} labelPlacement={'none'} autoFocus={!values.name} noRow />
                )}
                view={values.name ? () => values.name : undefined}
              />

              <EnumAsRadios<ClientProjectDraft, SiteApplicationType>
                enumValues={allowedApplicationTypes as any}
                currentValue={values.appType || SiteApplicationType.Drupal}
                name={'appType'}
                label={'Application Type'}
                showStatic={staticAppType}
                help="If you would like access to new products, please contact us."
                setValue={(name, type) => {
                  setValue('appType', type);

                  setValue('syncCore', undefined);
                  this.setState({ selectedSyncCore: undefined });
                  setValue('contentCloudSettings', undefined);
                }}
              />

              <EnumAsRadios<ClientProjectDraft, SiteEnvironmentType>
                enumValues={types}
                currentValue={values.type}
                name={'type'}
                label={'Eventual use for this space'}
                showStatic={staticType}
                help={
                  <>
                    The type defines what sites should be grouped together. Sites of a different types are not allowed to be in the same
                    project. This way you can strictly separate testing sites from production sites. If you are using content staging, your
                    staging sites must be in a Production project as they must talk to your production site.{' '}
                    <span className={'text-warning'}>You can't change the type after the project has been created.</span>
                  </>
                }
                setValue={(name, type) => {
                  setValue('type', type);
                  this.setState({
                    selectedReplicationGroup: undefined,
                  });
                  setValue('contentCloudSettings', undefined);
                }}
              />

              {selectedContract && (
                <EntityFormRow<ClientProjectDraft>
                  name={'syncCore'}
                  label={values.appType === SiteApplicationType.ContentCloud ? 'Publisher region' : 'Region'}
                  view={
                    selectedSyncCore &&
                    (() =>
                      selectedSyncCore.type === RemoteServiceHostingType.OnPremise && !createNew ? (
                        <SyncCoreLink entityId={selectedSyncCore.id} />
                      ) : (
                        <>
                          {selectedSyncCore.name}
                          {createNew && (
                            <EditButton
                              onClick={() => {
                                setValue('syncCore.id' as 'syncCore', undefined);
                                this.setState({
                                  selectedSyncCore: undefined,
                                  selectedReplicationGroup: undefined,
                                  selectSyncCoreManually: true,
                                });
                                setValue('contentCloudSettings', undefined);
                              }}
                            />
                          )}
                        </>
                      ))
                  }
                  edit={
                    createNew
                      ? () => (
                          <Row className={'m-0 p-0'}>
                            <Col className={'m-0 p-0'}>
                              <SyncCoreSelector
                                appType={
                                  values.appType === SiteApplicationType.ContentCloud
                                    ? SiteApplicationType.ContentCloud
                                    : SiteApplicationType.SyncCore
                                }
                                forCustomer={selectPublicSyncCore ? undefined : values.customer.id}
                                forContract={selectPublicSyncCore ? undefined : selectedContract?.id}
                                key={`${values.appType === SiteApplicationType.ContentCloud ? SiteApplicationType.ContentCloud : SiteApplicationType.SyncCore}-${selectPublicSyncCore ? 'public' : 'default'}`}
                                autoSelect={!selectSyncCoreManually}
                                name={'syncCore.id'}
                                error={(errors?.syncCore as any)?.id}
                                value={selectedSyncCore}
                                onSelected={(selectedSyncCore) => {
                                  setValue('syncCore.id' as 'syncCore', selectedSyncCore.id);
                                  this.setState({
                                    selectedSyncCore,
                                    selectedReplicationGroup: undefined,
                                    selectSyncCoreManually: false,
                                  });
                                  setValue('contentCloudSettings', undefined);
                                }}
                                variant={createNew ? 'radios' : 'drop-down'}
                              />
                            </Col>
                            {(selectedSyncCore?.type === RemoteServiceHostingType.OnPremise || selectPublicSyncCore) && (
                              <Col xs={4}>
                                <Button
                                  className={'p-1'}
                                  variant={'link'}
                                  onClick={() => {
                                    this.setState({
                                      selectPublicSyncCore: !selectPublicSyncCore,
                                      selectedSyncCore: undefined,
                                      selectedReplicationGroup: undefined,
                                      selectSyncCoreManually: true,
                                    });
                                    setValue('contentCloudSettings', undefined);
                                  }}
                                >
                                  {selectPublicSyncCore ? 'Use Private' : 'Use Public'}
                                </Button>
                              </Col>
                            )}
                          </Row>
                        )
                      : undefined
                  }
                />
              )}

              {values.appType === SiteApplicationType.ContentCloud && selectedSyncCore && (
                <EntityFormRow<ClientProjectDraft>
                  name={'contentCloudSettings'}
                  label={'Settings'}
                  view={() =>
                    values.contentCloudSettings?.replicationGroup
                      ? getReplicationGroupName(values.contentCloudSettings.replicationGroup)
                      : null
                  }
                  edit={() => (
                    <>
                      <ReplicationGroupSelector
                        emptyLabel="Select replication..."
                        key={values.type + selectedSyncCore.id}
                        withoutReplication={values.type !== SiteEnvironmentType.Production}
                        contentCloud={selectedSyncCore}
                        value={selectedReplicationGroup}
                        onSelected={(option) => {
                          this.setState({ selectedReplicationGroup: option });

                          setValue('contentCloudSettings', { ...(values.contentCloudSettings ?? {}), replicationGroup: option.id });
                        }}
                      />

                      <EntityFormRow<ClientProjectDraft>
                        name={'contentCloudSettings.defaultLocaleCode' as 'contentCloudSettings'}
                        label={'Default locale code'}
                        edit={() => (
                          <FormField<ClientProjectDraft>
                            type={'text'}
                            name={'contentCloudSettings.defaultLocaleCode' as 'contentCloudSettings'}
                            labelPlacement={'none'}
                            noRow
                          />
                        )}
                        view={
                          values.contentCloudSettings?.defaultLocaleCode ? () => values.contentCloudSettings?.defaultLocaleCode : undefined
                        }
                      />

                      <EntityFormRow<ClientProjectDraft>
                        name={'contentCloudSettings.defaultEnvironmentCustomId' as 'contentCloudSettings'}
                        label={'Default environment ID'}
                        edit={() => (
                          <FormField<ClientProjectDraft>
                            type={'text'}
                            name={'contentCloudSettings.defaultEnvironmentCustomId' as 'contentCloudSettings'}
                            labelPlacement={'none'}
                            noRow
                          />
                        )}
                        view={
                          values.contentCloudSettings?.defaultEnvironmentCustomId
                            ? () => values.contentCloudSettings?.defaultEnvironmentCustomId
                            : undefined
                        }
                      />
                    </>
                  )}
                />
              )}

              {children}
            </>
          )}
        </FormWithValidation>
      </EntityContext.Provider>
    );
  }
}
