import React, { useEffect, useMemo, useState } from 'react';
import { Permission } from '../shared-permissions';
import { ContentCloudComponentProps, withContentCloud } from '../WithContentCloud';
import { ContentTypeEntry, ContentCloudRestClient } from '../RestClient';
import {
  CONTENT_CLOUD_API_VERSION,
  getContentCloudSatelliteUrl,
  isContentUserDataType,
  loadAllContentTypes,
} from '../content-cloud-helper';
import { Alert, Col, Row } from 'react-bootstrap';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { HeaderCol, LoadingBar, Right } from '@edgebox/react-components';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import { SanitizeHtml } from '../SanitizeHtml';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';
import { ILocationProp, INavigateProp, IParamsProp, withLocationAndNavigate, withParams } from '../../../RouterHelper';
import { PrimaryActionButton } from '../../../Shared/PrimaryActionButton';
import { AddContentTypeForm } from './Components/AddContentTypeForm';
import { PublisherClient } from '../PublisherClient';
import { PrimaryActionHighlightBox } from '../../../Shared/PrimaryActionHighlightBox';
import { FeatureFlagUserData } from '../shared-features';

interface Props
  extends ContentCloudComponentProps,
    INavigateProp,
    ILocationProp,
    IParamsProp<{
      project: string;
    }> {}

function ContentUserDataTypeCollectionPage({ contentCloudData, navigate, params }: Props) {
  const { environment, space, accessToken, contentCloud, featureConfig } = contentCloudData ?? {};

  const publisherClient = useMemo(
    () =>
      contentCloud &&
      accessToken &&
      new PublisherClient({
        accessToken,
        baseUrl: contentCloud.baseUrl,
        version: CONTENT_CLOUD_API_VERSION,
      }),
    [accessToken, contentCloud]
  );

  const environmentClient = useMemo(
    () =>
      contentCloud &&
      accessToken &&
      space &&
      environment &&
      new ContentCloudRestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
      }),
    [accessToken, space, environment, contentCloud]
  );

  const [contentTypes, setContentTypes] = useState<ContentTypeEntry[] | null>(null);
  useEffect(
    () =>
      (environmentClient
        ? !!loadAllContentTypes(environmentClient).then((contentTypes) =>
            setContentTypes(contentTypes.sort((a, b) => a.name.localeCompare(b.name)).filter((c) => isContentUserDataType(c)))
          )
        : true) && void 0,
    [environmentClient]
  );

  const [createModel, setCreateModel] = useState(false);

  if (featureConfig && !featureConfig[FeatureFlagUserData.ContentUserDataEnabled]) {
    return (
      <ContentCloudPageLayout>
        <div className="p-3">
          <HeadlineWithBreadcrumbNavigation className="ms-2">User data stores</HeadlineWithBreadcrumbNavigation>
          <Alert variant="light" className="mt-5 mb-3">
            User data stores are not enabled for this space. Please contact us if you're interested in using this feature.
          </Alert>
        </div>
      </ContentCloudPageLayout>
    );
  }

  return (
    <ContentCloudPageLayout>
      <div className="p-3">
        <HeadlineWithBreadcrumbNavigation className="ms-2">User data stores</HeadlineWithBreadcrumbNavigation>
        {contentTypes ? (
          contentTypes.length ? (
            <>
              <Row>
                <HeaderCol xs={6}>Name</HeaderCol>
                <HeaderCol xs={2}>Fields</HeaderCol>
                <HeaderCol xs={3}>Updated</HeaderCol>
                <HeaderCol xs={1}>Actions</HeaderCol>
              </Row>
              {contentTypes.map((contentTypeEntry) => (
                <Link
                  to={`/projects/${params.project}/content-cloud/content-user-data/${contentTypeEntry.sys.id}`}
                  className={'text-black ps-3 pe-1 pt-1 pb-1 d-block rounded bg-white mb-2 hover-shadow text-decoration-none'}
                  key={contentTypeEntry.sys.id}
                >
                  <Row className="m-0 p-0">
                    <Col xs={6} className="ms-0 me-0 pt-2">
                      {contentTypeEntry.sys.name}
                      <div className="small text-muted">
                        {contentTypeEntry.sys.description && <SanitizeHtml>{contentTypeEntry.sys.description}</SanitizeHtml>}
                      </div>
                    </Col>
                    <Col xs={2} className="ms-0 me-0 pt-2">
                      {contentTypeEntry.properties.length}
                    </Col>
                    <Col xs={3} className="ms-0 me-0 pt-2">
                      <EntryRelativeDate date={contentTypeEntry.sys.versionCreatedAt} />
                    </Col>
                    <Col xs={1} className="text-end fs-4 text-light pt-1">
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Col>
                  </Row>
                </Link>
              ))}
            </>
          ) : (
            <div className="my-5">
              <PrimaryActionHighlightBox>
                {createModel ? (
                  <>
                    {space && environment && publisherClient && (
                      <AddContentTypeForm
                        onSave={async (draft) => {
                          draft.localeCode = space.defaultLocale.code;
                          draft.environmentCustomIds = [environment.customId];
                          console.log('saving new type', draft);
                          const type = await publisherClient.mutateContentType(draft);
                          console.log('saved new type', type);
                          if (type) {
                            navigate(`/projects/${params.project}/content-cloud/content-user-data/${type.id}`);
                          }
                        }}
                        spaceId={space.id}
                      >
                        <Right className="p-3 pb-0 mt-2">
                          <PrimaryActionButton type="submit">Create</PrimaryActionButton>
                        </Right>
                      </AddContentTypeForm>
                    )}
                  </>
                ) : (
                  <div>
                    <h2 className="m-0">Elevate your user journey</h2>
                    <div>
                      Create a custom user data store to save bookmarks, reading histories, notes and other data that help you customize the
                      user journey across your apps and services.
                    </div>
                    <PrimaryActionButton className="mt-4" onClick={() => setCreateModel(true)}>
                      Create model
                    </PrimaryActionButton>
                  </div>
                )}
              </PrimaryActionHighlightBox>
            </div>
          )
        ) : (
          <LoadingBar />
        )}
      </div>
    </ContentCloudPageLayout>
  );
}

export default withLocationAndNavigate(
  withParams<INavigateProp & ILocationProp, { project: string }>(
    withContentCloud(ContentUserDataTypeCollectionPage, [Permission.CONTENT_TYPE_READ, Permission.CONTENT_TYPE_WRITE])
  )
);
