import React, { useEffect, useMemo, useState } from 'react';
import { IAppContextProp, withAppContext } from '../../../../common/contexts/AppContext';
import { ILocationProp, INavigateProp, withLocationAndNavigate, withParams } from '../../../RouterHelper';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import { TagEntry, ExternalEntryLinkType, ContentCloudRestClient } from '../RestClient';
import { ContentCloudComponentProps, withContentCloud } from '../WithContentCloud';
import { CONTENT_CLOUD_API_VERSION, getContentCloudSatelliteUrl } from '../content-cloud-helper';
import { Permission } from '../shared-permissions';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import { CopyToClipboardButton, LeftRightContainer, LoadingBar } from '@edgebox/react-components';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { EntryPublishedBadge } from '../Components/EntryPublishedBadge';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';
import { Link } from 'react-router-dom';
import { ContentCloudDetailsPageLayout } from '../Layouts/ContentCloudDetailsPageLayout';
import { ContentEntryPropertyWrapper, StringPropertyValue } from './Components/ContentEntryProperties';

interface Props extends JSX.IntrinsicAttributes, IAppContextProp, INavigateProp, ILocationProp, ContentCloudComponentProps {
  params: {
    project: string;
    tag: string;
    tab: string;
  };
}

function TagEntryPageComponent({ contentCloudData, params, location, navigate }: Props) {
  const { accessToken, contentCloud, environment, space } = contentCloudData ?? {};

  const client = useMemo(
    () =>
      contentCloud &&
      accessToken &&
      space &&
      environment &&
      new ContentCloudRestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
      }),
    [accessToken, space, environment, contentCloud]
  );

  const [sidebarTab, setSidebarTab] = useState('general');
  const [contentTab, _setContentTab] = useState(params.tab);
  const setContentTab = useMemo(
    () => (tab: string) => {
      _setContentTab(tab);
      navigate(`/projects/${params.project}/content-cloud/tags/${params.tag}/${tab}${location.search}`);
    },
    [navigate, _setContentTab, params, location]
  );

  const search = new URLSearchParams(location.search);
  const locale = search.get('locale');
  const [tagEntry, setTagEntry] = useState<TagEntry | null>(null);
  useEffect(() => {
    setTagEntry(null);

    client && client.tagEntry({ id: params.tag, ...(locale ? { locale } : {}) }).then((tagEntry) => setTagEntry(tagEntry));
  }, [client, params.tag, locale]);

  const json = useMemo(() => JSON.stringify(tagEntry, null, 2), [tagEntry]);

  const source = tagEntry?.externalLinks?.find((c) => c.linkType === ExternalEntryLinkType.Source);

  return (
    <ContentCloudPageLayout>
      <ContentCloudDetailsPageLayout
        pageHeader={
          <HeadlineWithBreadcrumbNavigation className="ms-2" names={{ [params.tag]: tagEntry?.name ?? '...' }}>
            {tagEntry?.name ?? '...'}
          </HeadlineWithBreadcrumbNavigation>
        }
        sidebar={
          <Tabs
            id={'sidebar-tabs'}
            className={'mb-3'}
            activeKey={sidebarTab}
            onSelect={(k: string | null) => setSidebarTab(k ?? 'general')}
          >
            <Tab eventKey={'general'} title={'General'}>
              <div>
                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Status</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={<span className="text-muted">Current</span>}
                    right={tagEntry && <EntryPublishedBadge sys={tagEntry.sys} />}
                  />
                </div>

                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Publication</div>
                  {tagEntry && !tagEntry.sys.isPublished && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'Draft saved at'}
                      right={<EntryRelativeDate date={tagEntry.sys.versionCreatedAt} />}
                    />
                  )}
                  {tagEntry && tagEntry.sys.publishedAt && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'Published at'}
                      right={<EntryRelativeDate date={tagEntry.sys.publishedAt} />}
                    />
                  )}
                  {tagEntry && tagEntry.sys.firstPublishedAt && tagEntry.sys.firstPublishedAt !== tagEntry.sys.versionCreatedAt && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'First published at'}
                      right={<EntryRelativeDate date={tagEntry.sys.firstPublishedAt} />}
                    />
                  )}
                </div>
              </div>
            </Tab>

            <Tab eventKey={'source'} title={'Source'}>
              <div>
                {source ? (
                  <div className="mb-4">
                    <div className="small fw-bold border-bottom text-muted pb-1">Canonical URL</div>
                    {source.canonicalUrl ? (
                      <Link to={source.canonicalUrl} target="_blank" className="text-truncate d-block">
                        {source.domain}
                      </Link>
                    ) : (
                      <em>Not provided.</em>
                    )}
                  </div>
                ) : (
                  <Alert variant="light">This entry doesn't include any source information.</Alert>
                )}
              </div>
            </Tab>
            {/*<Tab eventKey={'links'} title={'Links'}>
                    <div>Links</div>
                  </Tab>*/}
            <Tab eventKey={'info'} title={'Info'}>
              <div>
                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Entry</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>ID</span>}
                    right={
                      <span>
                        {tagEntry?.sys.id}{' '}
                        <CopyToClipboardButton name="copy-entry-id" text={tagEntry?.sys.id ?? '...'} buttonText="" buttonOnly />
                      </span>
                    }
                  />
                </div>

                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Version</div>
                  <LeftRightContainer className="pt-2" left={<span>Entry</span>} right={<span>{tagEntry?.sys.entryVersion}</span>} />
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Localization</span>}
                    right={<span>{tagEntry?.sys.localizationVersion}</span>}
                  />
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Version ID</span>}
                    right={
                      <CopyToClipboardButton name="copy-version-id" text={tagEntry?.sys.versionId ?? '...'} buttonText=" Copy" buttonOnly />
                    }
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        }
      >
        <Tabs id={'content-tabs'} className={'mb-3'} activeKey={contentTab} onSelect={(k: string | null) => setContentTab(k ?? 'content')}>
          <Tab eventKey={'content'} title={'Content'}>
            <ContentEntryPropertyWrapper label="Title">
              {tagEntry ? <StringPropertyValue>{tagEntry.name}</StringPropertyValue> : <LoadingBar />}
            </ContentEntryPropertyWrapper>

            {tagEntry && tagEntry.sys.description && (
              <ContentEntryPropertyWrapper label="Description">
                <StringPropertyValue>{tagEntry.sys.description}</StringPropertyValue>
              </ContentEntryPropertyWrapper>
            )}
          </Tab>
          {/*<Tab eventKey={'links'} title={'Links'}>
            Links
          </Tab>*/}
          <Tab eventKey={'json'} title={'JSON Preview'}>
            <code className="border rounded border-light p-2 d-block mx-2 mb-2 position-relative">
              <div className="position-absolute" style={{ top: '10px', right: '10px' }}>
                <CopyToClipboardButton name="copy-json" buttonText=" Copy" text={json} buttonOnly />
              </div>
              <pre>{json}</pre>
            </code>
          </Tab>
        </Tabs>
      </ContentCloudDetailsPageLayout>
    </ContentCloudPageLayout>
  );
}

export const TagEntryPage = withParams<{}, { site: string; tab: string }>(
  withLocationAndNavigate<any>(
    withAppContext<any>(
      withContentCloud(TagEntryPageComponent, [
        Permission.CONTENT_READ,
        Permission.SPACE_READ,
        Permission.PREVIEW,
        Permission.EXTERNAL_LINK_READ,
      ])
    )
  )
);
