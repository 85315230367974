import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IAppContextProp, withAppContext } from '../../../../common/contexts/AppContext';
import { ILocationProp, INavigateProp, withLocationAndNavigate, withParams } from '../../../RouterHelper';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import { ContentTypeEntry, ContentCloudRestClient } from '../RestClient';
import { ContentCloudComponentProps, updateAccessToken, withContentCloud } from '../WithContentCloud';
import { CONTENT_CLOUD_API_VERSION, getContentCloudSatelliteUrl, loadAllContentTypes } from '../content-cloud-helper';
import { Permission } from '../shared-permissions';
import { Alert, Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { CopyToClipboardButton, HeaderCol, LeftRightContainer } from '@edgebox/react-components';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { ContentCloudDetailsPageLayout } from '../Layouts/ContentCloudDetailsPageLayout';
import { EntryPublishedBadge } from '../Components/EntryPublishedBadge';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';
import { SanitizeHtml } from '../SanitizeHtml';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBracketsSquare } from '@fortawesome/pro-light-svg-icons/faBracketsSquare';
import { ContentTypePropertyType, getContentTypePropertyDisplay } from './Components/ContentTypePropertyType';
import { ContentTypeVisualization } from './Components/ContentTypeVisualization';
import { PrimaryActionHighlightBox } from '../../../Shared/PrimaryActionHighlightBox';
import { PrimaryActionButton } from '../../../Shared/PrimaryActionButton';
import {
  AddSystemContentTypePropertyInput,
  getSystemContentTypePropertyInput,
  PublisherClient,
  SystemContentType,
  SystemContentTypePropertyType,
} from '../PublisherClient';
import { ApiContext } from '../../../../common';
import { EditContentTypeProperty, EditContentTypePropertyForm } from './Components/EditContentTypePropertyForm';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';

interface Props extends JSX.IntrinsicAttributes, IAppContextProp, INavigateProp, ILocationProp, ContentCloudComponentProps {
  params: {
    project: string;
    type: string;
    tab: string;
  };
}

const RECOMMENDED_FIELDS = (contentTypeEntry: SystemContentType): AddSystemContentTypePropertyInput[] => [
  {
    customId: `${contentTypeEntry.customId}.bookmarked`,
    machineName: 'bookmarked',
    isArray: false,
    isBig: false,
    isLocalized: false,
    isRequired: false,
    name: 'Bookmarked',
    type: SystemContentTypePropertyType.Boolean,
  },
  {
    customId: `${contentTypeEntry.customId}.read_at`,
    machineName: 'readAt',
    isArray: false,
    isBig: false,
    isLocalized: false,
    isRequired: false,
    name: 'Read at',
    type: SystemContentTypePropertyType.Date,
  },
  {
    customId: `${contentTypeEntry.customId}.notes`,
    machineName: 'notes',
    isArray: false,
    isBig: true,
    isLocalized: false,
    isRequired: false,
    name: 'Notes',
    type: SystemContentTypePropertyType.String,
  },
  {
    customId: `${contentTypeEntry.customId}.settings`,
    machineName: 'settings',
    isArray: false,
    isBig: false,
    isLocalized: false,
    isRequired: false,
    name: 'Settings',
    type: SystemContentTypePropertyType.JSON,
  },
  {
    customId: `${contentTypeEntry.customId}.reading_lists`,
    machineName: 'readingLists',
    isArray: true,
    isItemRequired: true,
    isParentLink: false,
    isBig: false,
    isLocalized: false,
    isRequired: false,
    isLink: true,
    name: 'Reading lists',
    type: SystemContentTypePropertyType.AnyEntry,
  },
];

function ContentUserDataTypeEntryPageComponent({ contentCloudData, params, location, navigate }: Props) {
  const { accessToken, contentCloud, environment, space } = contentCloudData ?? {};

  const api = useContext(ApiContext);

  const satelliteClient = useMemo(
    () =>
      contentCloud &&
      accessToken &&
      space &&
      environment &&
      new ContentCloudRestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
      }),
    [accessToken, space, environment, contentCloud]
  );

  const [writeAccessToken, setWriteAccessToken] = useState<string | null>(null);
  useEffect(() => {
    if (!api || !contentCloudData || !contentCloud) {
      return;
    }

    updateAccessToken(api, contentCloudData, [Permission.CONTENT_TYPE_WRITE, Permission.SPACE_READ, Permission.CONTENT_TYPE_READ]).then(
      (token) => setWriteAccessToken(token)
    );
  }, [api, contentCloudData, contentCloud]);
  const publisherClient = useMemo(
    () =>
      contentCloud &&
      writeAccessToken &&
      new PublisherClient({
        accessToken: writeAccessToken,
        baseUrl: contentCloud.baseUrl,
        version: CONTENT_CLOUD_API_VERSION,
      }),
    [contentCloud, writeAccessToken]
  );

  const [sidebarTab, setSidebarTab] = useState('general');
  const [contentTab, _setContentTab] = useState(params.tab);
  const setContentTab = useMemo(
    () => (tab: string) => {
      _setContentTab(tab);
      navigate(`/projects/${params.project}/content-cloud/content-user-data/${params.type}/${tab}${location.search}`);
    },
    [navigate, _setContentTab, params, location]
  );

  const [contentTypes, setContentTypes] = useState<ContentTypeEntry[] | null>(null);
  useEffect(
    () => (satelliteClient ? !!loadAllContentTypes(satelliteClient).then((contentTypes) => setContentTypes(contentTypes)) : true) && void 0,
    [satelliteClient]
  );

  const [publisherContentTypeEntry, setPublisherContentTypeEntry] = useState<SystemContentType | null>(null);
  const [satelliteContentTypeEntry, setSatelliteContentTypeEntry] = useState<ContentTypeEntry | null>(null);
  const contentTypeEntry = publisherContentTypeEntry ?? satelliteContentTypeEntry;
  useEffect(() => {
    const type =
      contentTypes?.find((c) => c.id === params.type) ??
      contentTypes?.find((c) => c.machineName === params.type) ??
      contentTypes?.find((c) => c.customId === params.type) ??
      null;

    setPublisherContentTypeEntry(null);
    setSatelliteContentTypeEntry(type);
  }, [contentTypes, params.type]);

  const json = useMemo(
    () => (satelliteContentTypeEntry ? JSON.stringify(satelliteContentTypeEntry, null, 2) : 'loading...'),
    [satelliteContentTypeEntry]
  );

  const fields = contentTypeEntry ? [...contentTypeEntry.properties].sort((a, b) => a.name.localeCompare(b.name)) : [];

  const [editField, setEditField] = useState<AddSystemContentTypePropertyInput | null>(null);
  const [deleteField, setDeleteField] = useState<string | null>(null);
  const editFieldInstance = useMemo(() => (editField ? new EditContentTypeProperty(editField) : null), [editField]);
  const [saving, setSaving] = useState(false);

  const recommendedFields = useMemo(
    () =>
      contentTypeEntry &&
      RECOMMENDED_FIELDS(contentTypeEntry).filter(
        (c) => !contentTypeEntry.properties.find((e) => c.machineName === e.machineName || c.customId === e.customId)
      ),
    [contentTypeEntry]
  );

  const mutateField = useMemo(
    () => (property: AddSystemContentTypePropertyInput) => {
      if (!contentTypeEntry || !publisherClient || !space || !environment) {
        return;
      }

      setSaving(true);

      publisherClient
        .mutateContentTypeProperty(space, [environment], contentTypeEntry, property)
        .then((contentTypeEntry) => {
          if (contentTypeEntry) {
            setPublisherContentTypeEntry(contentTypeEntry);
            setSatelliteContentTypeEntry(null);
            setDeleteField(null);
            setEditField(null);
          }
        })
        .finally(() => setSaving(false));
    },
    [contentTypeEntry, publisherClient, space, environment]
  );
  const removeField = useMemo(
    () => (id: string) => {
      if (!contentTypeEntry || !publisherClient || !space || !environment) {
        return;
      }

      setSaving(true);

      publisherClient
        .removeContentTypeProperty(space, [environment], contentTypeEntry, { id })
        .then((contentTypeEntry) => {
          if (contentTypeEntry) {
            setPublisherContentTypeEntry(contentTypeEntry);
            setSatelliteContentTypeEntry(null);
            setDeleteField(null);
            setEditField(null);
          }
        })
        .finally(() => setSaving(false));
    },
    [contentTypeEntry, publisherClient, space, environment]
  );

  return (
    <ContentCloudPageLayout>
      <ContentCloudDetailsPageLayout
        pageHeader={
          <HeadlineWithBreadcrumbNavigation className="ms-2" names={{ [params.type]: contentTypeEntry?.name ?? '...' }}>
            {contentTypeEntry?.sys.name ?? '...'}
          </HeadlineWithBreadcrumbNavigation>
        }
        sidebar={
          <Tabs
            id={'sidebar-tabs'}
            className={'mb-3'}
            activeKey={sidebarTab}
            onSelect={(k: string | null) => setSidebarTab(k ?? 'general')}
          >
            <Tab eventKey={'general'} title={'General'}>
              <div>
                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Status</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={<span className="text-muted">Current</span>}
                    right={contentTypeEntry && <EntryPublishedBadge sys={contentTypeEntry.sys} />}
                  />
                </div>
                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Publication</div>
                  {contentTypeEntry && !contentTypeEntry.sys.isPublished && contentTypeEntry.sys.versionCreatedAt && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'Draft saved at'}
                      right={<EntryRelativeDate date={contentTypeEntry.sys.versionCreatedAt} />}
                    />
                  )}
                  {contentTypeEntry && contentTypeEntry.sys.publishedAt && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'Published at'}
                      right={<EntryRelativeDate date={contentTypeEntry.sys.publishedAt} />}
                    />
                  )}
                  {contentTypeEntry &&
                    contentTypeEntry.sys.firstPublishedAt &&
                    contentTypeEntry.sys.firstPublishedAt !== contentTypeEntry.sys.versionCreatedAt && (
                      <LeftRightContainer
                        className="pt-2"
                        left={'First published at'}
                        right={<EntryRelativeDate date={contentTypeEntry.sys.firstPublishedAt} />}
                      />
                    )}
                </div>
              </div>
            </Tab>
            <Tab eventKey={'info'} title={'Info'}>
              <div>
                <div className="mb-4">
                  <div className="pt-2 pb-1 border-bottom">
                    <span className="small fw-bold text-muted">Details</span>
                  </div>

                  <LeftRightContainer className="pt-2" left={<span>Name</span>} right={<span>{contentTypeEntry?.name}</span>} />

                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Machine name</span>}
                    right={<span>{contentTypeEntry?.machineName}</span>}
                  />

                  <LeftRightContainer className="pt-2" left={<span>ID</span>} right={<span>{contentTypeEntry?.customId}</span>} />

                  {
                    <div className="small text-muted pb-1">
                      {contentTypeEntry?.description ? (
                        <SanitizeHtml>{contentTypeEntry.description}</SanitizeHtml>
                      ) : (
                        <em>No description provided.</em>
                      )}
                    </div>
                  }
                </div>

                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Entry</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>ID</span>}
                    right={
                      <span>
                        {contentTypeEntry?.sys.id}{' '}
                        <CopyToClipboardButton name="copy-entry-id" text={contentTypeEntry?.sys.id ?? '...'} buttonText="" buttonOnly />
                      </span>
                    }
                  />
                </div>

                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Version</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Entry</span>}
                    right={<span>{contentTypeEntry?.sys.entryVersion}</span>}
                  />
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Localization</span>}
                    right={<span>{contentTypeEntry?.sys.localizationVersion}</span>}
                  />
                  {satelliteContentTypeEntry && (
                    <LeftRightContainer
                      className="pt-2"
                      left={<span>Version ID</span>}
                      right={
                        <CopyToClipboardButton
                          name="copy-version-id"
                          text={satelliteContentTypeEntry.sys.versionId ?? '...'}
                          buttonText=" Copy"
                          buttonOnly
                        />
                      }
                    />
                  )}
                </div>
              </div>
            </Tab>
          </Tabs>
        }
      >
        <Tabs id={'content-tabs'} className={'mb-3'} activeKey={contentTab} onSelect={(k: string | null) => setContentTab(k ?? 'content')}>
          <Tab eventKey={'fields'} title={'Fields'}>
            {fields.length ? (
              <div className="mb-5">
                <Row>
                  <HeaderCol xs={4}>Name</HeaderCol>
                  <HeaderCol xs={4}>Field Type</HeaderCol>
                  <HeaderCol xs={2}>Multiple</HeaderCol>
                  <HeaderCol xs={2}>Actions</HeaderCol>
                </Row>
                {fields.map((contentTypePropertyEntry) =>
                  contentTypeEntry && editFieldInstance?.id === contentTypePropertyEntry.id ? (
                    <EditContentTypePropertyForm
                      key={contentTypePropertyEntry.id}
                      contentType={contentTypeEntry}
                      disabled={saving}
                      entity={editFieldInstance}
                      onSave={async (draft) => mutateField(draft)}
                      onCancel={() => setEditField(null)}
                      asRow
                    />
                  ) : deleteField === contentTypePropertyEntry.id ? (
                    <Row className="m-0 p-0 mb-3" key={contentTypePropertyEntry.id}>
                      <Col xs={10}>
                        <Alert variant="danger">
                          <p>
                            Are you sure you want to delete the field <em>{contentTypePropertyEntry.name}</em>?
                          </p>
                          <p>
                            <strong>The field can no longer be queried in this environment and requests that require it will fail.</strong>
                          </p>
                        </Alert>
                      </Col>
                      <Col xs={2}>
                        <Button variant="danger" onClick={() => removeField(deleteField)}>
                          {saving && <FontAwesomeIcon icon={faSpinner} spin />} Delete
                        </Button>{' '}
                        <Button variant="light" onClick={() => setDeleteField(null)}>
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="m-0 p-0 mb-3" key={contentTypePropertyEntry.id}>
                      <Col xs={4} className="ms-0 me-0 pt-2">
                        <strong>
                          {contentTypePropertyEntry.sys.name}
                          {contentTypePropertyEntry.isRequired ? ` *` : ''}
                        </strong>
                        <div className="small text-muted">
                          {contentTypePropertyEntry.sys.description && (
                            <SanitizeHtml>{contentTypePropertyEntry.sys.description}</SanitizeHtml>
                          )}
                        </div>
                      </Col>
                      <Col xs={4} className="ms-0 me-0 pt-2">
                        <ContentTypePropertyType
                          contentTypePropertyEntry={contentTypePropertyEntry}
                          contentTypeLink={
                            contentTypes?.find((c) => c.machineName === contentTypePropertyEntry.type) ??
                            (contentTypePropertyEntry.allowedTypes?.length === 1
                              ? contentTypes?.find((c) => c.machineName === contentTypePropertyEntry.allowedTypes?.[0])
                              : undefined)
                          }
                        />
                      </Col>
                      <Col xs={2} className="ms-0 me-0 pt-2">
                        {contentTypePropertyEntry.isArray ? (
                          <FontAwesomeIcon icon={faBracketsSquare} className="text-dark" />
                        ) : (
                          <span className="text-light">—</span>
                        )}
                      </Col>
                      <Col xs={2} className="ms-0 me-0 pt-2">
                        <Button
                          disabled={!!editFieldInstance || saving || !!deleteField}
                          variant="link"
                          onClick={() => setEditField(getSystemContentTypePropertyInput(contentTypePropertyEntry))}
                        >
                          Edit
                        </Button>{' '}
                        <Button
                          disabled={!!editFieldInstance || saving || !!deleteField}
                          variant="link"
                          className="text-danger"
                          onClick={() => setDeleteField(contentTypePropertyEntry.id)}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  )
                )}
              </div>
            ) : null}

            {editFieldInstance && contentTypeEntry ? (
              editFieldInstance?.id ? null : (
                <PrimaryActionHighlightBox>
                  <EditContentTypePropertyForm
                    contentType={contentTypeEntry}
                    disabled={saving}
                    entity={editFieldInstance}
                    onSave={async (draft) => mutateField(draft)}
                    onCancel={() => setEditField(null)}
                  />
                </PrimaryActionHighlightBox>
              )
            ) : (
              recommendedFields && (
                <>
                  <PrimaryActionHighlightBox>
                    <h2 className="m-0">Add a field</h2>
                    {recommendedFields.length ? (
                      <div>
                        <ul>
                          {recommendedFields.map((field) => (
                            <li key={field.machineName}>
                              <span className="text-muted d-inline-block" style={{ width: '20px' }}>
                                <FontAwesomeIcon icon={getContentTypePropertyDisplay(field).icon} className="me-2" />
                              </span>{' '}
                              <span className="fw-bold">{field.name}</span>{' '}
                              <Button disabled={saving} variant="link" onClick={() => mutateField(field)}>
                                add
                              </Button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}

                    <PrimaryActionButton
                      className="mt-4"
                      onClick={() =>
                        setEditField({
                          name: '',
                          customId: `custom`,
                          machineName: 'custom',
                          isArray: false,
                          isRequired: false,
                          isLocalized: false,
                          isBig: false,
                          type: SystemContentTypePropertyType.Boolean,
                        })
                      }
                    >
                      Add custom field
                    </PrimaryActionButton>
                  </PrimaryActionHighlightBox>
                </>
              )
            )}
          </Tab>
          {satelliteContentTypeEntry && (
            <Tab eventKey={'visualize'} title={'Visualize'} style={{ height: '100%' }}>
              <ContentTypeVisualization
                rootEntry={satelliteContentTypeEntry}
                onOpenContentType={(contentType) =>
                  navigate(`/projects/${params.project}/content-cloud/content-user-data/${contentType.id}/visualize${location.search}`)
                }
              />
            </Tab>
          )}

          {satelliteContentTypeEntry && (
            <Tab eventKey={'json'} title={'JSON Preview'}>
              <code className="border rounded border-light p-2 d-block mx-2 mb-2 position-relative">
                <div className="position-absolute" style={{ top: '10px', right: '10px' }}>
                  <CopyToClipboardButton name="copy-json" buttonText=" Copy" text={json} buttonOnly />
                </div>
                <pre>{json}</pre>
              </code>
            </Tab>
          )}
        </Tabs>
      </ContentCloudDetailsPageLayout>
    </ContentCloudPageLayout>
  );
}

export const ContentUserDataTypeEntryPage = withParams<{}, { project: string; tab: string }>(
  withLocationAndNavigate<any>(
    withAppContext<any>(
      withContentCloud(ContentUserDataTypeEntryPageComponent, [
        //Permission.CONTENT_READ,
        Permission.SPACE_READ,
        Permission.CONTENT_TYPE_READ,
        Permission.PREVIEW,
        //Permission.EXTERNAL_LINK_READ,
      ])
    )
  )
);
