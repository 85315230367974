import { faBoxUsd } from '@fortawesome/pro-solid-svg-icons/faBoxUsd';
import { faBoxUsd as faBoxUsdLight } from '@fortawesome/pro-light-svg-icons/faBoxUsd';
import { faBrowser as faBrowserLight } from '@fortawesome/pro-light-svg-icons/faBrowser';
import { faBrowser } from '@fortawesome/pro-solid-svg-icons/faBrowser';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';
import { faBuilding as faBuildingLight } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faFileAlt as faFileAltLight } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faRotate } from '@fortawesome/pro-solid-svg-icons/faRotate';
import { faRotate as faRotateLight } from '@fortawesome/pro-light-svg-icons/faRotate';
import { faRotateExclamation } from '@fortawesome/pro-solid-svg-icons/faRotateExclamation';
import { faRotateExclamation as faRotateExclamationLight } from '@fortawesome/pro-light-svg-icons/faRotateExclamation';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';
import { faDollarSign as faDollarSignLight } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { faFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar';
import { faFileInvoiceDollar as faFileInvoiceDollarLight } from '@fortawesome/pro-light-svg-icons/faFileInvoiceDollar';
import { faPercent } from '@fortawesome/pro-solid-svg-icons/faPercent';
import { faPercent as faPercentLight } from '@fortawesome/pro-light-svg-icons/faPercent';
import { faProjectDiagram } from '@fortawesome/pro-solid-svg-icons/faProjectDiagram';
import { faProjectDiagram as faProjectDiagramLight } from '@fortawesome/pro-light-svg-icons/faProjectDiagram';
import { faServer } from '@fortawesome/pro-solid-svg-icons/faServer';
import { faServer as faServerLight } from '@fortawesome/pro-light-svg-icons/faServer';
import { faCommentAltLines } from '@fortawesome/pro-solid-svg-icons/faCommentAltLines';
import { faCommentAltLines as faCommentAltLinesLight } from '@fortawesome/pro-light-svg-icons/faCommentAltLines';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut';
import { faSignOut as faSignOutLight } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faTachometerAlt as faTachometerAltLight } from '@fortawesome/pro-light-svg-icons/faTachometerAlt';
import { faTachometerAlt } from '@fortawesome/pro-solid-svg-icons/faTachometerAlt';
import { faRocket } from '@fortawesome/pro-solid-svg-icons/faRocket';
import { faRocket as faRocketLight } from '@fortawesome/pro-light-svg-icons/faRocket';
import { faFileImport } from '@fortawesome/pro-solid-svg-icons/faFileImport';
import { faFileImport as faFileImportLight } from '@fortawesome/pro-light-svg-icons/faFileImport';
import { faFileExport } from '@fortawesome/pro-solid-svg-icons/faFileExport';
import { faFileExport as faFileExportLight } from '@fortawesome/pro-light-svg-icons/faFileExport';
import { faFolderUser } from '@fortawesome/pro-solid-svg-icons/faFolderUser';
import { faFolderUser as faFolderUserLight } from '@fortawesome/pro-light-svg-icons/faFolderUser';
import { faTable } from '@fortawesome/pro-solid-svg-icons/faTable';
import { faTable as faTableLight } from '@fortawesome/pro-light-svg-icons/faTable';
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage';
import { faImage as faImageLight } from '@fortawesome/pro-light-svg-icons/faImage';
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag';
import { faTag as faTagLight } from '@fortawesome/pro-light-svg-icons/faTag';
import { faBracketsCurly as faBracketsCurlyLight } from '@fortawesome/pro-light-svg-icons/faBracketsCurly';
import { faBracketsCurly } from '@fortawesome/pro-solid-svg-icons/faBracketsCurly';
import { faHexagonNodes as faHexagonNodesLight } from '@fortawesome/pro-light-svg-icons/faHexagonNodes';
import { faHexagonNodes } from '@fortawesome/pro-solid-svg-icons/faHexagonNodes';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUser as faUserLight } from '@fortawesome/pro-light-svg-icons/faUser';
import { faFileContract } from '@fortawesome/pro-solid-svg-icons/faFileContract';
import { faFileContract as faFileContractLight } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faUsers as faUsersLight } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faSlidersHSquare } from '@fortawesome/pro-solid-svg-icons/faSlidersHSquare';
import { faSlidersHSquare as faSlidersHSquareLight } from '@fortawesome/pro-light-svg-icons/faSlidersHSquare';
import { faTrafficLight } from '@fortawesome/pro-light-svg-icons/faTrafficLight';
import { faFlaskVial } from '@fortawesome/pro-light-svg-icons/faFlaskVial';
import { faComputer } from '@fortawesome/pro-light-svg-icons/faComputer';
import { faIndustryWindows } from '@fortawesome/pro-light-svg-icons/faIndustryWindows';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faCode as faCodeSolid } from '@fortawesome/pro-solid-svg-icons/faCode';
import { faCode as faCodeLight } from '@fortawesome/pro-light-svg-icons/faCode';
import { faCloud } from '@fortawesome/pro-light-svg-icons/faCloud';
import { faDrupal } from '@fortawesome/free-brands-svg-icons/faDrupal';
import { faWordpress } from '@fortawesome/free-brands-svg-icons/faWordpress';
import { faBoltLightning } from '@fortawesome/pro-light-svg-icons/faBoltLightning';
import { faChartNetwork } from '@fortawesome/pro-light-svg-icons/faChartNetwork';
import { faCloudWord } from '@fortawesome/pro-light-svg-icons/faCloudWord';
import {
  ProjectLinkStatus,
  ProjectStatus,
  SiteApplicationType,
  SiteEnvironmentType,
  SiteStatus,
  SyncCoreDataDefinitionsEnumTranslator,
} from '@edgebox/sync-core-data-definitions';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faEnvelopeDot } from '@fortawesome/pro-light-svg-icons/faEnvelopeDot';
import { ClientProjectEntity, ClientProjectLinkEntity, ClientSiteEntity, ClientUserEntity } from '@edgebox/api-rest-client';
import { ApiContext } from '../common';
import { CustomerRole, DataDefinitionsEnumTranslator, UserStatus } from '@edgebox/data-definitions';
import { RemoteServiceHostingType } from '@edgebox/sync-core-data-definitions';

export const SITE_ENVIRONMENT_TYPE_ICONS = {
  [SiteEnvironmentType.Local]: faComputer,
  [SiteEnvironmentType.Testing]: faFlaskVial,
  [SiteEnvironmentType.Staging]: faTrafficLight,
  [SiteEnvironmentType.Production]: faIndustryWindows,
};
export function SiteEnvironmentIcon({ type, ...rest }: Omit<FontAwesomeIconProps, 'icon'> & { type: SiteEnvironmentType }) {
  return <FontAwesomeIcon {...rest} icon={SITE_ENVIRONMENT_TYPE_ICONS[type]} />;
}

export const SITE_APPLICATION_TYPE_ICONS = {
  [SiteApplicationType.Drupal]: faDrupal,
  [SiteApplicationType.Wordpress]: faWordpress,
  [SiteApplicationType.SyncCore]: faChartNetwork,
  [SiteApplicationType.ContentCloud]: faCloudWord,
  [SiteApplicationType.PantheonPublisher]: faBoltLightning,
};
export function SiteApplicationIcon({ type, ...rest }: Omit<FontAwesomeIconProps, 'icon'> & { type: SiteApplicationType }) {
  return <FontAwesomeIcon {...rest} icon={SITE_APPLICATION_TYPE_ICONS[type] as any} />;
}

export const SITE_STATUS_ICONS = {
  [SiteStatus.Active]: faCheckCircle,
  [SiteStatus.Inactive]: faTimesCircle,
};
export function SiteStatusIcon({ status, ...rest }: Omit<FontAwesomeIconProps, 'icon'> & { status: SiteStatus }) {
  return (
    <FontAwesomeIcon
      {...rest}
      icon={SITE_STATUS_ICONS[status]}
      className={`${rest.className || ''} ${status === SiteStatus.Inactive ? 'text-danger' : 'text-success'}`}
    />
  );
}

export const PROJECT_STATUS_ICONS = {
  [ProjectStatus.Active]: faCheckCircle,
  [ProjectStatus.Inactive]: faTimesCircle,
};
export function ProjectStatusIcon({ status, ...rest }: Omit<FontAwesomeIconProps, 'icon'> & { status: ProjectStatus }) {
  return (
    <FontAwesomeIcon
      {...rest}
      icon={PROJECT_STATUS_ICONS[status]}
      className={`${rest.className || ''} ${status === ProjectStatus.Inactive ? 'text-danger' : 'text-success'}`}
    />
  );
}

export const PROJECT_LINK_STATUS_ICONS = {
  [ProjectLinkStatus.Active]: faCheckCircle,
  [ProjectLinkStatus.Inactive]: faTimesCircle,
};
export function ProjectLinkStatusIcon({ status, ...rest }: Omit<FontAwesomeIconProps, 'icon'> & { status: ProjectLinkStatus }) {
  return (
    <FontAwesomeIcon
      {...rest}
      icon={PROJECT_LINK_STATUS_ICONS[status]}
      className={`${rest.className || ''} ${status === ProjectLinkStatus.Inactive ? 'text-danger' : 'text-success'}`}
    />
  );
}

export const USER_STATUS_ICONS = {
  [UserStatus.Active]: faCheckCircle,
  [UserStatus.Inactive]: faTimesCircle,
  [UserStatus.Invited]: faEnvelope,
  [UserStatus.VerifyEmail]: faEnvelopeDot,
};
export function UserStatusIcon({ status, ...rest }: Omit<FontAwesomeIconProps, 'icon'> & { status: UserStatus }) {
  return (
    <FontAwesomeIcon
      {...rest}
      icon={USER_STATUS_ICONS[status]}
      className={`${rest.className || ''} ${
        status === UserStatus.Inactive ? 'text-danger' : status === UserStatus.Active ? 'text-success' : 'text-warning'
      }`}
      title={DataDefinitionsEnumTranslator.transLateEnumValue('UserStatus', status)}
    />
  );
}

export const CUSTOMER_ROLE_ICONS = {
  [CustomerRole.Owner]: faKey,
  [CustomerRole.ManageFinances]: faDollarSign,
  [CustomerRole.ManageSites]: faCodeSolid,
  [CustomerRole.ManageContent]: faFileAltLight,
};
export function CustomerRoleIcon({ role, ...rest }: Omit<FontAwesomeIconProps, 'icon'> & { role: CustomerRole }) {
  return (
    <FontAwesomeIcon
      {...rest}
      icon={CUSTOMER_ROLE_ICONS[role]}
      className={`${rest.className || ''} ${role === CustomerRole.Owner ? 'text-danger' : 'text-dark'}`}
      title={DataDefinitionsEnumTranslator.transLateEnumValue('CustomerRole', role)}
    />
  );
}

export const REMOTE_SERVICE_HOSTING_TYPE_TYPE_ICONS = {
  [RemoteServiceHostingType.SaaS]: faCloud,
  [RemoteServiceHostingType.OnPremise]: faServer,
};
export function RemoteServiceHostingTypeIcon({ type, ...rest }: Omit<FontAwesomeIconProps, 'icon'> & { type: RemoteServiceHostingType }) {
  return <FontAwesomeIcon {...rest} icon={REMOTE_SERVICE_HOSTING_TYPE_TYPE_ICONS[type]} />;
}

export function SiteIcons({ site }: { site: ClientSiteEntity }) {
  const api = useContext(ApiContext);

  const [user, setUser] = useState('');
  useEffect(() => {
    const userId = site.userOwner?.getId();
    if (!userId) {
      return;
    }

    api?.authentication.users
      .item(userId)
      .then((user) => (user ? setUser(`${user.firstName} ${user.lastName}`) : undefined))
      .catch((e) => undefined);
  }, [site.userOwner]);

  return (
    <span className="d-inline-block">
      <SiteStatusIcon
        className="me-1"
        status={site.status}
        title={SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SiteStatus', site.status)}
      />
      <SiteApplicationIcon
        className="me-1"
        type={site.appType}
        title={`Module ${site.appModuleVersion === 'dev' ? '' : 'v'}${
          site.appModuleVersion
        }, ${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SiteApplicationType', site.appType)} v${site.appVersion}`}
      />
      <SiteEnvironmentIcon
        className="me-2"
        type={site.environmentType}
        title={
          SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SiteEnvironmentType', site.environmentType) +
          (user ? ` from ${user}` : '')
        }
      />
    </span>
  );
}

export function ProjectIcons({ project }: { project: ClientProjectEntity }) {
  return (
    <span className="d-inline-block">
      <ProjectStatusIcon
        className="me-1"
        status={project.status || ProjectStatus.Active}
        title={SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('ProjectStatus', project.status || ProjectStatus.Active)}
      />
      {project.appType && (
        <SiteApplicationIcon
          className="me-1"
          type={project.appType}
          title={SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SiteApplicationType', project.appType)}
        />
      )}
      <SiteEnvironmentIcon
        className="me-2"
        type={project.type}
        title={SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SiteEnvironmentType', project.type)}
      />
    </span>
  );
}

export function ProjectLinkIcons({ projectLink }: { projectLink: ClientProjectLinkEntity }) {
  return (
    <span className="d-inline-block">
      <ProjectLinkStatusIcon
        className="me-1"
        status={projectLink.status}
        title={SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('ProjectLinkStatus', projectLink.status)}
      />
      <SiteApplicationIcon
        className="me-1"
        type={projectLink.sourceAppType}
        title={`From ${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SiteApplicationType', projectLink.sourceAppType)}`}
      />
      <SiteApplicationIcon
        className="me-1"
        type={projectLink.targetAppType}
        title={`To ${SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SiteApplicationType', projectLink.targetAppType)}`}
      />
    </span>
  );
}

export function UserIcons({ user }: { user: ClientUserEntity }) {
  return (
    <span className="d-inline-block">
      <UserStatusIcon
        className="me-1"
        status={user.status}
        title={DataDefinitionsEnumTranslator.transLateEnumValue('UserStatus', user.status)}
      />
      {user.customerRoles?.map((role) => (
        <CustomerRoleIcon className="me-1" role={role} title={DataDefinitionsEnumTranslator.transLateEnumValue('CustomerRole', role)} />
      ))}
    </span>
  );
}

export const ICONS = {
  Onboarding: {
    regular: faRocket,
    light: faRocketLight,
  },
  SourceLinks: {
    regular: faFileImport,
    light: faFileImportLight,
  },
  TargetLinks: {
    regular: faFileExport,
    light: faFileExportLight,
  },
  ContentTypes: {
    regular: faTable,
    light: faTableLight,
  },
  ContentUserData: {
    regular: faFolderUser,
    light: faFolderUserLight,
  },
  Assets: {
    regular: faImage,
    light: faImageLight,
  },
  Tags: {
    regular: faTag,
    light: faTagLight,
  },
  RestExplorer: {
    regular: faBracketsCurly,
    light: faBracketsCurlyLight,
  },
  GraphQlExplorer: {
    regular: faHexagonNodes,
    light: faHexagonNodesLight,
  },
  IntegrationClients: {
    regular: faCodeSolid,
    light: faCodeLight,
  },
  Dashboard: {
    regular: faTachometerAlt,
    light: faTachometerAltLight,
  },
  Site: {
    regular: faBrowser,
    light: faBrowserLight,
  },
  Project: {
    regular: faProjectDiagram,
    light: faProjectDiagramLight,
  },
  Content: {
    regular: faFileAlt,
    light: faFileAltLight,
  },
  Updates: {
    regular: faRotate,
    light: faRotateLight,
  },
  UpdateIssues: {
    regular: faRotateExclamation,
    light: faRotateExclamationLight,
  },
  SyncCore: {
    regular: faServer,
    light: faServerLight,
  },
  Logs: {
    regular: faCommentAltLines,
    light: faCommentAltLinesLight,
  },
  Customer: {
    regular: faBuilding,
    light: faBuildingLight,
  },
  Proposal: {
    regular: faFileContract,
    light: faFileContractLight,
  },
  Contract: {
    regular: faDollarSign,
    light: faDollarSignLight,
  },
  Invoice: {
    regular: faFileInvoiceDollar,
    light: faFileInvoiceDollarLight,
  },
  Pricing: {
    regular: faBoxUsd,
    light: faBoxUsdLight,
  },
  Discount: {
    regular: faPercent,
    light: faPercentLight,
  },
  Setting: {
    regular: faSlidersHSquare,
    light: faSlidersHSquareLight,
  },
  User: {
    regular: faUser,
    light: faUserLight,
  },
  Users: {
    regular: faUsers,
    light: faUsersLight,
  },
  Logout: {
    regular: faSignOut,
    light: faSignOutLight,
  },
};
