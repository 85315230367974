import { IPagedListResponse } from '@edgebox/data-definition-kit';
import { EntitySelector, IEntitySelectorProps, IEntitySelectorState, ITextSearchFilter } from '../../../../common';
import { ContentCloudComponentData, ContentCloudComponentProps, WithContentCloud } from '../WithContentCloud';
import { Permission } from '../shared-permissions';
import React from 'react';
import { LoadingBar } from '@edgebox/react-components';
import {
  CONTENT_CLOUD_API_VERSION,
  getContentCloudSatelliteUrl,
  isContentUserDataType,
  loadAllContentTypes,
} from '../content-cloud-helper';
import { ClientProjectEntity } from '@edgebox/api-rest-client';
import { ContentTypeEntry, ContentCloudRestClient } from '../RestClient';

interface Props extends IEntitySelectorProps<ContentTypeEntry>, ContentCloudComponentProps {
  contentCloudData: ContentCloudComponentData;
  contentCloudProject?: ClientProjectEntity;
  independentOnly?: boolean;
  semantics?: 'content-user-data' | 'content' | 'assets' | 'tags';
  exclude?: string[];
}
interface State extends IEntitySelectorState<ContentTypeEntry> {
  contentTypes?: ContentTypeEntry[];
}

class ContentTypeSelectorComponent extends EntitySelector<ContentTypeEntry, Props, State> {
  async search(page: number, filter?: ITextSearchFilter | undefined): Promise<IPagedListResponse<ContentTypeEntry>> {
    const { accessToken, contentCloud, space, environment } = this.props.contentCloudData;

    const satelliteClient = new ContentCloudRestClient({
      accessToken,
      baseUrl: getContentCloudSatelliteUrl(contentCloud, {
        api: 'rest',
        environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
        service: 'live',
        version: CONTENT_CLOUD_API_VERSION,
      }),
    });

    let items = await loadAllContentTypes(satelliteClient);

    const { independentOnly, semantics, exclude } = this.props;

    if (independentOnly) {
      items = items.filter((c) => c.isIndependent);
    }

    if (semantics) {
      if (semantics === 'assets') {
        items = items.filter((c) => c.isAsset);
      } else if (semantics === 'tags') {
        items = items.filter((c) => c.isTaxonomy);
      } else if (semantics === 'content') {
        items = items.filter((c) => !isContentUserDataType(c));
      } else if (semantics === 'content-user-data') {
        items = items.filter((c) => isContentUserDataType(c));
      }
    }

    if (exclude?.length) {
      items = items.filter((c) => !exclude.includes(c.id));
    }

    if (filter?.search) {
      const search = filter.search;
      items = items.filter(
        (c) => c.id === search || c.customId === search || c.machineName === search || c.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    return {
      items,
      itemsPerPage: items.length,
      numberOfPages: 1,
      page: 0,
      totalNumberOfItems: items.length,
    };
  }

  renderItemAsText(entity: ContentTypeEntry): string {
    return entity.name;
  }

  renderItem(entity: ContentTypeEntry): React.ReactNode {
    return entity.name;
  }

  renderEmpty(): React.ReactNode {
    return this.props.emptyLabel ?? 'None';
  }

  renderCurrentValue(selected: ContentTypeEntry): React.ReactNode {
    return selected.name;
  }
}

export function ContentTypeSelector(props: Omit<Props, keyof ContentCloudComponentProps>) {
  return (
    <WithContentCloud permissions={[Permission.SPACE_READ, Permission.CONTENT_TYPE_READ]} project={props.contentCloudProject}>
      {(contentCloudData) =>
        contentCloudData ? (
          <ContentTypeSelectorComponent key={props.contentCloudProject?.id} {...props} contentCloudData={contentCloudData} />
        ) : (
          <LoadingBar />
        )
      }
    </WithContentCloud>
  );
}
