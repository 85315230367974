import { ButtonLink } from '@edgebox/react-components';
import { IExternalProps } from '@edgebox/react-components/dist/components/Buttons/ButtonLink';
import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

type IProps = React.ComponentPropsWithRef<'button'> & ButtonProps;

const PRIMARY_ACTION_BUTTON_CLASSES = 'btn-primary-action pt-2 pb-2 ps-4 pe-4 rounded fw-bold';

export class PrimaryActionButton extends React.Component<IProps, {}> {
  render() {
    return <Button {...this.props} variant={'primary'} className={`${PRIMARY_ACTION_BUTTON_CLASSES} ${this.props.className || ''}`} />;
  }
}

export class PrimaryActionLinkButton extends React.Component<IProps & IExternalProps & { external?: boolean }, {}> {
  render() {
    return (
      <ButtonLink
        {...this.props}
        target={this.props.target ?? (this.props.external ? '_blank' : undefined)}
        variant={'primary'}
        className={`${PRIMARY_ACTION_BUTTON_CLASSES} ${this.props.className || ''}`}
      />
    );
  }
}
